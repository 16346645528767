import React from "react";
import {Card, Container} from "react-bootstrap";
import Obfuscate from "react-obfuscate";

export const UserDataDeletion = () => (
    <Container className="space-top">
        <Card className="card-shadow p-3">
            <h2>Instruction for user data deletion</h2>
            <div className="mt-4">Please follow the steps below in order you want to remove your user account:</div>

            <div className="mt-3">
                <ul>
                    <li>This web page use Milonga Finder <b>Facebook/Google</b> application for log in the user.
                        Please delete it manually in their site in case you don’t need it anymore!</li>
                    <li className="mt-2">
                        Please write to the <Obfuscate href={"mailto:szaltomi@gmail.com"}>szaltomi@gmail.com</Obfuscate> address to request the user data deletion.
                        The administrator of the web page will delete all of your data within 3 business days.
                        After the deletion of your user data you will get a confirmation email.
                        <br/>
                        <div className="mt-2 font-weight-bold">
                            Warning! The user data deletion cannot be undone after your requested it!
                        </div>
                    </li>
                </ul>
            </div>

        </Card>
    </Container>
);
