import {useCallback, useEffect, useState} from "react";
import {simpleAuthenticatedDelete, simpleAuthenticatedQuery} from "../utils/connection";

export function useTable(getUrl, deleteUrl) {
    const [originalData, setOriginalData] = useState([]);
    const [data, setData] = useState([]);
    const [ ,forceUpdateStateHelper] = useState();
    const forceUpdate = useCallback(() => forceUpdateStateHelper({}), [forceUpdateStateHelper]);

    const processData = useCallback( (data) => {
        setData(data);
        setOriginalData(data);
    }, []);

    const refreshData = useCallback( () => {
        return simpleAuthenticatedQuery(getUrl, processData);
    }, [getUrl, processData]);

    useEffect(() => {
        const controller = refreshData();

        return () => {
            controller.abort()
        }
    }, [refreshData]);

    const deleteRow = (id, queryParam = "") => {
        simpleAuthenticatedDelete(deleteUrl + id + queryParam, refreshData, forceUpdate);
    };

    const applyFilter = useCallback((filteredData) => setData(filteredData), []);

    return {data, originalData, refreshData, deleteRow, applyFilter}
}