import React, {Fragment, useState} from 'react';
import localization from "../../../../utils/localization";
import {useForm} from "react-hook-form";
import {API_DANCE_PARTNER, DEFAULT_CLUB_HUNGARY} from "../../../../constants";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {useModal} from "../../../../hook/useModal";
import {simpleAuthenticatedPost, simpleAuthenticatedPut, simpleQuery} from "../../../../utils/connection";
import ModalBase from "../../../../component/FormModal/ModalBase";
import {
    CustomForm,
    DatePickerField,
    SelectItem,
    SubmitButton,
    TextAreaField
} from "../../../../component/FormInputs/FormInputs";
import LoadingIndicator from "../../../../component/LoadingIndicator/LoadingIndicator";
import {createClubSelectOptions} from "../../../../utils/helperFunctions";
import {
    getDancePartnerCategoryOptions,
    getDancerRoleOptions,
    isPreferredClubOptionVisible
} from "../../../../utils/enumMapper";
import Alert from "react-s-alert";
import {useSelector} from "react-redux";
import {addMonths} from "date-fns";

const EditDancePartnerAdvertisementModal = (props) => {
    const modal = useModal();
    const formControl = useForm();
    const country = useSelector(state => state.uiReducer.country);
    const clubList = useSelector(state => state.eventReducer.clubs);
    const [clubOptions, setClubOptions] = useState([{value: null, text: "!!!"}]);
    const [data, setData] = useState({});
    const [clubVisible, setClubVisible] = useState(true);

    const minDate = addMonths(new Date(), 1);
    const maxDate = addMonths(new Date(), 3);

    let currentDate = minDate;
    if (new Date(data.expirationDate) >= new Date()) {
        currentDate = new Date(data.expirationDate);
    }

    const initClubList = () => {
        const options = createClubSelectOptions(clubList);
        options.unshift({value: DEFAULT_CLUB_HUNGARY, text: localization.notSpecified})

        setClubOptions(options);
    };

    const onSubmit = (submittedData) => {
        modal.setLoading(true);

        if (props.id) {
            simpleAuthenticatedPut(API_DANCE_PARTNER + `${country}/` + props.id, submittedData, () => {
                Alert.success(localization.modifySuccess);
                props.onSubmit(submittedData);
                onClose()
            }, onClose)
        } else {
            simpleAuthenticatedPost(API_DANCE_PARTNER + `${country}/`, submittedData, () => {
                Alert.success(localization.modifySuccess);
                props.onSubmit(submittedData);
                onClose()
            }, onClose)
        }
    }

    const onClose = () => {
        modal.handleClose();
    }

    const loadData = (response) => {
        setData(response)
        changeClubVisibility(response.category);
        modal.setLoading(false);
    }

    const changeClubVisibility = (category) => {
        const visible = isPreferredClubOptionVisible(category);
        setClubVisible(visible);
    }

    modal.setOnShowCallback(() => {
        initClubList();

        if (props.id) {
            modal.setLoading(true);
            simpleQuery(API_DANCE_PARTNER + props.id, loadData);
        } else {
            setData({expirationDate: minDate})
        }
    });

    const onCategoryChange = (event) => {
        changeClubVisibility(event.target.value);
    }

    return (
        <Fragment>
            {modal.renderTrigger(props.trigger)}

            {modal.show ? (
                <ModalBase show={modal.show} title={localization.dancePartnerAdModalTitle} size="md">
                    {modal.loading ? (
                        <Modal.Body>
                            <div style={{height: "200px"}}>
                                <LoadingIndicator/>
                            </div>
                        </Modal.Body>
                    ): (
                        <CustomForm formControl={formControl} onSubmit={onSubmit}>
                            <Modal.Body>
                                <SelectItem formControl={formControl}
                                            defaultValue={data.category}
                                            label={localization.dancePartnerAdModalCategory}
                                            name="category"
                                            onChange={onCategoryChange}
                                            options={getDancePartnerCategoryOptions()}/>

                                <DatePickerField formControl={formControl}
                                                 defaultValue={currentDate}
                                                 minDate={minDate}
                                                 maxDate={maxDate}
                                                 name="expirationDate"
                                                 label={localization.dancePartnerAdModalExpirationDate}/>

                                <SelectItem formControl={formControl}
                                            defaultValue={data.role}
                                            label={localization.dancePartnerAdModalRole}
                                            name="role"
                                            options={getDancerRoleOptions()}/>

                                <TextAreaField formControl={formControl}
                                               defaultValue={data.introduction}
                                               required
                                               name="introduction"
                                               label={localization.dancePartnerAdModalIntroduction}
                                               rows={4}
                                               maxLength={500}/>

                                {clubVisible ? (
                                    <SelectItem formControl={formControl}
                                                defaultValue={data.preferredClubId}
                                                label={localization.dancePartnerCardPreferredClub}
                                                name="clubId"
                                                options={clubOptions}/>
                                ) : null}

                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={modal.handleClose}>{localization.formDiscard}</Button>
                                <SubmitButton caption={localization.formSave} />
                            </Modal.Footer>
                        </CustomForm>
                    )}
                </ModalBase>
            ) : null}
        </Fragment>
    );
};

export default EditDancePartnerAdvertisementModal;