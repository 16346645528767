import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {createClubSelectOptions} from "../../../utils/helperFunctions";
import {simpleAuthenticatedQuery} from "../../../utils/connection";
import * as Const from "../../../constants";
import localization from "../../../utils/localization";
import {
    CheckBox,
    CustomForm,
    DateTimePicker,
    HiddenField,
    InputField,
    PrintGeneralErrorMsg,
    SelectItem,
    TextAreaField
} from "../../../component/FormInputs/FormInputs";
import EditPictureModal from "../../../component/FormModal/EditPictureModal";
import RecurringEventModal from "../../../component/FormModal/RecurringEventModal";
import {useLocation, useParams} from "react-router-dom";
import DialogPanel from "../../../component/DialogPanel/DialogPanel";
import GeneralFormButtons from "../../../component/FormModal/GeneralFormButtons";
import {Col, Row} from "react-bootstrap";
import useCreateOrModify from "../../../hook/useCreateOrModify";
import qs from "query-string";
import {useDispatch, useSelector} from "react-redux";
import eventActions from "../../../store/actions/eventActions";
import {getTangoEventOptions} from "../../../utils/EventStyle";
import {sendGAEvent} from "../../../utils/googleAnalytics";

const EditEventPage = (props) => {
    const location = useLocation();
    const {id} = useParams();
    const formControl = useForm();
    const dispatch = useDispatch();
    const [clubOptions, setClubOptions] = useState([{value: null, text: "!!!"}]);
    const [dateError, setDateError] = useState(null);
    const [noClubError, setNoClubError] = useState(null);
    const [invalidDurationError, setInvalidDurationError] = useState(null);
    const [updateUpcomingRecurring, setUpdateUpcomingRecurring] = useState(null);
    const {createOrModify, copy, fetchData, loading} = useCreateOrModify();
    const currentDate = useSelector(state => state.eventReducer.currentDate);
    const country = useSelector(state => state.uiReducer.country);

    const isModify = id !== Const.PATH_NEW;
    const isCopyMode = qs.parse(location.search).copy === null;

    const getEndDate = () => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 1);
        return currentDate;
    }

    const [data, setData] = useState({
        id: null,
        picturePath: "default_event.jpg",
        recurringId: null,
        clubMeta: {id: "", name: ""},
        endDateTime: getEndDate()
    });

    const showRecurringEventOptions = data.recurringId !== null && typeof data.recurringId !== 'undefined' && updateUpcomingRecurring === null && !isCopyMode;
    const editPictureButton = <div className="btn btn-primary mt-1">{localization.formUploadPicture}</div>

    const createClubListOptions = useCallback( (clubList) => {
        const options = createClubSelectOptions(clubList);

        if (options.length === 0) {
            setNoClubError(localization.warningClassNoClub);
        } else {
            setNoClubError(null);
            setClubOptions(options);
        }
    }, []);

    useEffect(() => {
        const controllers = [];

        controllers.push(simpleAuthenticatedQuery(Const.API_MY_CLUBS, createClubListOptions));

        if (isModify)
            controllers.push(fetchData(props.apiUrl, setData));

        return () => {
            for (const controller of controllers)
                controller.abort();
        }
    }, [createClubListOptions, fetchData, isModify, props.apiUrl]);

    const updatePicture = (picturePath) => {
        let newData = {...data};
        newData.picturePath = picturePath;
        setData(newData);
    };

    const onSubmit = async submittedData => {
        setDateError(null);
        setInvalidDurationError(null);

        if (submittedData.startDateTime >= submittedData.endDateTime)
            setDateError(localization.validationStartDateError);
        else if (!isValidDuration(submittedData))
            setInvalidDurationError(localization.validationEventDurationError);
        else if (!noClubError) {
            const recurringParam = updateUpcomingRecurring || submittedData.recurringEvent ? Const.QUERY_RECURRING : "";
            const createUrl = props.apiUrl + "club/" + submittedData.clubId + "/" + recurringParam;
            const modifyUrl = props.apiUrl + "club/" + submittedData.clubId + "/" + id + recurringParam;

            if (isCopyMode)
                await copy(createUrl, submittedData, props.redirectUrl);
            else
                await createOrModify(createUrl, modifyUrl, submittedData, props.redirectUrl);

            sendGAEvent('Create', "Event created: " + submittedData.title);
            dispatch(eventActions.fetchEvents(country, currentDate));
        }
    };

    const isValidDuration = data => {
        const milliseconds = Math.abs(data.startDateTime - data.endDateTime);
        const hours = Math.round(milliseconds / 36e5) + 1;

        return hours < Const.LIMIT_EVENT_DURATION_HOURS;
    };

    const getTitle = () => {
        let title;
        if (isModify)
            title = data.title;
        else
            title = localization.actionCreateEvent;

        const titlePrefix = isCopyMode ? localization.actionCopy.toUpperCase() + ": " : "";

        return titlePrefix + title;
    }

    return (
        <div className="container space-top">
            <RecurringEventModal show={showRecurringEventOptions}
                                 title={data.title}
                                 text={localization.formRecurringEventEdit}
                                 redirectUrl={props.redirectUrl}
                                 onSubmit={setUpdateUpcomingRecurring}/>

            <DialogPanel loading={loading} title={getTitle()}>
                <CustomForm formControl={formControl} onSubmit={onSubmit}>
                    <Row>
                        <Col md={5}>
                            <div className="form-group">
                                <label htmlFor="picture" className="font-weight-bold" >{localization.formProfilePicture}</label>
                                <img id="picture" className="img-fluid" src={Const.S3_IMAGES + data.picturePath} alt="Profile" />
                                <EditPictureModal s3Folder={"event"} width={800} height={400} onUpdate={updatePicture} trigger={editPictureButton} />
                                <div className="mt-2">
                                    <PrintGeneralErrorMsg error={noClubError} text={noClubError}/>
                                </div>
                                <div className="mt-2">
                                    <PrintGeneralErrorMsg error={dateError} text={dateError}/>
                                </div>
                                <div className="mt-2">
                                    <PrintGeneralErrorMsg error={invalidDurationError} text={invalidDurationError}/>
                                </div>
                            </div>
                        </Col>

                        <Col md={7}>
                            <HiddenField formControl={formControl} name="id" defaultValue={data.id} />
                            <HiddenField formControl={formControl} name="picturePath" value={data.picturePath} />
                            <InputField required formControl={formControl} defaultValue={data.title} name="title" label={localization.filterEventTitle}/>
                            <InputField required formControl={formControl} defaultValue={data.location} name="location" label={localization.eventLocation}/>
                            <TextAreaField formControl={formControl} defaultValue={data.description} name="description" label={localization.eventDescription}/>

                            <SelectItem formControl={formControl} name="clubId" label={localization.formClub} options={clubOptions} defaultValue={data.clubMeta.id}/>
                            <SelectItem formControl={formControl} name="eventType" label={localization.filterEventType} options={getTangoEventOptions()} defaultValue={data.eventType}/>

                            <DateTimePicker formControl={formControl} defaultValue={data.startDateTime} name="startDateTime" label={localization.eventStartDate}/>
                            <DateTimePicker formControl={formControl} defaultValue={data.endDateTime} name="endDateTime" label={localization.eventEndDate}/>

                            {!isModify && <CheckBox formControl={formControl} name="recurringEvent" label={localization.formRecurringWeekly} defaultValue={data.recurringEvent}/>}
                        </Col>
                    </Row>

                    <GeneralFormButtons redirectUrl={props.redirectUrl} isModify={isModify}/>
                </CustomForm>
            </DialogPanel>
        </div>
    )
};

export default EditEventPage;