import React, {useMemo, useState} from "react";
import HeadLayout from "../../layout/HeadLayout/HeadLayout";
import localization from "../../utils/localization";
import {useSelector} from "react-redux";
import ScrollLink from "../../component/ScrollLink/ScrollLink";
import EventPage from "../events/EventPage";
import EventFilterButtons from "../../component/EventFilterButtons/EventFilterButtons";

const TangoClasses = () => {
    const originalClasses = useSelector(state => state.eventReducer.classes);
    const [filteredEvents, setFilteredEvents] = useState([]);

    const Filter = useMemo(() => (
        <EventFilterButtons events={originalClasses} onFilter={setFilteredEvents} classCategory />
    ), [originalClasses]);

    return(
        <>
            <HeadLayout pic="classes.jpg" bgPositionY="bottom" copyright={localization.copyrightZoli}>
                <h1 className="text-white text-center">
                    {localization.menuClasses}
                </h1>

                <div className="text-light text-center mt-4 mt-md-5">
                    <h4>{localization.headerClassesDescription1}</h4>
                    <p className="mt-4">{localization.headerEventsDescription2}</p>
                </div>

                <div className="d-flex justify-content-center text-white mt-4 mt-md-5">
                    <ScrollLink to="calendar" text={localization.headerEventsButtonCalendar}/>
                    <ScrollLink className="ml-2" to="event-list" text={localization.headerEventsButtonUpcomingEvents}/>
                </div>
            </HeadLayout>

            <EventPage filter={Filter} events={filteredEvents}/>
        </>
    )
};

export default TangoClasses;