import React from "react";
import localization from "../../utils/localization";
import {Button} from "react-bootstrap";
import {useNavigation} from "../../hook/useNavigation";

const BackButton = (props) => {
    const navigation = useNavigation();

    const backToPreviousHandler = () => {
        navigation.replaceHistory(props.backUrl);
    };

    return (
        <Button variant="primary" className={props.className} onClick={backToPreviousHandler}>
            <i className="fas fa-chevron-left mr-2"/>
            {localization.buttonBack}
        </Button>
    )
}

export default BackButton;