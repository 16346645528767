import * as actionTypes from "../actions/userActions"

const initialState = {
    authenticated: false,
    currentUser: null,
    loading: true
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case actionTypes.USER_LOADED:
            return {
                currentUser: action.user,
                authenticated: true,
                loading: false
            };
        case actionTypes.USER_LOGOUT:
            return {
                authenticated: false,
                currentUser: null,
                loading: false
            };
        default:
            return state;
    }
};

export default userReducer;