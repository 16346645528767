import React, {useState} from "react";
import {resetPasswordRequest} from "../../../utils/connection";
import Alert from "react-s-alert";
import localization from "../../../utils/localization";
import qs from "query-string";
import { useForm } from 'react-hook-form'
import {CustomForm, PasswordField, PrintGeneralErrorMsg, SubmitButton} from "../../../component/FormInputs/FormInputs";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import localizeErrorMessage from "../../../utils/errorCodeResolver";
import {Url} from "../../../constants";
import {useNavigation} from "../../../hook/useNavigation";
import userActions from "../../../store/actions/userActions";

const ResetPasswordForm = () => {
    const location = useLocation();
    const navigation = useNavigation();
    const formControl = useForm();
    const dispatch = useDispatch();
    const [passwordMismatch, setPasswordMismatch] = useState(false);

    const setLoading = (value) => dispatch(userActions.loadingAction(value));

    const onSubmit = async (data) => {
        if (data.password === data.confirmPassword) {
            setLoading(true);

            const request = {
                ...data,
                token: qs.parse(location.search).token,
            };

            try {
                await resetPasswordRequest(request);
                setLoading(false);
                Alert.success(localization.loginResetPasswordSucceed);
                navigation.pushHistory(Url.LOGIN);
            } catch (error) {
                setLoading(false);
                console.log(error);
                Alert.error(localizeErrorMessage(error));
            }
        } else {
            setPasswordMismatch(true);
        }
    };

    return (
        <>
            <h1 className="mb-4 text-center">{localization.loginResetPassword}</h1>
            <p>{localization.loginResetPasswordInfo}!</p>
            <CustomForm formControl={formControl} onSubmit={onSubmit}>
                <PrintGeneralErrorMsg error={passwordMismatch} text={localization.loginPasswordMismatch}/>
                <PasswordField formControl={formControl}/>
                <PasswordField formControl={formControl} name="confirmPassword" label={localization.formConfirmPassword}/>
                <SubmitButton caption={localization.formSubmit}/>
            </CustomForm>
        </>
    );
}

export default ResetPasswordForm;