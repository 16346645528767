import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import uiActions from "../store/actions/uiActions";
import {sendGAPageView} from "../utils/googleAnalytics";

const Translation = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const currentLanguage = useSelector(state => state.uiReducer.currentLanguage);

    const currentLocation = location.pathname;
    const languageParam = /\/([a-z]{2})($|\/)/;
    const match = languageParam.exec(currentLocation);

    if (match !== null) {
        const languageId = match[1];
        if (currentLanguage !== languageId) {
            dispatch(uiActions.changeLanguage(languageId));
        } else {
            sendGAPageView(location.pathname);
        }
    }

    return <Outlet/>;
};

export default Translation;