import React from "react"
import {CONFIG} from "../config";

const UnderDevelopmentWarning = () => {
    if (CONFIG.profile.title === "titleTangoHungary")
        return null;
    else {
        return (
            <div className="container mt-5 mb-3">
                <div className="text-white bg-danger p-2">
                    <h5><i className="fas fa-exclamation-triangle fa-2x pr-3 pt-2"></i>
                        Warning! This web page is under development, please mind that some information might not up to
                        date!</h5>
                </div>
            </div>
        )
    }
}

export default UnderDevelopmentWarning;