import React from "react";
import {EventStyle} from "../../utils/EventStyle";
import './EventCategoryBadge.css';

const EventCategoryBadge = (props) => {
    const eventStyle = EventStyle(props.event);

    const getClassNames = () => {
        let badgeClasses = 'text-white rounded mb-2 mr-2 pl-2 pr-2 text-center d-inline-flex align-items-center';
        badgeClasses += " " + eventStyle.backgroundColor;
        badgeClasses += " " + eventStyle.textColor;

        return badgeClasses;
    };

    let style = null;
    if (props.small) {
        style = {width: "60px", display: "block"}
    }

    return (
        <span className={getClassNames()} style={style}>{eventStyle.eventTypeTitle}</span>
    )
};

export default EventCategoryBadge;