import * as actionTypes from "../actions/eventActions";

const initialState = {
    events: [],
    classes: [],
    currentDate: null,
    clubs: []
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_EVENTS:
            return {
                ...state,
                events: action.events,
                classes: action.classes
            };
        case actionTypes.CLEAR_EVENTS:
            return {
                ...state,
                events: [],
                classes: []
            };
        case actionTypes.SET_CURRENT_DATE:
            return {
                ...state,
                currentDate: action.value
            };
        case actionTypes.FETCH_CLUBS:
            return {
                ...state,
                clubs: action.clubs
            };
        default:
            return state;
    }
}

export default eventReducer;