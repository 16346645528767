import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Jumbotron, Row} from "react-bootstrap";
import BackButton from "../../../component/BackButton/BackButton";
import {API_EVENT_PROCESSING_LOGS, Url} from "../../../constants";
import {simpleAuthenticatedQuery} from "../../../utils/connection";
import {FullDateFormatter, TimeFormatter} from "../../../component/DateTimeFormatter/DateTimeFormatter";

const EventProcessorLogs = () => {
    const [data, setData] = useState([]);

    const fetchData = useCallback((apiUrl, setData) => {
        return simpleAuthenticatedQuery(apiUrl, (data) => {
            setData(data)
        }, () => {
            console.log("Error during fetching event processing logs");
            setData([])
        });
    }, [])

    useEffect(() => {
        const controller = fetchData(API_EVENT_PROCESSING_LOGS, setData);
        return () => controller.abort();
    }, [fetchData])

    console.log(data)

    let i = 0;
    const logs = data.map(log => {
        i++;

        return (
            <>
                <div className="separator-line"/>

                <Row key={"key-" + i}>
                    <Col>
                        <FullDateFormatter date={log.timestamp}/> <TimeFormatter date={log.timestamp}/>
                    </Col>
                    <Col>
                        <div>{log.message}</div>
                    </Col>
                </Row>
            </>
        )
    });

    return (
        <Container className="space-top">
            <Jumbotron className="mb-3 p-3">
                <h5>Event Processing logs</h5>
            </Jumbotron>

            <BackButton backUrl={Url.PROFILE} className="mb-2"/>

            {logs}
        </Container>
    );
};

export default EventProcessorLogs;