import React, {useMemo, useState} from 'react';
import EventFilterButtons from "../../component/EventFilterButtons/EventFilterButtons";
import {useSelector} from 'react-redux';
import localization from "../../utils/localization";
import HeadLayout from "../../layout/HeadLayout/HeadLayout";
import ScrollLink from "../../component/ScrollLink/ScrollLink";
import EventPage from "./EventPage";

const Events = () => {
    const originalEvents = useSelector(state => state.eventReducer.events);
    const [filteredEvents, setFilteredEvents] = useState([]);

    const Filter = useMemo(() => (
        <EventFilterButtons events={originalEvents} onFilter={setFilteredEvents}/>
    ), [originalEvents]);

    return(
        <>
            <HeadLayout pic="events.jpg" bgPositionX="center" copyright={localization.copyrightZoli}>
                <h1 className="text-white text-center">
                    {localization.headerEvents}
                </h1>

                <div className="text-light text-center mt-4 mt-md-5">
                    <h4>{localization.headerEventsDescription1}</h4>
                    <p className="mt-4">{localization.headerEventsDescription2}</p>
                </div>

                <div className="d-flex justify-content-center text-white mt-4 mt-md-5">
                    <ScrollLink to="calendar" text={localization.headerEventsButtonCalendar}/>
                    <ScrollLink className="ml-2" to="event-list" text={localization.headerEventsButtonUpcomingEvents}/>
                </div>
            </HeadLayout>

            <EventPage filter={Filter} events={filteredEvents}/>
        </>
    )
};

export default Events;