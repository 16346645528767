import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import localization from "../../../utils/localization";
import Alert from "react-s-alert";
import {
    API_CLUBS,
    API_TEACHERS,
    COUNTRY_HUNGARY,
    COUNTRY_POLAND,
    COUNTRY_SERBIA,
    IMG_DEFAULT_EVENT,
    IMG_UNKNOWN,
    PATH_NEW,
    S3_IMAGES,
    Url
} from "../../../constants";
import {useParams} from "react-router-dom";
import {
    CKEditorField,
    CustomForm,
    EmailField,
    HiddenField,
    InputField,
    VARCHAR_MAX_LENGTH
} from "../../../component/FormInputs/FormInputs";
import EditPictureModal from "../../../component/FormModal/EditPictureModal";
import DialogPanel from "../../../component/DialogPanel/DialogPanel";
import useCreateOrModify from "../../../hook/useCreateOrModify";
import GeneralFormButtons from "../../../component/FormModal/GeneralFormButtons";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import ImageAdministrator from "../../../component/ImageAdministrator/ImageAdministrator";
import DialogLayout from "../../../layout/DialogLayout/DialogLayout";
import {generatePictureFileName, mapClubDescriptions} from "../../../utils/helperFunctions";
import CountrySelect from "../../../component/CountrySelect";
import {useSelector} from "react-redux";

const EditClubPage = (props) => {
    const formControl = useForm();
    const country = useSelector(state => state.uiReducer.country);
    const [tabKey, setTabKey] = useState('en');
    const currentUser = useSelector(state => state.userReducer.currentUser);
    const [galleryPicturePath, setGalleryPicturePath] = useState(new Array(10).fill(null));
    const {id} = useParams();
    const isModify = id !== PATH_NEW;
    const {createOrModify, fetchData, loading} = useCreateOrModify();
    const [data, setData] = useState({
        countryId: country,
        picture: IMG_UNKNOWN,
        pictureCover: IMG_DEFAULT_EVENT,
        descriptions: []
    });

    const tabIds = [];

    const languageTabs = () => {
        const tabs = [];

        tabs.push(createTab("en", "English", "gb"));

        if (currentUser.privileges.countries.includes(COUNTRY_HUNGARY)) {
            tabs.push(createTab("hu", "Magyar", "hu"));
        }

        if (currentUser.privileges.countries.includes(COUNTRY_SERBIA)) {
            tabs.push(createTab("rs", "Srpski", "rs"));
        }

        if (currentUser.privileges.countries.includes(COUNTRY_POLAND)) {
            tabs.push(createTab("pl", "Polski", "pl"));
        }

        return tabs;
    }

    const convertDescriptions = (submittedData) => {
        const desc = [];
        for (const tabId of tabIds) {
            desc.push(convertDescription(submittedData, tabId));
        }

        return desc;
    }

    const s3Folder = props.teacher ? "teacher" : "club";
    const apiUrl = props.teacher ? API_TEACHERS : API_CLUBS;
    const redirectUrl = props.teacher ? Url.TEACHER_ADMIN : Url.MY_CLUBS;
    const editPictureButton = <div className="btn btn-primary mt-1">{localization.formUploadPicture}</div>
    const descriptions = mapClubDescriptions(data.descriptions);

    const processData = (data) => {
        if (data.pictureGallery) {
            const pictureGallery = JSON.parse(data.pictureGallery);
            setGalleryPicturePath(pictureGallery);
        }

        setData(data);
    }

    useEffect(() => {
        if (isModify) {
            const controller = fetchData(apiUrl, processData);
            return () => controller.abort();
        }
    }, [isModify, fetchData, apiUrl])

    const updatePicture = (name, picturePath) => {
        formControl.setValue(name, picturePath);
        setData(formControl.getValues());
    };

    const updateProfilePicture = (picturePath) => updatePicture("picture", picturePath);
    const updateCoverPicture = (picturePath) => updatePicture("pictureCover", picturePath);

    const convertPictureGallery = () => {
        const filtered = galleryPicturePath.filter(x => x !== null);
        if (filtered.length > 0)
            return JSON.stringify(galleryPicturePath);
        else
            return null;
    }

    const validateFields = (submittedData) => {
        const LONG_DESCRIPTION_MAX_SIZE = 5000;
        let validationSuccess = true;

        submittedData.descriptions.forEach(
            desc => {
                if (desc.longDescription && desc.longDescription.length > LONG_DESCRIPTION_MAX_SIZE) {
                    Alert.error(desc.language + ": " + localization.validationDetailedDescriptionSizeError);
                    validationSuccess = false;
                }
            }
        );

        if (+submittedData.country !== country) {
            Alert.error(localization.validationNoProperCountryPrivilege);
            validationSuccess = false;
        }

        return validationSuccess;
    }

    const convertDescription = (submittedData, langId) => {
        const item = {
            language: langId.toUpperCase(),
            shortDescription: submittedData["title_" + langId],
            longDescription: submittedData["description_" + langId]
        };

        delete submittedData["title_" + langId];
        delete submittedData["description_" + langId];

        return item;
    }

    const onSubmit = async submittedData => {
        submittedData.pictureGallery = convertPictureGallery();
        submittedData.descriptions = convertDescriptions(submittedData);

        if (validateFields(submittedData)) {
            setData(submittedData);

            const createUrl = apiUrl + submittedData.country;
            const modifyUrl = apiUrl + submittedData.country + "/" + id;
            await createOrModify(createUrl, modifyUrl, submittedData, redirectUrl);
        }
    };

    const getTitle = () => {
        if (isModify)
            return data.name;
        else
            return props.teacher ? localization.actionAddTeacher : localization.actionCreateClub;
    }

    const clubFileName = generatePictureFileName(data.name);

    const createTab = (langId, lang, flagId) => {
        tabIds.push(langId);
        const langIdUpper = langId.toUpperCase();

        return (
            <Tab key={"tab-" + langId} eventKey={langId}
                 title={<div><span className={"flag-icon flag-icon-" + flagId}/> {lang}</div>}>
                <div className={"border bg-light p-2"}>
                    <InputField formControl={formControl} defaultValue={descriptions["shortDescription" + langIdUpper]}
                                name={"title_" + langId} label={localization.formDescriptionShort}
                                maxLength={VARCHAR_MAX_LENGTH}/>
                    <CKEditorField formControl={formControl}
                                   defaultValue={descriptions["longDescription" + langIdUpper]}
                                   name={"description_" + langId} label={localization.formDescriptionLong}/>
                </div>
            </Tab>
        )
    }

    return (
        <div className="container space-top">
            <DialogPanel loading={loading} title={getTitle()}>
                <CustomForm formControl={formControl} onSubmit={onSubmit}>
                    <HiddenField formControl={formControl} name="id" defaultValue={data.id}/>
                    <HiddenField formControl={formControl} name="picture" value={data.picture}/>
                    <HiddenField formControl={formControl} name="pictureCover" value={data.pictureCover}/>

                    <DialogLayout title={localization.formGeneralInformation}>
                        <Row>
                            <Col md={4}>
                                <div className="form-group">
                                    <label htmlFor="picture"
                                           className="font-weight-bold">{localization.formProfilePicture}</label>
                                    <img id="picture" className="img-fluid" src={S3_IMAGES + data.picture}
                                         alt={data.name + " " + localization.formProfilePicture}/>

                                    <EditPictureModal s3Folder={s3Folder} fileName={clubFileName} width={400}
                                                      height={400} onUpdate={updateProfilePicture}
                                                      trigger={editPictureButton}/>
                                </div>

                                <div className="separator-line my-3"/>

                                <div className="form-group">
                                    <label htmlFor="picture"
                                           className="font-weight-bold">{localization.formCoverPicture}</label>
                                    <img id="picture" className="img-fluid" src={S3_IMAGES + data.pictureCover}
                                         alt={data.name + " " + localization.formCoverPicture}/>

                                    <EditPictureModal s3Folder={s3Folder} fileName={clubFileName} width={800}
                                                      height={400} onUpdate={updateCoverPicture}
                                                      trigger={editPictureButton}/>
                                </div>
                            </Col>

                            <Col md={8}>
                                <InputField required formControl={formControl} defaultValue={data.name} name="name"
                                            label={localization.formName} maxLength={40}/>
                                <EmailField required formControl={formControl} defaultValue={data.email}/>

                                <CountrySelect formControl={formControl} defaultValue={data.countryId} admin/>

                                <InputField formControl={formControl} defaultValue={data.facebook} name="facebook"
                                            label="Facebook"/>
                                <InputField formControl={formControl} defaultValue={data.webPage} name="webPage"
                                            label={localization.webPage}/>
                                <InputField formControl={formControl} defaultValue={data.phone} name="phone"
                                            label={localization.phone}/>

                                {props.teacher
                                    ? null :
                                    <InputField formControl={formControl} defaultValue={data.address} name="address"
                                                label={localization.address}/>}

                                {currentUser.privileges.root && !props.teacher
                                    ? <InputField formControl={formControl} defaultValue={data.hashtag} name="hashtag"
                                                  label={localization.formHashtag}/>
                                    : <HiddenField formControl={formControl} name="hashtag" value={data.hashtag}/>}
                            </Col>
                        </Row>
                    </DialogLayout>

                    <DialogLayout title={localization.formDescription}>
                        <Tabs id="description-tab" className="tab-style" activeKey={tabKey}
                              onSelect={(k) => setTabKey(k)}>
                            {languageTabs()}
                        </Tabs>
                    </DialogLayout>

                    <DialogLayout title={localization.formUploadPictureToGallery}>
                        <Row>
                            <ImageAdministrator id={0} s3Folder={s3Folder} fileName={clubFileName}
                                                onUpdate={setGalleryPicturePath} picture={galleryPicturePath}/>
                            <ImageAdministrator id={1} s3Folder={s3Folder} fileName={clubFileName}
                                                onUpdate={setGalleryPicturePath} picture={galleryPicturePath}/>
                            <ImageAdministrator id={2} s3Folder={s3Folder} fileName={clubFileName}
                                                onUpdate={setGalleryPicturePath} picture={galleryPicturePath}/>
                            <ImageAdministrator id={3} s3Folder={s3Folder} fileName={clubFileName}
                                                onUpdate={setGalleryPicturePath} picture={galleryPicturePath}/>
                        </Row>
                    </DialogLayout>

                    <GeneralFormButtons redirectUrl={redirectUrl} isModify={isModify}/>

                </CustomForm>
            </DialogPanel>
        </div>
    );
};

export default EditClubPage;