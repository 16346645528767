import React from 'react';
import {Button, Card} from "react-bootstrap";
import DancePartnerMessageModal from "../../page/extras/dancePartnerSeeker/DancePartnerMessageModal";
import linkifyHtml from "linkify-html";
import localization from "../../utils/localization";
import EditDancePartnerAdvertisementModal from "../../page/user/profile/dancePartnerSeeker/EditDancePartnerAdvertisementModal";
import {DeleteConfirmModal} from "../FormModal/ConfirmModal";
import {FullDateFormatter} from "../DateTimeFormatter/DateTimeFormatter";
import {
    getRoleText,
    getUserAgeOptionText,
    getUserSkillLevelOptionText,
    isPreferredClubOptionVisible
} from "../../utils/enumMapper";
import {
    DANCE_PARTNER_CATEGORY_CAMP,
    DANCE_PARTNER_CATEGORY_COURSE, DANCE_PARTNER_CATEGORY_PRACTICE, DANCE_PARTNER_CATEGORY_REGISTRATION,
    DANCE_PARTNER_CATEGORY_WORKSHOP,
    DEFAULT_CLUB_NAME,
    S3_IMAGES
} from "../../constants";

const DancePartnerCard = (props) => {
    const introduction = linkifyHtml(props.data.introduction);
    const imageUrl = S3_IMAGES + props.data.picture;
    const clubVisible = isPreferredClubOptionVisible(props.data.category);

    let expiredBackground = "";
    let expirationDate;

    if (props.edit) {
        if (new Date(props.data.expirationDate) < new Date()) {
            expiredBackground = "bg-dark text-white";
            expirationDate = <div className="text-danger font-weight-bold mt-3">{localization.dancePartnerCardExpired}</div>
        } else {
            expirationDate = (
                <div>
                    <div className="font-weight-bold mt-3">{localization.dancePartnerCardExpiryDate}: </div> <FullDateFormatter date={props.data.expirationDate} />
                </div>
            )
        }
    }

    const onDeleteAction = () => {
        props.onDelete(props.data.id);
    }

    const getPreferredClubName = () => {
        const clubName = props.data.preferredClub;
        if (!clubName || clubName === DEFAULT_CLUB_NAME)
            return localization.notSpecified;
        else
            return clubName;
    }

    const ButtonBar = () => {
        if (props.hideButtons) {
            return null;
        }

        if (props.edit) {
            const editButton = <Button className="ml-auto" size="sm">
                <i className="fas fa-edit mr-1"/>{localization.actionModify}
            </Button>

            const deleteButton = <Button className="ml-auto" size="sm" variant="danger">
                <i className="fas fa-trash-alt mr-1"/>{localization.actionDelete}
            </Button>

            return (
                <div className="d-flex">
                    <DeleteConfirmModal title={localization.dancePartnerCardTitleAdvertisement} trigger={deleteButton} onAction={onDeleteAction}/>
                    <div className="ml-2" />
                    <EditDancePartnerAdvertisementModal trigger={editButton} id={props.data.id} onSubmit={props.onSubmit}/>
                </div>
            )
        } else {
            const cabeceoButton = <Button className="ml-auto" size="sm">
                <i className="far fa-eye mr-2"/>Cabeceo
            </Button>

            return <DancePartnerMessageModal trigger={cabeceoButton}
                                             id={props.data.id}
                                             language={props.data.language}
                                             category={getDancePartnerCategoryName(props.category)}/>
        }
    }

    const getDancePartnerCategoryName = (category) => {
        switch (category) {
            case DANCE_PARTNER_CATEGORY_COURSE:
                return  localization.dancePartnerCardCategoryCourse;
            case DANCE_PARTNER_CATEGORY_PRACTICE:
                return  localization.dancePartnerCardCategoryPractice;
            case DANCE_PARTNER_CATEGORY_WORKSHOP:
                return localization.dancePartnerCardCategoryWorkshop;
            case DANCE_PARTNER_CATEGORY_CAMP:
                return localization.dancePartnerCardCategoryCamp;
            case DANCE_PARTNER_CATEGORY_REGISTRATION:
                return  localization.dancePartnerCardCategoryRegistration;
            default:
                return "No category";
        }
    }

    const DancePartnerCategoryBadge = (props) => {
        const title = getDancePartnerCategoryName(props.category);
        let color;

        switch (props.category) {
            case DANCE_PARTNER_CATEGORY_COURSE:
                color = "bg-success";
                break;
            case DANCE_PARTNER_CATEGORY_PRACTICE:
                color = "bg-warning";
                break;
            case DANCE_PARTNER_CATEGORY_WORKSHOP:
                color = "bg-info";
                break;
            case DANCE_PARTNER_CATEGORY_CAMP:
                color = "bg-danger";
                break;
            case DANCE_PARTNER_CATEGORY_REGISTRATION:
                color = "bg-dark";
                break;
            default:
                color = "bg-secondary";
        }

        return (
            <span className={"p-1 d-block text-center font-weight-bold text-white " + color}>
                {title}
            </span>
        )
    }

    return (
        <Card className={"shadow-sm bg-light " + expiredBackground}>

            {props.edit ? (
                <div className="d-flex ml-auto m-1">
                    <ButtonBar />
                </div>
            ) : (
                <img style={{marginTop: "-100px"}} className="mx-auto rounded-top" src={imageUrl} alt={localization.formProfilePicture} width="256" height="256"/>
            )}

            <DancePartnerCategoryBadge category={props.data.category}/>

            {props.edit ? null : (
                <>
                    <div className="mt-1 ml-2 text-center" style={{fontSize: "20px"}}>{props.data.name}</div>

                    <div className="d-flex mx-auto m-1">
                        <ButtonBar />
                    </div>
                </>
            )}

            <div className="text-center">
                {expirationDate}
            </div>

            <div className="separator-line my-2" />

            <div className="pl-2">
                <div>
                    <span className="font-weight-bold">{localization.dancePartnerCardRole}: </span> {getRoleText(props.data.role)}
                </div>

                <div>
                    <span className="font-weight-bold">{localization.dancePartnerCardLevel}: </span> {getUserSkillLevelOptionText(props.data.info.skillLevel)}
                </div>

                <div>
                    <span className="font-weight-bold">{localization.dancePartnerCardActiveYears}: </span> {props.data.info.activeDancingYears}
                </div>

                <div>
                    <span className="font-weight-bold">{localization.dancePartnerCardHeight}: </span> {props.data.info.bodyHeight} cm
                </div>

                <div>
                    <span className="font-weight-bold">{localization.dancePartnerCardAge}: </span> {getUserAgeOptionText(props.data.info.age)}
                </div>

                <div>
                    {clubVisible ? (
                        <>
                            <span className="font-weight-bold">{localization.dancePartnerCardPreferredClub}: </span> {getPreferredClubName()}
                        </>
                    ) : null}
                </div>
            </div>

            <div className="p-2 mt-2 mt-md-1 text-justify" dangerouslySetInnerHTML={{ __html: introduction }}/>
        </Card>
    )
};

export default DancePartnerCard;