import React from 'react';
import {Row, Col, Button} from "react-bootstrap";
import {SubmitButton} from "../FormInputs/FormInputs";
import localization from "../../utils/localization";
import {useNavigation} from "../../hook/useNavigation";

const GeneralFormButtons = (props) => {
    const navigation = useNavigation();
    let okButtonCaption = localization.formSave;

    const redirect = () => {
        navigation.replaceHistory(props.redirectUrl);
    }

    return (
        <>
            <div className="separator-line mb-3 mt-3" />

            <Row>
                <Col lg={4} />
                <Col lg={4}>
                    {props.hideDiscard ? null : <Button variant="secondary" className="btn-block mt-3 mt-lg-0" onClick={redirect}>{localization.formDiscard}</Button>}
                </Col>
                <Col lg={4}>
                    {props.hideOkButton ? null : (
                        <div className="mt-3 mt-lg-0">
                            <SubmitButton caption={okButtonCaption}/>
                        </div>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default GeneralFormButtons;