import React from "react";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {Card} from "react-bootstrap";

const DialogPanel = (props) => {
    const title = props.title ? (
        <Card.Header className={"font-weight-bold text-center h4"}>
            {props.title}
        </Card.Header>
    ) : null;

    return (
        <div className={"dialog-shadow " + props.className}>
            { props.loading ? (
                <Card style={{height: "400px"}}>
                    <LoadingIndicator />
                </Card>
            ) : (
                <>
                    {title}
                    <Card.Body>
                        {props.children}
                    </Card.Body>
                </>
            )}
        </div>
    )
}

export default DialogPanel;