import React from 'react';
import EventCard from "../../component/EventCard/EventCard";
import localization from "../../utils/localization";
import {Col, Container, Row} from "react-bootstrap";

const EventListSection = (props) => {
    let eventCards = (
        <Container>
            <h5 className="mt-3 text-danger text-center">{localization.warningEventNotFound}</h5>
        </Container>
    );

    if (props.events.length > 0) {
        const currentDate = new Date().setHours(0,0,0,0);

        eventCards = props.events.map(event => {
            const eventDate = new Date(event.startDateTime).setHours(0,0,0,0);

            if (currentDate <= eventDate)
                return (
                    <Col sm={10} className="mt-3" key={event.id}>
                        <EventCard event={event}/>
                    </Col>
                );
            else
                return null
            })
    }

    return (
        <section id="event-list">
            <h1 className="text-center mt-5">
                {localization.sectionTitleUpcomingEvents}&nbsp;
            </h1>

            <Row className="justify-content-center">
                {eventCards}
            </Row>
        </section>
    );
};

export default EventListSection;