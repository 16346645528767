import React from "react";
import DataTable from "react-data-table-component";
import localization from "../../../utils/localization";
import {FullDateFormatter, TimeFormatter} from "../../../component/DateTimeFormatter/DateTimeFormatter";
import {EventStyle} from "../../../utils/EventStyle";
import {CUSTOM_TABLE_STYLES} from "../../../utils/tableUtils";
import TooltipOverlay from "../../../component/TooltipOverlay/TooltipOverlay";
import {isPastEvent} from "../../../component/EventFilterButtons/filtersFunctions";
import {capitalize} from "../../../utils/helperFunctions";

const EventTableBase = (props) => {
    const conditionalRowStyles = [
        {
            when: row => isPastEvent(row.startDateTime),
            style: {
                backgroundColor: 'rgb(204,204,204)',
                '&:hover': {
                    backgroundColor: 'rgb(184,184,184)',
                },
            },
        },
    ];

    const renderRecurringIcon = (row) => {
        if (row.recurringId)
            return <TooltipOverlay text={localization.formRecurringEvent}><i className="fas fa-redo"/></TooltipOverlay>
    }

    const columns = [
        {
            cell: (row) => props.actionButtonBar(row),
            ignoreRowClick: true,
        },
        {
            grow: 0,
            right: true,
            cell: (row) => renderRecurringIcon(row)
        },
        {
            name: localization.formName,
            selector: 'title',
            sortable: true
        },
        {
            name: localization.filterEventType,
            selector: 'eventType',
            sortable: true,
            format: (row) => EventStyle(row).eventTypeTitle
        },
        {
            name: localization.formClub,
            selector: 'clubMeta.name',
            sortable: true,
            format: (row) => row.clubMeta.defaultClub ? localization.eventIndependent : row.clubMeta.name
        },
        {
            name: localization.eventStartDate,
            selector: 'startDateTime',
            sortable: true,
            format: (row) => <><FullDateFormatter date={row.startDateTime}/> <TimeFormatter date={row.startDateTime}/></>
        },
        {
            name: localization.eventEndDate,
            selector: 'endDateTime',
            sortable: true,
            format: (row) => <><FullDateFormatter date={row.endDateTime}/> <TimeFormatter date={row.endDateTime}/></>
        },
        {
            name: localization.eventLocation,
            sortable: true,
            selector: 'location',
        },
        {
            name: localization.eventSource,
            sortable: true,
            selector: 'dataSource',
            format: (row) => row.dataSource === "MILONGA_FINDER" ? "Web" : capitalize(row.dataSource.toLowerCase())
        },
    ];

    return (
        <DataTable
            title={props.title}
            data={props.data}
            columns={columns}
            customStyles={CUSTOM_TABLE_STYLES}
            subHeader
            subHeaderComponent={props.subHeaderComponent}
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent={localization.warningClassNotFound}
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25, 50, 100]}
            pagination
            paginationComponentOptions={{
                rowsPerPageText: localization.tableRowsPerPage + ":",
                rangeSeparatorText: '/',
                noRowsPerPage: false }}
        />
    );
};

export default EventTableBase;