import React, {useState} from 'react';
import ImageGallery from 'react-image-gallery';
import './ClubDetailsImageGallery.scss';
import LoadingIndicator from "../../../component/LoadingIndicator/LoadingIndicator";
import {S3_IMAGES} from "../../../constants";

const ClubDetailsImageGallery = (props) => {
    const [loading, setLoading] = useState(true);

    const onImageLoaded = () => {
        setLoading(false);
    }

    let pictureGallery = [];
    if (props.pictures)
        pictureGallery = JSON.parse(props.pictures);

    const images = pictureGallery
        .filter(path => path !== null)
        .map((path, index) => {
            return {
                original: S3_IMAGES + path,
                originalAlt: props.name + " " + index
            }
    })

    return (
        <div>
            {loading ? (
              <div style={{height: "300px"}}>
                  <LoadingIndicator />
              </div>
            ) : null}

            <ImageGallery items={images}
                          showThumbnails={false}
                          showPlayButton={false}
                          showFullscreenButton={false}
                          showBullets
                          autoPlay
                          onImageLoad={onImageLoaded}/>
        </div>
    );
};

export default ClubDetailsImageGallery;