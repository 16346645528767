import React from "react";
import {SocialIconEmail, SocialIconFacebook, SocialIconWeb} from "../../../component/SocialIcon/SocialIcon";
import localization from "../../../utils/localization";
import {PictureExternalLink} from "../../../component/PictureLink/PictureLink";
import {useSelector} from "react-redux";
import {Card, Col, Row} from "react-bootstrap";

const ArgentineTangoRadio = () => {
    const currentLanguage = useSelector(state => state.uiReducer.currentLanguage);

    const email = "balazs@argentinetangoradio.com";
    const facebook = "https://www.facebook.com/ArgentineTangoRadio";
    let web = "https://www.ArgentineTangoRadio.com";

    if (currentLanguage === 'hu') {
        web = web + "/hu";
    }

    return (
        <Card className="shadow-sm">
            <Card.Body className="p-1">
                <Row className="row">
                    <Col lg={6}>
                        <PictureExternalLink picturePath={process.env.PUBLIC_URL + "/pictures/tangoradiocover.png"}
                                             url={web}/>
                    </Col>

                    <Col lg={6}>
                        <div className="mb-auto">
                            <div className="mt-2 mt-lg-0">
                                {localization.argentineTangoRadioText1}<br/>{localization.argentineTangoRadioText2}
                            </div>

                            <div className="text-center mt-5">
                                <span className="font-weight-bold">{localization.argentineTangoRadioText3}</span>
                            </div>

                            <div className="d-flex justify-content-center">
                                <iframe src="https://play.argentinetangoradio.com"
                                        scrolling="no" frameBorder="no" height="100px" width="120px"
                                        title="Argentine Tango Radio"/>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap justify-content-center mt-0 mt-lg-5">
                            <SocialIconWeb url={web}/>
                            <SocialIconFacebook url={facebook}/>
                            <div className="mb-2 mb-sm-0">
                                <SocialIconEmail url={email}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ArgentineTangoRadio;