import React, {Fragment, useState} from 'react';
import {useForm} from "react-hook-form";
import localization from "../../../../utils/localization";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {useModal} from "../../../../hook/useModal";
import ModalBase from "../../../../component/FormModal/ModalBase";
import {
    CustomForm,
    NumberField,
    PrintGeneralErrorMsg,
    SelectItem,
    SubmitButton
} from "../../../../component/FormInputs/FormInputs";
import LoadingIndicator from "../../../../component/LoadingIndicator/LoadingIndicator";
import {getUserAgeOptions, getUserSkillLevelOptions} from "../../../../utils/enumMapper";
import {simpleAuthenticatedPut} from "../../../../utils/connection";
import {API_USER_INFO} from "../../../../constants";
import userActions from "../../../../store/actions/userActions";
import {useDispatch} from "react-redux";

const EditUserInfoModal = (props) => {
    const modal = useModal();
    const formControl = useForm();
    const dispatch = useDispatch();
    const [invalidBodyHeight, setInvalidBodyHeight] = useState(null);

    const MAX_BODY_HEIGHT = 230;
    const MIN_BODY_HEIGHT = 150;

    const onSubmit = (submittedData) => {
        if (submittedData.bodyHeight < MIN_BODY_HEIGHT) {
            setInvalidBodyHeight(localization.dancePartnerAdminMinBodyHeightError + MIN_BODY_HEIGHT + " cm!");
        } else if (submittedData.bodyHeight > MAX_BODY_HEIGHT) {
            setInvalidBodyHeight(localization.dancePartnerAdminMaxBodyHeightError + MAX_BODY_HEIGHT + " cm!");
        } else {
            modal.setLoading(true);
            simpleAuthenticatedPut(API_USER_INFO, submittedData, () => {
                dispatch(userActions.loadCurrentlyLoggedInUser());
            },  onClose);
        }
    }

    const onClose = () => {
        modal.handleClose();
        setInvalidBodyHeight(null);
    }

    return (
        <Fragment>
            {modal.renderTrigger(props.trigger)}

            <ModalBase show={modal.show} title={localization.profileEditPublicData} size="md">
                {modal.loading ? (
                    <Modal.Body>
                        <div style={{height: "200px"}}>
                            <LoadingIndicator/>
                        </div>
                    </Modal.Body>
                ): (
                        <CustomForm formControl={formControl} onSubmit={onSubmit}>
                            <Modal.Body>
                                <SelectItem formControl={formControl}
                                            defaultValue={props.userInfo.age}
                                            label={localization.dancePartnerCardAge}
                                            name="age"
                                            options={getUserAgeOptions()}/>

                                <PrintGeneralErrorMsg error={invalidBodyHeight} text={invalidBodyHeight}/>
                                <NumberField formControl={formControl}
                                             defaultValue={props.userInfo.bodyHeight}
                                             maxLength={3}
                                             name="bodyHeight"
                                             label={localization.dancePartnerCardHeight + " (cm)"} />

                                <SelectItem formControl={formControl}
                                            defaultValue={props.userInfo.skillLevel}
                                            label={localization.dancePartnerCardLevel}
                                            name="skillLevel"
                                            options={getUserSkillLevelOptions()}/>

                                <NumberField formControl={formControl}
                                             defaultValue={props.userInfo.activeDancingYears}
                                             maxLength={2}
                                             name="activeDancingYears"
                                             label={localization.dancePartnerCardActiveYears} />

                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={onClose}>{localization.formDiscard}</Button>
                                <SubmitButton caption={localization.formSave} />
                            </Modal.Footer>
                        </CustomForm>
                    )}
            </ModalBase>
        </Fragment>
    );
};

export default EditUserInfoModal;
