import {getHost} from "./utils/helperFunctions";

export const profiles = {
    hu : {
        dancePartnerSeekerEnabled: true,
        title: "titleTangoHungary",
        favicon: "favicon_hu.ico",
        defaultLanguage: "hu",
        defaultCountry: 1,
        coverImageHome: "budapest.jpg",
        textHomeHeader: "homeHeaderHU",
        googleAnalyticsId: "G-VEFVTXH3WT"
    },

    krakow : {
        title: "titleTangoKrakow",
        favicon: "favicon_pl.ico",
        defaultLanguage: "en",
        defaultCountry: 3,
        coverImageHome: "krakow.jpg",
        textHomeHeader: "homeHeaderKrakow",
        googleAnalyticsId: "G-0TJN5KBTMR"
    },

    milongaFinder : {
        international: true,
        favicon: "favicon_hu.ico",
        title: "titleMilongaFinder",
        defaultLanguage: "en",
        defaultCountry: 1,
        coverImageHome: "budapest.jpg",
        textHomeHeader: "homeHeader",
        googleAnalyticsId: "G-6QR1RKKX43"
    }
}



export const getProfileByDomain = () => {
    const baseUrl = getHost();

    if (isLocalhost(baseUrl)) {
        return profiles.hu;
    } else if (baseUrl === "tangohungary.hu") {
        return profiles.hu;
    } else if (baseUrl === "milongafinder.com") {
        return profiles.milongaFinder;
    } else if (baseUrl === "krakow.milongafinder.com") {
        return profiles.krakow;
    }

    const error = "Could not find the profile by [" + baseUrl + "]";
    console.error(error);
}

const isLocalhost = (baseUrl) => {
    const localhostRegex = new RegExp("localhost.*");
    const match = localhostRegex.exec(baseUrl);
    return match !== null;
}