import React, {Fragment} from 'react';
import './Profile.css';
import {useDispatch, useSelector} from "react-redux";
import localization from "../../../utils/localization";
import {Button, Col, Container, Row} from "react-bootstrap";
import ProfileSettingsModal from "./ProfileSettingsModal";
import {ModalTriggerEditButton} from "../../../component/ActionButtons/ActionButtons";
import {Url} from "../../../constants";
import SettingCard from "../../../component/SettingCard/SettingCard";
import ChangePasswordModal from "./ChangePasswordModal";
import userActions from "../../../store/actions/userActions";
import {CONFIG} from "../../../config";

const Profile = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.userReducer.currentUser);

    const logOut = () => dispatch(userActions.userLogout());

    const SectionSeparator = (props) => (
        <>
            <h3 className="mt-5">{props.title}</h3>
            <div className="separator-line mb-3" />
        </>
    );

    const SettingCardLayout = (props) => (
        <Col md={6} lg={4} className="mb-5">
            <SettingCard {...props} />
        </Col>
    )

    return (
        <Container>
            <div className="text-center space-top">
                <div className="profile-avatar">
                    <img src={currentUser.getImage()} alt={currentUser.name + " " + localization.formProfilePicture} width={256} height={256}/>
                </div>
                <div>
                    <h2>{currentUser.name}</h2>
                    <p className="profile-email">{currentUser.email}</p>
                </div>

                <div className="d-flex justify-content-center">
                    <Button variant="primary" className="mb-5 ml-3" onClick={logOut}><i className="fas fa-sign-out-alt mr-2"/>{localization.menuLogout}</Button>
                </div>
            </div>

            <SectionSeparator title={localization.profileSectionUser} />

            <Row>
                <SettingCardLayout title={localization.settingCardNameAndProfileTitle}
                                   icon="fas fa-user-edit"
                                   text={localization.settingCardNameAndProfileDescription}
                                   button={<ProfileSettingsModal trigger={ModalTriggerEditButton}/>}/>

                <SettingCardLayout title={localization.settingCardChangePasswordTitle}
                                   icon="fas fa-key"
                                   text={localization.settingCardChangePasswordDescription}
                                   button={<ChangePasswordModal trigger={ModalTriggerEditButton}/>}/>

                {CONFIG.profile.dancePartnerSeekerEnabled ? (
                    <SettingCardLayout title={localization.settingCardDancePartnerSeekerTitle}
                                       icon="fas fa-people-arrows"
                                       text={localization.settingCardDancePartnerSeekerDescription}
                                       button={ModalTriggerEditButton}
                                       url={Url.DANCE_PARTNER_SEEKER_ADMIN}/>
                ) : null}
            </Row>

            {currentUser.privileges.admin ? (
                <Fragment>
                    <SectionSeparator title={localization.profileSectionAdmin} />

                    <Row>
                        <SettingCardLayout title={localization.menuMyClubs}
                                     icon="far fa-address-card"
                                     text={localization.settingCardMyClubsDescription}
                                     button={ModalTriggerEditButton}
                                     url={Url.MY_CLUBS}/>

                        <SettingCardLayout title={localization.menuMyEvents}
                                           icon="far fa-list-alt"
                                           text={localization.settingCardTangoEventsDescription}
                                           button={ModalTriggerEditButton}
                                           url={Url.EVENTS_ADMIN}/>

                        <SettingCardLayout title={localization.menuTeacherAdmin}
                                           icon="fas fa-chalkboard-teacher"
                                           text={localization.settingCardTeacherAdminDescription}
                                           button={ModalTriggerEditButton}
                                           url={Url.TEACHER_ADMIN}/>
                    </Row>
                </Fragment>
            ) : null}

            {currentUser.privileges.root ? (
                <Fragment>
                    <SectionSeparator title={"Facebook Admin"}/>

                    <Row>
                        <SettingCardLayout title={"Event Processor Logs"}
                                           icon="far fa-address-card"
                                           text={"Show Facebook event processing errors."}
                                           button={ModalTriggerEditButton}
                                           url={Url.FACEBOOK_EVENT_ERRORS}/>
                    </Row>
                </Fragment>
            ) : null}
        </Container>
    )
}

export default Profile;