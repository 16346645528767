import {Url} from "../constants";
import localization from "./localization";
import latinize from "latinize";
import {sendGAError} from "./googleAnalytics";

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
}

export function createClubSelectOptions(clubList) {
    const processedOptions = [];

    clubList.map(club => (
        processedOptions.push({value: club.id, text: club.name})
    ));

    return processedOptions;
}

export const generatePictureFileName = (name = "") => {
    const latinizedName = latinize(name.toLowerCase());
    return latinizedName.replace(/\s+/g, " ").replace(/[\s]/g, '-');
}

export const mapClubDescriptions = (descriptions) => {
    const result = {}

    // eslint-disable-next-line array-callback-return
    descriptions && descriptions.map(item => {
        result["shortDescription" + item.language] = item.shortDescription;
        result["longDescription" + item.language] = item.longDescription;
    })

    return result;
}

export const getLocalizedDescription = (description, field) => {
    const result =  description[field + localization.getLanguage().toUpperCase()];
    return result ? result : localization.noDescription;
}

export const getEventUrl = (event) => {
    return Url.EVENTS + '/' + constructDateUrl(new Date(event.startDateTime)) + '/' + event.id;
}

export function constructDateUrl(date) {
    return date.getFullYear() + "/" + (date.getMonth() + 1)
}

export function isEmptyObject(obj) {
    return obj !== null && Object.keys(obj).length === 0 && obj.constructor === Object
}

export function getHost() {
    if (typeof window !== 'undefined') {
        return window.location.host
    } else {
        const errorMessage = "Could not get the host from window.location!";
        console.error(errorMessage);
        sendGAError(errorMessage)
        return null;
    }
}

export function getBaseUrl() {
    if (typeof window !== 'undefined') {
        return window.location.protocol + '//' + window.location.host
    } else {
        const errorMessage = "Could not get the base url from window.location!";
        console.error(errorMessage);
        sendGAError(errorMessage)
        return null;
    }
}