import React, {useCallback, useEffect, useState} from "react";
import localization from "../../utils/localization";
import {S3_IMAGES} from "../../constants";
import Copyright from "../Copyright/Copyright";
import "../../index.css"

const EventPicture = (props) => {
    const [imgClassName, setImgClassName] = useState("img-fluid");

    let picturePath = S3_IMAGES + props.event.picturePath;
    let copyRightText = "";

    function getImageMeta(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject();
            img.src = url;
        })
    }

    const fixHeightOnLargeImage = useCallback((url) => {
        getImageMeta(url).then(img => {
            if (img.height > img.width) {
                setImgClassName("image-fix-height");
            } else {
                setImgClassName("img-fluid");
            }
        });
    }, []);

    useEffect(() => {
        fixHeightOnLargeImage(picturePath);
    }, [fixHeightOnLargeImage, picturePath])

    if (props.event.dataSource === "MILONGA_HU") {
        picturePath = S3_IMAGES + "default/" + props.event.picturePath;
        copyRightText = localization.copyrightZoli;
    }

    if (props.event.dataSource === "FACEBOOK") {
        picturePath = props.event.picturePath;
    }

    return (
        <div>
            <img className={imgClassName} src={picturePath} alt={props.title}/>
            <Copyright text={copyRightText}/>
        </div>
    )
};

export default EventPicture;