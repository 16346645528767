import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import localization from "../../utils/localization";
import {CustomForm, SelectItem} from "../FormInputs/FormInputs";
import {filterName} from "./filtersFunctions";
import {Col, Container, Row} from "react-bootstrap";
import FilterInputName from "./FilterInputName";
import CountrySelect from "../CountrySelect";
import {useSelector} from "react-redux";

const teacherOptions = () => [
    {value: 0, text: localization.filterHintEventType},
    {value: 1, text: localization.tangoSchools},
    {value: 2, text: localization.tangoTeachers},
];

const TeachingFilterButtons = props => {
    const formControl = useForm();
    const [searchName, setSearchName] = useState('');
    const country = useSelector(state => state.uiReducer.country);

    const onChange = (event) => {
        props.onChange(+event.target.value)
    }

    useEffect(() => {
        props.onFilterClubs(filterName(props.clubs, searchName));
        props.onFilterTeachers(filterName(props.teachers, searchName));
    }, [props, searchName]);

    return (
        <Container className="mt-4">
            <h3>{localization.filter}</h3>
            <hr/>

            <CustomForm formControl={formControl}>
                <Row>
                    <Col md={4}>
                        <FilterInputName formControl={formControl} onChange={setSearchName} value={searchName}/>
                    </Col>

                    <Col md={4}>
                        <SelectItem formControl={formControl}
                                    defaultValue={0}
                                    label={localization.filterShow}
                                    name="appearanceFilter"
                                    onChange={onChange}
                                    options={teacherOptions()}/>
                    </Col>

                    <Col md={4}>
                        <CountrySelect formControl={formControl} defaultValue={country}/>
                    </Col>
                </Row>

            </CustomForm>

            <hr/>
        </Container>
    );
};

export default TeachingFilterButtons;