import React from "react";
import InfoBoxLayout from "../../layout/InfoBoxLayout/InfoBoxLayout";
import GoogleMap from "../GoogleMap/GoogleMap";
import localization from "../../utils/localization";

const MapBox = (props) => {
    return props.location ? (
        <InfoBoxLayout title={localization.eventLocation}>
            <GoogleMap location={props.location} title={props.title} />
        </InfoBoxLayout>
    ) : null;
};

export default MapBox;