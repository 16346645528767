import React, {createRef} from "react";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import {dataURItoBlob} from "../../utils/helperFunctions";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import localization from "../../utils/localization";
import CenterText from "../CenterText/CenterText";
import {uploadFile} from "../../utils/connection";

class PictureEditor extends React.Component {
    dropZoneRef = createRef();

    state = {
        image: null,
        scale: 1,
        loading: false,
        errorMessage: null,
        rotate: 0,
        width: this.props.width,
        height: this.props.height
    };

    handleDrop = dropped => {
        this.setState({ image: dropped[0], errorMessage: null })
    };

    handleClick = () => {
        this.dropZoneRef.current.open();
    };

    handleRotation = () => {
        let rotationValue = this.state.rotate + 90;
        if (rotationValue > 270)
            rotationValue = 0;

        let width = this.props.width;
        let height = this.props.height;

        if (rotationValue === 90 || rotationValue === 270) {
            width = this.props.height;
            height = this.props.width;
        }

        this.setState({
            rotate: rotationValue,
            width: width,
            height: height
        });
    };

    setEditorRef = (editor) => {
        this.editor = editor;
    };

    dropZoneStyle = {
        width: `${this.props.width}px`,
        height: `${this.props.height}px`,
        borderWidth: "2px",
        borderStyle: "dashed"
    };

    uploadImage = async file => {
        const formData = new FormData();
        formData.append('file', file, this.props.fileName ? this.props.fileName : this.props.s3Folder);
        formData.append('folder', this.props.s3Folder);
        formData.append('extension', this.props.extension);

        try {
            this.setState({loading: true});
            const response = await uploadFile(formData);
            this.props.onClose(response.data);
        } catch (error) {
            this.setState({
                loading: false,
                errorMessage: localization.loginUnexpectedError
            });
            console.log(error);
        }
    };

    onSave = () => {
        if (this.editor) {
            const canvasScaled = this.editor.getImageScaledToCanvas();
            let image = canvasScaled.toDataURL('image/jpeg', 0.7);
            const blob = dataURItoBlob(image);

            this.uploadImage(blob);
        } else {
            this.setState({
                errorMessage: localization.pictureEditorSaveError
            });
        }
    };

    onZoom = (event) => {
        this.setState({scale: event.target.value });
    };

    render() {
        const scaleValue = 1 + (this.state.scale / 100);
        const isImageLoaded = this.state.image !== null;

        const avatarEditor = (
            <AvatarEditor
                width={this.state.width}
                height={this.state.height}
                image={this.state.image}
                ref={this.setEditorRef}
                className="border border-dark"
                scale={scaleValue}
                rotate={this.state.rotate}
                border={0}/>
        );

        const instructions = (
            <div style={this.dropZoneStyle}>
                <CenterText>
                    {localization.pictureEditorInstruction}
                </CenterText>
            </div>
        );

        const errorMessage = this.state.errorMessage &&
            <h5 className="text-white bg-danger text-center p-2">{this.state.errorMessage}</h5>;

        return (
            <>
                {this.state.loading ? (
                    <div className="my-5">
                        <div className="my-5">
                           <LoadingIndicator/>
                        </div>
                    </div>
                    ) : (
                    <>
                        {errorMessage}
                        <button className="btn btn-primary mb-1" onClick={this.handleClick}>
                            <i className="far fa-folder-open mr-2"/>{localization.pictureEditorBrowse}
                        </button>

                        <button className="btn btn-primary mb-1 ml-2" onClick={this.handleRotation}>
                            <i className="fas fa-sync mr-2"/>{localization.pictureEditorRotate}
                        </button>

                        <Dropzone
                            onDrop={this.handleDrop}
                            ref={this.dropZoneRef}
                            noClick={isImageLoaded}
                            noKeyboard
                            multiple={false}
                            accept="image/jpeg, image/png"
                            style={this.dropZoneStyle}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    {this.state.image ? avatarEditor : instructions}
                                    <input {...getInputProps()} />
                                </div>
                            )}
                        </Dropzone>

                        <div className="mt-1">
                            <span className="font-weight-bold">{localization.pictureEditorZoom}</span>
                            <div style={{ width: `${this.props.width}px` }}>
                                <input className="custom-range" min="1" max="100" step="1" type="range"
                                       onChange={this.onZoom} value={this.state.scale} disabled={!isImageLoaded} />
                            </div>
                        </div>
                    </>)}
            </>
        )
    }
}

PictureEditor.defaultProps = {
    fileName: "",
    extension: "jpg"
};


export default PictureEditor;