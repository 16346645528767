import {
    COUNTRY_HUNGARY, COUNTRY_POLAND, COUNTRY_SERBIA,
    DANCE_PARTNER_CATEGORY_CAMP,
    DANCE_PARTNER_CATEGORY_COURSE, DANCE_PARTNER_CATEGORY_PRACTICE, DANCE_PARTNER_CATEGORY_REGISTRATION,
    DANCE_PARTNER_CATEGORY_WORKSHOP
} from "../constants";
import localization from "./localization";

export const getUserAgeOptions = () => [
    {value: "AGE_NOT_PUBLIC", text: localization.notPublic},
    {value: "AGE_16_20", text: "16-20"},
    {value: "AGE_21_30", text: "21-30"},
    {value: "AGE_31_40", text: "31-40"},
    {value: "AGE_41_50", text: "41-50"},
    {value: "AGE_51_60", text: "51-60"},
    {value: "AGE_OVER_61", text: "61-"}
];

export const getUserSkillLevelOptions = () => [
    {value: "NOT_PUBLIC", text: localization.notPublic},
    {value: "ABSOLUTE_BEGINNER", text: localization.skillLevelAbsoluteBeginner},
    {value: "BEGINNER", text: localization.skillLevelBeginner},
    {value: "BEGINNER_INTERMEDIATE", text: localization.skillLevelBeginnerIntermediate},
    {value: "INTERMEDIATE", text: localization.skillLevelIntermediate},
    {value: "INTERMEDIATE_ADVANCED", text: localization.skillLevelIntermediateAdvanced},
    {value: "ADVANCED", text: localization.skillLevelAdvanced}
];

export const getDancerRoleOptions = () => [
    {value: "LEADER", text: localization.roleLeader},
    {value: "FOLLOWER", text: localization.roleFollower},
];

export const getLanguageOptions = () => [
    {value: "EN", text: "English"},
    {value: "HU", text: "Magyar"},
];

export const getDancePartnerCategoryOptions = () => [
    {value: DANCE_PARTNER_CATEGORY_COURSE, text: localization.dancePartnerCardCategoryCourse},
    {value: DANCE_PARTNER_CATEGORY_PRACTICE, text: localization.dancePartnerCardCategoryPractice},
    {value: DANCE_PARTNER_CATEGORY_WORKSHOP, text: localization.dancePartnerCardCategoryWorkshop},
    {value: DANCE_PARTNER_CATEGORY_CAMP, text: localization.dancePartnerCardCategoryCamp},
    {value: DANCE_PARTNER_CATEGORY_REGISTRATION, text: localization.dancePartnerCardCategoryRegistration},
];

export const getCountryOptions = () => [
    {value: COUNTRY_HUNGARY, text: localization.countryHungary},
    {value: COUNTRY_SERBIA, text: localization.countrySerbia},
    {value: COUNTRY_POLAND, text: localization.countryPoland}
];

export const getUserAgeOptionText = (value) => {
    const option = getUserAgeOptions().find(option => option.value === value);
    return option ? option.text : "-";
}

export const getUserSkillLevelOptionText = (value) => {
    const option = getUserSkillLevelOptions().find(option => option.value === value);
    return option ? option.text : "-";
}

export const getRoleText = (role) => {
    return role === "LEADER" ? localization.roleLeader : localization.roleFollower;
}

export const isPreferredClubOptionVisible = (category) => {
    return category !== DANCE_PARTNER_CATEGORY_CAMP && category !== DANCE_PARTNER_CATEGORY_REGISTRATION;
}