import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const AutoScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <div/>
}

export default AutoScrollToTop;