import React, {useState} from "react";

export function useModal() {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    let onShowCallback = () => {};

    const handleClose = () => {
        setShow(false);
        setLoading(false);
    };

    const handleShow = () => {
        setLoading(false);
        setShow(true);
        onShowCallback();
    };

    const setOnShowCallback = (callback) => {
        onShowCallback = callback;
    }

    const renderTrigger = (triggerComponent) => <span onClick={handleShow}>{triggerComponent}</span>

    return {show, loading, renderTrigger, handleClose, handleShow, setLoading, setOnShowCallback}
}