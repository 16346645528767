import localization from "./localization";

const ERROR_CODE_VALIDATION_VIOLATION = 1000;
const ERROR_CODE_BAD_CREDENTIALS = 2001;
const ERROR_CODE_EMAIL_IN_USE = 2003;
const ERROR_CODE_USER_NOT_FOUND = 2004;
const ERROR_CODE_RESET_TOKEN_NOT_FOUND = 2005;
const ERROR_CODE_RESET_TOKEN_EXPIRED = 2006;
const ERROR_CODE_INVALID_PASSWORD = 2007;
export const ERROR_CODE_USER_LOGGED_OUT = 2008;

function localizeErrorMessage(error) {
    if (error) {
        switch (error.errorCode) {
            case ERROR_CODE_BAD_CREDENTIALS:
                return localization.loginBadCredentials;
            case ERROR_CODE_EMAIL_IN_USE:
                return localization.loginEmailInUse;
            case ERROR_CODE_USER_NOT_FOUND:
                return localization.loginResetPasswordUserNotFound;
            case ERROR_CODE_RESET_TOKEN_EXPIRED:
                return localization.loginResetPasswordTokenExpired;
            case ERROR_CODE_RESET_TOKEN_NOT_FOUND:
                return localization.loginResetPasswordTokenExpired;
            case ERROR_CODE_INVALID_PASSWORD:
                return localization.errorInvalidPassword;
            case ERROR_CODE_VALIDATION_VIOLATION:
                return localization.validationError;
            default:
                return localization.loginUnexpectedError;
        }
    } else {
        return localization.loginUnexpectedError;
    }
}

export default localizeErrorMessage;