import {constructDateUrl} from "../../utils/helperFunctions";
import {simpleQuery} from "../../utils/connection";
import {API_CLUBS, API_EVENTS} from "../../constants";

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const SET_CURRENT_DATE = "SET_CURRENT_DATE";
export const FETCH_CLUBS = "FETCH_CLUBS";

const fetchEvents = (country, date) => {
    return (dispatch) => {
        dispatch(clearEvents());
        if (date === null)
            date = new Date();

        const urlParameters = constructDateUrl(date);
        simpleQuery(API_EVENTS + `all/${country}/` + urlParameters, events => processEventsByType(dispatch, events));
    }
};

const setCurrentDate = (newDate) => {
    return {
        type: SET_CURRENT_DATE,
        value: newDate
    }
};

function clearEvents() {
    return {
        type: CLEAR_EVENTS
    }
}

const fetchEventsAction = (events, classes) =>{
    return {
        type: FETCH_EVENTS,
        events: events,
        classes: classes
    }
};

const processEventsByType = (dispatch, data) => {
    const events = [];
    const classes = [];

    for (let id in data) {
        const event = data[id];

        if (event.eventMeta.categoryEvent)
            events.push(event);

        if (event.eventMeta.categoryClass)
            classes.push(event);
    }

    dispatch(fetchEventsAction(events, classes));
};

const fetchClubNamesAction = (dispatch, clubs) => {
    return {
        type: FETCH_CLUBS,
        clubs: clubs
    }
}

const fetchClubNames = (country) => {
    return (dispatch) => {
        simpleQuery(API_CLUBS + `all/${country}`, clubs => dispatch(fetchClubNamesAction(dispatch, clubs)));
    }
}

export default {
    fetchClubNames,
    fetchEvents,
    setCurrentDate
}