import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from "./store/reducers";
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
import {initialiseGoogleAnalytics} from "./utils/googleAnalytics";

initialiseGoogleAnalytics();

// Redux
const composeEnhancers = composeWithDevTools({
    trace: true
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk),
));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
