import React from "react";
import {ACCESS_TOKEN, Url} from "../../../constants";
import Alert from "react-s-alert";
import localization from "../../../utils/localization";
import {useForm} from 'react-hook-form'
import {CustomForm, EmailField, PasswordField, SubmitButton} from "../../../component/FormInputs/FormInputs";
import SocialLogin from "../SocialLogin/SocialLogin";
import {useDispatch} from "react-redux";
import localizeErrorMessage from "../../../utils/errorCodeResolver";
import {login} from "../../../utils/connection";
import {LocalizedLink, useNavigation} from "../../../hook/useNavigation";
import userActions from "../../../store/actions/userActions";
import {useLocation} from "react-router-dom";

const LoginForm = () => {
    const location = useLocation();
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const formControl = useForm();

    const setLoading = (value) => dispatch(userActions.loadingAction(value));

    const onSubmit = async (data) => {
        setLoading(true);

        try {
            const response = await login(data);
            setLoading(false);
            localStorage.setItem(ACCESS_TOKEN, response.data);
            dispatch(userActions.loadCurrentlyLoggedInUser());
            navigation.pushHistory(getRedirectUri());
        } catch (error) {
            setLoading(false);
            console.log(error);
            Alert.error(localizeErrorMessage(error));
        }
    };

    const getRedirectUri = () => {
        if (location.state && location.state.pathname) {
            let path = location.state.pathname
            return path.slice(path.lastIndexOf('/'));
        } else {
            return Url.PROFILE;
        }
    }

    return (
        <>
            <h1 className="mb-4 text-center">{localization.formSignIn}</h1>
            <CustomForm formControl={formControl} onSubmit={onSubmit}>
                <EmailField required formControl={formControl}/>
                <PasswordField formControl={formControl}/>
                <SubmitButton caption={localization.formSignIn}/>
            </CustomForm>

            <LocalizedLink className="text-center mt-3" to={Url.FORGOT_PASSWORD}>
                {localization.loginForgotPassword}?
            </LocalizedLink>

            <div className="separator my-3">{localization.formOr}</div>
            <SocialLogin mode="login" target={getRedirectUri()} />
            <div className="text-center text-muted signup-link mt-3">
                {localization.formNewUser} <LocalizedLink to={Url.SIGNUP}>{localization.formSignUp}</LocalizedLink>
            </div>
        </>
    );
}

export default LoginForm;