import React from 'react';
import {Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import {Url} from "../../constants";
import {LocalizedRedirect} from "../../hook/useNavigation";

const PrivateRoute = ({children}) => {
    const authenticated = useSelector(state => state.userReducer.authenticated);

    if (authenticated) {
        return children ? children : <Outlet/>;
    } else {
        return <LocalizedRedirect to={Url.LOGIN}/>;
    }
}

export default PrivateRoute;