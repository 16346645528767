import React from "react";
import ClubTable from "./ClubTable";
import localization from "../../../utils/localization";
import {Url} from "../../../constants";
import BackButton from "../../../component/BackButton/BackButton";
import {Container, Card, Jumbotron} from "react-bootstrap";

const MyClubs = () => (
     <Container className="space-top">
         <Jumbotron className="mb-3 p-3">
             <h5>{localization.infoMyClub1}</h5>
             <p><span className="font-weight-bold mt-2">{localization.infoMyClub2}</span></p>
         </Jumbotron>

         <BackButton backUrl={Url.PROFILE} className="mb-2"/>

         <Card className="shadow-sm">
             <ClubTable/>
         </Card>
     </Container>
);

export default MyClubs;