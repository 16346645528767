import React, {Fragment, useEffect, useMemo, useState} from 'react';
import PersonCard from "../../component/PersonCard/PersonCard";
import CustomDivider from "../../component/UI/UI";
import localization from "../../utils/localization";
import HeadLayout from "../../layout/HeadLayout/HeadLayout";
import ScrollLink, {ScrollUpLink} from "../../component/ScrollLink/ScrollLink";
import {simpleQuery} from "../../utils/connection";
import {API_CLUBS, API_TEACHERS} from "../../constants";
import TeachingFilterButtons from "../../component/EventFilterButtons/TeachingFilterButtons";
import {Container, Row, Col} from "react-bootstrap";
import {useSelector} from "react-redux";

const Teachers = () => {
    const country = useSelector(state => state.uiReducer.country);
    const [clubs, setClubs] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(0);
    const [filteredClubs, setFilteredClubs] = useState([]);
    const [filteredTeachers, setFilteredTeachers] = useState([]);

    const isClubSectionVisible = categoryFilter <= 1;
    const isTeacherSectionVisible = categoryFilter === 0 || categoryFilter === 2;

    const TeachingFilter = useMemo(() => (
        <TeachingFilterButtons
            clubs={clubs}
            teachers={teachers}
            onFilterClubs={setFilteredClubs}
            onFilterTeachers={setFilteredTeachers}
            onChange={setCategoryFilter}/>
    ), [clubs, teachers]);

    useEffect(() => {
        const controllers = [];
        controllers.push(simpleQuery(API_CLUBS + `all/${country}/`, setClubs));
        controllers.push(simpleQuery(API_TEACHERS + `all/${country}/`, setTeachers));

        return () => {
            for (const controller of controllers)
                controller.abort();
        }
    }, [country])

    const clubCards = filteredClubs.map(club => (
        <Col key={club.id} md={4}  lg={4} className="my-5">
            <PersonCard data={club}/>
        </Col>
    ));

    const personCards = filteredTeachers.map(person => (
        <Col key={person.name} md={4}  lg={3} className="my-5">
            <PersonCard data={person}/>
        </Col>
    ));

    return (
        <Fragment>
            <HeadLayout pic="teachers.jpg" bgPositionY="bottom" copyright={localization.copyrightZoli}>
                <h1 className="text-white text-center">
                    {localization.headerTeachers}
                </h1>

                <div className="text-light text-center mt-4 mt-md-5">
                    <h4>{localization.headerTeachersDescription1}</h4>
                    <p className="mt-4">{localization.headerTeachersDescription2}</p>
                </div>

                <div className="d-flex justify-content-center text-white mt-4 mt-md-5">
                    {isClubSectionVisible ? <ScrollLink className="mr-2" to="schools" text={localization.tangoSchools}/> : null}
                    {isTeacherSectionVisible ? <ScrollLink to="teachers" text={localization.tangoTeachers}/> : null}
                </div>
            </HeadLayout>

            <ScrollUpLink to="filter"/>

            <section id="filter">
                {TeachingFilter}
            </section>

            <Container>
                {isClubSectionVisible ? (
                    <section id="schools">
                        <div className="mt-5">
                            <div className="mb-5">
                                <h1 className="text-center ">{localization.tangoSchools}</h1>
                                <CustomDivider/>
                            </div>
                            <Row>
                                {clubCards}
                            </Row>
                        </div>
                    </section>
                ) : null}

                {isTeacherSectionVisible ? (
                    <section id="teachers">
                        <div className="mb-5">
                            <h1 className="text-center">{localization.tangoTeachers}</h1>
                            <CustomDivider/>
                        </div>
                        <Row>
                            {personCards}
                        </Row>
                    </section>
                ) : null}
            </Container>
        </Fragment>
    )
}

export default Teachers