import ReactGA from "react-ga4";
import {CONFIG} from "../config";

export function initialiseGoogleAnalytics() {
    ReactGA.initialize(CONFIG.profile.googleAnalyticsId, {
        debug: false,
        gaOptions: {cookieFlags: 'max-age=7200;secure;samesite=none'}
    });
}

export function sendGAEvent(category, action) {
    ReactGA.event({
        category: category,
        action: action,
    });
}

export function sendGAError(errorMessage) {
    sendGAEvent('Error', errorMessage);
}

export function sendGAPageView(pathname) {
    ReactGA.set({page: pathname});
    ReactGA.send({
        hitType: "pageview",
        page: pathname,
        title: "Custom Title"
    });
}