import React from 'react';
import moment from "moment";
import localization from "../../utils/localization";
import TooltipOverlay from "../TooltipOverlay/TooltipOverlay";
import {useNavigation} from "../../hook/useNavigation";

const GoogleCalendarAddButton = (props) => {
    const {localizedUrl} = useNavigation();

    const BASE_URL = "https://calendar.google.com/calendar/r/eventedit?";
    const DATE_TIME_FORMAT = 'YYYYMMDDTHHmmss';
    const startDate = moment(props.startDateTime).format(DATE_TIME_FORMAT);
    const endDate = moment(props.endDateTime).format(DATE_TIME_FORMAT);
    const localizedEventUrl = localizedUrl(props.eventUrl);

    const urlEncodeQueryData = (data) => {
        const ret = [];
        for (let d in data)
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
    }

    const getBaseUrl = () => {
        if (typeof window !== 'undefined')
            return  window.location.protocol + '//' + window.location.host;
        else
            return null;
    }

    const concatenateBaseUrl = (permalink) => {
        const baseUrl = getBaseUrl();
        return baseUrl ? baseUrl + permalink : '';
    }

    const data = {
        'text': props.title,
        'dates': startDate + '/' + endDate,
        'details': "Link: " + concatenateBaseUrl(localizedEventUrl),
        'location': props.location
    };

    const href = BASE_URL + urlEncodeQueryData(data);

    return (
        <TooltipOverlay text={localization.googleCalendarAdd}>
            <a className="btn btn-primary" href={href} target="_blank" rel="noopener noreferrer">
                <i className="fas fa-plus-square mr-1"/>
                {localization.googleCalendar}
            </a>
        </TooltipOverlay>
    )
};

export default GoogleCalendarAddButton;