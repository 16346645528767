import React from "react";
import {Link} from "react-scroll";
import "./ScrollLink.css";

const ScrollLink = (props) => (
    <Link className={"btn btn-primary " + props.className} to={props.to} spy={false} smooth={true} offset={-70} duration={500}>
        {props.text}
    </Link>
);

export const ScrollUpLink = (props) => (
    <Link to={props.to} spy={false} smooth={true} offset={-70} duration={500}>
        <button className="scroll">
            <i className="fas fa-chevron-up"/>
        </button>
    </Link>
);

export default ScrollLink;