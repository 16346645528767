import React from "react";
import {login, signup} from "../../../utils/connection";
import Alert from "react-s-alert";
import localization from "../../../utils/localization";
import { useForm } from 'react-hook-form'
import {CustomForm, EmailField, PasswordField, SubmitButton, InputField} from "../../../component/FormInputs/FormInputs";
import SocialLogin from "../SocialLogin/SocialLogin";
import {useDispatch} from "react-redux";
import localizeErrorMessage from "../../../utils/errorCodeResolver";
import {ACCESS_TOKEN, USERNAME_MAX_LENGTH, Url} from "../../../constants";
import {LocalizedLink, useNavigation} from "../../../hook/useNavigation";
import userActions from "../../../store/actions/userActions";

const SignupForm = () => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const formControl = useForm();

    const setLoading = (value) => dispatch(userActions.loadingAction(value));

    const onSubmit = async (data) => {
        setLoading(true);
        data.language = localization.getLanguage().toUpperCase();

        try {
            await signup(data);
            const response = await login(data);
            setLoading(false);
            localStorage.setItem(ACCESS_TOKEN, response.data);
            dispatch(userActions.loadCurrentlyLoggedInUser());
            navigation.pushHistory(Url.PROFILE);
        } catch (error) {
            setLoading(false);
            console.log(error);
            Alert.error(localizeErrorMessage(error));
        }
    };

    return (
        <>
            <h1 className="mb-4 text-center">{localization.formSignUp}</h1>

            <CustomForm formControl={formControl} onSubmit={onSubmit}>
                <InputField required formControl={formControl} name="name" label={localization.formName} maxLength={USERNAME_MAX_LENGTH}/>
                <EmailField required formControl={formControl}/>
                <PasswordField formControl={formControl}/>
                <SubmitButton caption={localization.formSignUp}/>
            </CustomForm>

            <div className="separator my-3">{localization.formOr}</div>
            <SocialLogin mode="register"/>
            <div className="text-center text-muted signup-link mt-3">
                {localization.formHasAccount} <LocalizedLink to={Url.LOGIN}>{localization.formSignIn}</LocalizedLink>
            </div>
        </>
    );
}

export default SignupForm;