import React from "react";
import TooltipOverlay from "../TooltipOverlay/TooltipOverlay";
import localization from "../../utils/localization";
import {Button} from "react-bootstrap";

export const ActionButtonEditCore = (props) => {
    return (
        <TooltipOverlay text={localization.actionModify}>
            <button className="btn btn-light" onClick={props.onClick}>
                <i className="fas fa-edit"/>
            </button>
        </TooltipOverlay>
    )
};

export const ActionButtonDeleteCore = (props) => {
    return (
        <TooltipOverlay text={localization.actionDelete}>
            <button className="btn btn-danger" onClick={props.onClick}>
                <i className="fas fa-trash-alt"/>
            </button>
        </TooltipOverlay>
    )
};

export const ActionButtonCopyCore = (props) => {
    return (
        <TooltipOverlay text={localization.actionCopy}>
            <button className="btn btn-light" onClick={props.onClick}>
                <i className="far fa-copy"/>
            </button>
        </TooltipOverlay>
    )
};

export const ActionButtonPictureUploadCore = (props) => {
    return (
        <TooltipOverlay text={localization.formUploadPicture}>
            <div className="btn btn-primary" onClick={props.onClick}>
                <i className="fas fa-file-upload"/>
            </div>
        </TooltipOverlay>
    )
};

export const ModalTriggerEditButtonCore = () => (
    <Button>
        <i className="fas fa-edit mr-2"/>{localization.actionModify}
    </Button>
)

export const ActionButtonEdit = <ActionButtonEditCore/>;
export const ActionButtonDelete = <ActionButtonDeleteCore/>;
export const ActionButtonCopy = <ActionButtonCopyCore/>;
export const ActionButtonPictureUpload = <ActionButtonPictureUploadCore/>;
export const ModalTriggerEditButton = <ModalTriggerEditButtonCore/>;