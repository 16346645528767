import React from "react";
import {InputField} from "../FormInputs/FormInputs";
import localization from "../../utils/localization";

const FilterInputTitle = (props) => {
    const titleSearchHandler = (event) => {
        props.onChange(event.target.value);
    };

    return (
        <InputField formControl={props.formControl} name="title"
                    label={localization.filterEventTitle} placeholder={localization.filterHintEventTitle}
                    onChange={titleSearchHandler} value={props.value}/>
    )
};

export default FilterInputTitle;