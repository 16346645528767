import React from "react";
import localization from "../../utils/localization";
import {Container, Card} from "react-bootstrap";
import {CONFIG} from "../../config";

const ClubInstructions = () => {

    const pageTitle = localization.getString(CONFIG.profile.title);

    const hungarian = (
        <>
            <h2 className="text-center">Gyorstalpaló a {pageTitle} adminisztrálásához</h2>

            <div className="mt-3">
                Üdvözlünk a <b>{pageTitle}</b> leendő adminisztrátoraként! Az alábbi pár lépést végrehajtva máris létrehozhatsz új tangó klubot, kurzust és workshopot.
            </div>

            <ol className="mt-3">
                <li>Regisztrálj az oldalon a <b>Bejelentkezés</b> menüpont alatt, legalul kattints a <b>Regisztráció</b> linkre.</li><br/>
                <li>
                    Írj <b>Szalai Tamásnak</b>, hogy a felhasználód adminisztrátor jogosultságot kapjon.
                    Keressétek nyugodtan <a href="https://www.facebook.com/tamas.szalai" target="_blank" rel="noopener noreferrer">Facebookon</a>, az a legegyszerűbb.
                </li><br/>
                <li>
                    <div className="mb-2">Adminisztrátor jogosultsággal a felhasználói menüben (a profilképre kattintva) az alábbi menüpontok láthatóak:</div>
                    <ul>
                        <li><b><i className="far fa-list-alt"/> {localization.menuMyEvents}:</b> a tangó klubjaidhoz kapcsolva tudsz új eseményeket itt meghirdetni (Milonga, Kurzus, Tábor, Fesztivál stb.)
                            <div style={{fontSize: "14px"}}>
                                <div><u>Fontos:</u> klub regisztráció nélkül nem lehet eseményt hirdetni!</div>
                                <div><u>Megjegyzés:</u> az események többsége jelenleg a milonga.hu oldalról származik.</div>
                            </div>
                        </li><br/>
                        <li><b><i className="far fa-address-card"/> {localization.menuMyClubs}:</b> saját tangó klubjaidat tudod ebben a menüpontban adminisztrálni, illetve újat létrehozni.
                            <div style={{fontSize: "14px"}}>
                                <u>Megjegyzés:</u>  Hogyha egy klubot többen szeretnétek adminisztrálni, akkor írjátok meg <b>Szalai Tamásnak</b> a felhasználó neveket, hogy a klubotokhoz kiket szeretnétek hozzáadni adminisztrátorként!
                            </div>
                        </li><br/>
                        <li><b><i className="fas fa-chalkboard-teacher"/> {localization.menuTeacherAdmin}:</b> a tangó oktatók adatait lehet itt kezelni.</li>
                    </ul>
                </li>
            </ol>
        </>
    );

    const english = (
        <>
            <h2 className="text-center">{pageTitle} Administration Quickstart Guide </h2>

            <div className="mt-3">
                Welcome to <b>{pageTitle} </b> page as a prospective administrator! Follow these few steps below and you will be able to create a new tango club, classes or workshops!
            </div>

            <ol className="mt-3">
                <li>Register a user account in the <b>Sign In</b> menu and click on the  <b>Sign Up</b> url at the bottom.</li><br/>
                <li>
                    Write to <b>Tamás Szalai</b>, who will give you administrator privileges.
                    The best way if you contact him on <a href="https://www.facebook.com/tamas.szalai" target="_blank" rel="noopener noreferrer">Facebook</a>.
                </li><br/>
                <li>
                    <div className="mb-2">As an administrator in the user menu (clicking on your profile picture at top) you can access the following contents:</div>
                    <ul>
                        <li><b><i className="far fa-list-alt"/> {localization.menuMyEvents}:</b> manage tango events (Milonga, Class, Camp, Festival, etc.).
                            <div style={{fontSize: "14px"}}>
                                <div><u>Important:</u> Creating new class or workshop is not possible without a registered tango club!</div>
                                <div><u>Notice:</u> Currently most of the events were imported from the milonga.hu page.</div>
                            </div>
                        </li><br/>
                        <li><b><i className="far fa-address-card"/> {localization.menuMyClubs}:</b> manage your tango clubs under this page or create a new one.<br/>
                            <div style={{fontSize: "14px"}}>
                                <u>Notice:</u> In case multiple users would like to manage the same club, please send the name list to <b>Tamás Szalai</b>, who will assign these users to your club as administrators!
                            </div>
                        </li><br/>
                        <li><b><i className="fas fa-chalkboard-teacher"/> {localization.menuTeacherAdmin}:</b> modify the information of tango instructors.<p/></li>
                    </ul>
                </li>
            </ol>
        </>
    );

    const content = localization.getLanguage() === 'hu' ? hungarian : english;

    return (
        <Container className="space-top">
            <Card className="card-shadow p-3">
                {content}
            </Card>
        </Container>
    );
};

export default ClubInstructions;