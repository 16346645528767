import React, {useEffect, useState} from 'react';
import MapBox from "../../../component/MapBox/MapBox";
import {Card, Col, Container, Row} from "react-bootstrap";
import InfoBoxLayout from "../../../layout/InfoBoxLayout/InfoBoxLayout";
import HeadLayout from "../../../layout/HeadLayout/HeadLayout";
import ClubDetailsContacts from "./ClubDetailsContacts";
import ClubDetailsImageGallery from "./ClubDetailsImageGallery";
import {API_CLUBS, API_EVENTS, LONG_DESCRIPTION, S3_IMAGES, SHORT_DESCRIPTION, Url} from "../../../constants";
import {getEventUrl, getLocalizedDescription, isEmptyObject, mapClubDescriptions} from "../../../utils/helperFunctions";
import localization from "../../../utils/localization";
import LoadingIndicator from "../../../component/LoadingIndicator/LoadingIndicator";
import BackButton from "../../../component/BackButton/BackButton";
import {simpleQuery} from "../../../utils/connection";
import {useParams} from "react-router-dom";
import DetailsButton from "../../../component/DetailsButton/DetailsButton";
import {FullDateFormatter} from "../../../component/DateTimeFormatter/DateTimeFormatter";
import EventCategoryBadge from "../../../component/EventCategoryBadge/EventCategoryBadge";
import linkifyHtml from "linkify-html";

const ClubDetails = () => {
    const [data, setData] = useState({});
    const [events, setEvents] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        simpleQuery(API_EVENTS + "club/" + id, setEvents);
        simpleQuery(API_CLUBS + id, setData);
    }, [id])

    const loading = isEmptyObject(data);
    const description = mapClubDescriptions(data.descriptions);
    const longDescription = getLocalizedDescription(description, LONG_DESCRIPTION);
    const modifiedDescription = linkifyHtml(longDescription);

    let upcomingEvents = <div className={"text-center"}>{localization.noEvent}</div>
    if (events.length > 0) {
        upcomingEvents = events.map((event, index) => {
            const eventUrl = getEventUrl(event);

            return (
                <div className={"p-2"} key={"event_" + index}>
                    <Row className="">
                        <Col md={2}>
                            <EventCategoryBadge event={event}/>
                        </Col>

                        <Col md={3} className="mt-1 mt-md-0">
                            <FullDateFormatter date={event.startDateTime}/>
                        </Col>

                        <Col className="mt-1 mt-md-0">
                            {event.title}
                        </Col>

                        <Col md={2} className="mt-2 mt-md-0">
                            <div className={"float-right"}>
                                <DetailsButton url={eventUrl} small/>
                            </div>
                        </Col>
                    </Row>

                    <div className="separator-line mt-1"/>
                </div>
            )
        })
    }

    if (loading)
        return (
            <Card style={{height: "400px"}}>
                <LoadingIndicator />
            </Card>
        )
    else
        return (
            <div>
                <div style={{paddingTop: "70px"}} />
                <HeadLayout className="container text-white text-center club-details-space-top" pic={S3_IMAGES + data.pictureCover} bgPositionX="center" bgPositionY="top">
                    <h1>{data.name}</h1>
                    <h5 className={"p-2 mt-3"}>
                        {getLocalizedDescription(description, SHORT_DESCRIPTION)}
                    </h5>
                </HeadLayout>

                <Container className="mt-2">
                    <BackButton backUrl={Url.TEACHERS}/>
                    <Row className={"mt-2"}>
                        <Col md={6}>
                            <div className="card p-2">
                                <div dangerouslySetInnerHTML={{ __html: modifiedDescription }} />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="mt-3 mt-md-0"/>

                            <ClubDetailsContacts data={data} />

                            <div className="mt-3"/>
                            <MapBox location={data.address} title={data.name}/>
                        </Col>
                    </Row>

                    <div className={"mt-3"} />

                    {data.pictureGallery ? (
                        <InfoBoxLayout title={localization.gallery}>
                            <ClubDetailsImageGallery pictures={data.pictureGallery} name={data.name}/>
                        </InfoBoxLayout>
                    ) : null}

                    {data.teacher ? null : (
                        <InfoBoxLayout title={localization.sectionTitleUpcomingEvents}>
                            <div className={"container p-2"}>
                                {upcomingEvents}
                            </div>
                        </InfoBoxLayout>
                    )}

                </Container>
            </div>
        );
};

export default ClubDetails;