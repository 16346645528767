import React from "react";
import '../../index.css';
import './HeadLayout.css';
import Copyright from "../../component/Copyright/Copyright";
import {isMobileOnly} from "react-device-detect";
import UnderDevelopmentWarning from "../../component/UnderDevelopmentWarning";

const HeadLayout = (props) => {
    const mainClass = props.className ? props.className : "";
    const bgPositionX = props.bgPositionX ? props.bgPositionX : "left";
    const bgPositionY = props.bgPositionY ? props.bgPositionY : "top";

    const picture = props.pic.includes("https://")
        ? props.pic
        : `/pictures/header/${props.pic}`;

    const backgroundStyle = {
        background: `url("${picture}") no-repeat ${bgPositionX} ${bgPositionY}`,
        backgroundSize: "cover",
    };

    function isBoxVisible() {
        return typeof props.children !== 'undefined';
    }

    let classes = "head-box-background ";
    if (isBoxVisible()) {
        if (isMobileOnly)
            classes += "p-1 mb-2";
        else
            classes += "border border-secondary rounded p-3";
    }

    return (
        <div className={mainClass}>
            <div style={backgroundStyle}>
                <div className="dark-overlay">
                    <div className="container space-top space-bottom">
                        <div className="d-flex justify-content-center">
                            <div className={classes}>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-white">
                    <Copyright text={props.copyright}/>
                </div>
            </div>

            <UnderDevelopmentWarning/>
        </div>
    );
};

export default HeadLayout;