import React from 'react';
import InfoBoxLayout from "../../../layout/InfoBoxLayout/InfoBoxLayout";
import Obfuscate from "react-obfuscate";

const ClubDetailsContacts = ({data}) => {
    return (
        <InfoBoxLayout title={"Elérhetőségek"}>
            <div className="p-2">
                <ul className="nobull">
                    {data.email ? (
                        <li>
                            <i className={"far fa-envelope mr-2"}/>
                            <Obfuscate email={data.email} target="_blank"
                                       rel="noopener noreferrer">{data.email}</Obfuscate>
                        </li>
                    ) : null}

                    {data.facebook ? (
                        <li>
                            <i className={"fab fa-facebook-f mr-2 ml-1"}/>
                            <a href={data.facebook} target="_blank" rel="noopener noreferrer">
                                {data.facebook}
                            </a>
                        </li>
                    ) : null}

                    {data.webPage ? (
                        <li>
                            <i className={"fas fa-globe mr-2"}/>
                            <a href={data.webPage} target="_blank" rel="noopener noreferrer">
                                {data.webPage}
                            </a>
                        </li>
                    ) : null}

                    {data.address ? (
                        <li>
                            <i className="fas fa-map-signs mr-2"/>
                            <a href={"https://maps.google.com/?q=" + data.address} target="_blank"
                               rel="noopener noreferrer">
                                <span>{data.address}</span>
                            </a>
                        </li>
                    ) : null}

                    {data.phone ? (
                        <li>
                            <i className={"fas fa-phone mr-2"}/>
                            <Obfuscate tel={data.phone} target="_blank"
                                       rel="noopener noreferrer">{data.phone}</Obfuscate>
                        </li>
                    ) : null}
                </ul>
            </div>
        </InfoBoxLayout>
    );
};

export default ClubDetailsContacts;