import React from "react";
import {Route, Routes} from "react-router-dom";
import EventsDetails from "../page/details/EventDetails/EventDetails";
import Events from "../page/events/Events";
import Home from "../page/home/Home";
import TangoClasses from "../page/classes/TangoClasses";
import Teachers from "../page/teachers/Teachers";
import LoginDialog from "../page/user/login/LoginDialog";
import OAuth2RedirectHandler from "../page/user/oauth2/OAuth2RedirectHandler";
import PrivateRoute from "../page/user/PrivateRoute";
import Profile from "../page/user/profile/Profile";
import MyClubs from "../page/admin/MyClubs/MyClubs";
import TeacherAdmin from "../page/admin/TeacherAdmin/TeacherAdmin";
import AdminInstructions from "../page/help/AdminInstructions";
import TangoEventsAdmin from "../page/admin/EventAdmin/TangoEventsAdmin";
import ClubDetails from "../page/details/ClubDetails/ClubDetails";
import EditClubPage from "../page/admin/MyClubs/EditClubPage";
import EditEventPage from "../page/admin/EventAdmin/EditEventPage";
import {API_EVENTS, Url} from "../constants";
import ArgentineTangoRadioPage from "../page/extras/argentineTangoRadio/ArgentineTangoRadioPage";
import DancePartnerSeekerPage from "../page/extras/dancePartnerSeeker/DancePartnerSeekerPage";
import PageNotFound404 from "../page/PageNotFound404";
import EditDancePartnerAdminPage from "../page/user/profile/dancePartnerSeeker/EditDancePartnerAdminPage";
import DancePartnerCardDetailsPage from "../page/extras/dancePartnerSeeker/DancePartnerCardDetailsPage";
import {UserDataDeletion} from "../page/help/UserDataDeletion";
import {CONFIG} from "../config";
import EventProcessorLogs from "../page/admin/EventProcessorLogs/EventProcessorLogs";
import {LocalizedRedirect} from "../hook/useNavigation";
import Translation from "./Translation";

const AppRoutes = () => {

    const path = (url, params = "") => {
        return "/:loc" + url + params;
    }

    return (
        <Routes>
            <Route path="/" element={<LocalizedRedirect to={Url.HOME}/>} exact/>
            <Route path={"/oauth2/redirect"} element={<OAuth2RedirectHandler/>}/>
            <Route path="/:loc" element={<Translation/>}>
                <Route path={path(Url.EVENTS, "/:year/:month/:id")} exact element={<EventsDetails/>}/>
                <Route path={path(Url.EVENTS)} element={<Events/>}/>
                <Route path={path(Url.HOME)} element={<Home/>}/>
                <Route path={path(Url.CLASSES)} element={<TangoClasses/>}/>
                <Route path={path(Url.TEACHERS, "/:id")} element={<ClubDetails/>}/>
                <Route path={path(Url.TEACHERS)} element={<Teachers/>}/>
                <Route path={path(Url.LOGIN)} element={<LoginDialog login/>}/>
                <Route path={path(Url.SIGNUP)} element={<LoginDialog signup/>}/>
                <Route path={path(Url.FORGOT_PASSWORD)} element={<LoginDialog forgotPassword/>}/>
                <Route path={path(Url.RESET_PASSWORD)} element={<LoginDialog resetPassword/>}/>
                <Route path={path(Url.RADIO)} element={<ArgentineTangoRadioPage/>}/>/>
                <Route path={path(Url.INSTRUCTIONS)} element={<AdminInstructions/>}/>
                <Route path={path(Url.USER_DATA_DELETION)} element={<UserDataDeletion/>}/>

                <Route element={<PrivateRoute/>}>
                    <Route path={path(Url.PROFILE)} element={<Profile/>}/>
                    <Route path={path(Url.MY_CLUBS)} element={<MyClubs/>} exact/>
                    <Route path={path(Url.MY_CLUBS, "/:id")} element={<EditClubPage/>} exact/>
                    <Route path={path(Url.EVENTS_ADMIN, "/:id")}
                           element={<EditEventPage redirectUrl={Url.EVENTS_ADMIN} apiUrl={API_EVENTS} withoutClub/>}/>
                    <Route path={path(Url.EVENTS_ADMIN)} element={<TangoEventsAdmin/>}/>
                    <Route path={path(Url.TEACHER_ADMIN, "/:id")} element={<EditClubPage teacher/>} exact/>
                    <Route path={path(Url.TEACHER_ADMIN)} element={<TeacherAdmin/>}/>
                    <Route path={path(Url.FACEBOOK_EVENT_ERRORS)} element={<EventProcessorLogs/>}/>
                </Route>


                {CONFIG.profile.dancePartnerSeekerEnabled && [
                    <Route key="find-a-partner/id" path={path(Url.DANCE_PARTNER_SEEKER, "/:id")}
                           element={<DancePartnerCardDetailsPage/>}/>,
                    <Route key="find-a-partner" path={path(Url.DANCE_PARTNER_SEEKER)}
                           element={<DancePartnerSeekerPage/>}/>,
                    <Route key="find-a-partner/edit" element={<PrivateRoute/>}>
                        <Route path={path(Url.DANCE_PARTNER_SEEKER_ADMIN)} element={<EditDancePartnerAdminPage/>}/>
                    </Route>
                ]}

                <Route element={PageNotFound404}/>
            </Route>
        </Routes>
    );
}

export default AppRoutes;