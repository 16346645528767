import {useParams} from "react-router-dom";
import Alert from "react-s-alert";
import {PATH_NEW} from "../constants";
import {handleError, postAuthenticated, putAuthenticated, simpleAuthenticatedQuery} from "../utils/connection";
import localization from "../utils/localization";
import {useCallback, useState} from "react";
import {useNavigation} from "./useNavigation";

function useCreateOrModify() {
    const navigation = useNavigation();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const isModify = id !== PATH_NEW;

    const createOrModify = async (createUrl, modifyUrl, data, redirect) => {
        try {
            setLoading(true);
            if (isModify)
                await putAuthenticated(modifyUrl, data);
            else
                await postAuthenticated(createUrl, data);

            navigation.pushHistory(redirect);
            Alert.success(localization.modifySuccess);
        } catch (error) {
            handleError(error);
        }

        setLoading(false);
    }

    const copy = async (createUrl, data, redirect) => {
        try {
            setLoading(true);
            data.id = null;
            data.recurringId = null;

            await postAuthenticated(createUrl, data);

            navigation.pushHistory(redirect);
            Alert.success(localization.modifySuccess);
        } catch (error) {
            handleError(error);
        }

        setLoading(false);
    }

    const fetchData = useCallback( (apiUrl, setData) => {
        setLoading(true);
        return simpleAuthenticatedQuery(apiUrl + id, (data) => {
            setData(data)
            setLoading(false);
        }, () => setLoading(false));
    }, [id])

    return {createOrModify, copy, fetchData, loading}
}

export default useCreateOrModify;