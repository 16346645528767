import React from 'react';
import './LoginDialog.css';
import {useLocation} from 'react-router-dom'
import Alert from 'react-s-alert';
import {Url} from "../../../constants";
import {useSelector} from "react-redux";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import {Card, Col, Container, Row} from "react-bootstrap";
import localizeErrorMessage from "../../../utils/errorCodeResolver";
import {LocalizedRedirect} from "../../../hook/useNavigation";

const LoginDialog = (props) => {
    const location = useLocation();
    const authenticated = useSelector(state => state.userReducer.authenticated);

    const renderContent = () => {
        if (props.login)
            return <LoginForm/>;

        if (props.signup)
            return <SignupForm/>;

        if (props.forgotPassword)
            return <ForgotPasswordForm/>;

        if (props.resetPassword)
            return <ResetPasswordForm/>;

        return <h1>PLEASE GIVE A VALID PARAMETER!</h1>;
    };

    if (location.state && location.state.error) {
        setTimeout(() => {
            const msg = localizeErrorMessage({
                message: location.state.error
            });

            Alert.error(msg, {timeout: 5000});
        }, 100);
    }

    if (authenticated) {
        return <LocalizedRedirect to={Url.HOME}/>;
    } else {
        return (
            <Container className="space-top">
                <Row>
                    <Col md={3} xl={4}/>

                    <Col md={6} xl={4}>
                        <Card className="p-3 dialog-shadow">
                            {renderContent()}
                        </Card>
                    </Col>

                    <Col md={3} xl={4}/>
                </Row>
            </Container>
        );
    }
}

export default LoginDialog;