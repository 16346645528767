import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import localization from "../../../utils/localization";
import {ActionButtonEditCore} from "../../../component/ActionButtons/ActionButtons";
import Button from "react-bootstrap/Button";
import {simpleAuthenticatedQuery} from "../../../utils/connection";
import {API_MY_CLUBS, PATH_NEW, Url} from "../../../constants";
import {CUSTOM_TABLE_STYLES} from "../../../utils/tableUtils";
import {useNavigation} from "../../../hook/useNavigation";

const ClubTable = () => {
    const [data, setData] = useState([]);
    const navigation = useNavigation();

    useEffect(() => {
        const controller = simpleAuthenticatedQuery(API_MY_CLUBS, setData);
        return () => controller.abort()
    }, []);

    const onCreateClick = (id) => {
        navigation.pushHistory(Url.MY_CLUBS + "/" + id);
    }

    const createNewButton = (
        <Button onClick={() => onCreateClick(PATH_NEW)}>
            <i className="fas fa-plus mr-2"/>{localization.actionCreateClub}
        </Button>
    );

    const columns = [
        {
            cell: (row) => <ActionButtonEditCore onClick={() => onCreateClick(row.id)} />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: localization.formName,
            selector: 'name',
        },
        {
            name: localization.formEmail,
            selector: 'email',
        },
        {
            name: "Facebook",
            selector: 'facebook',
        },
        {
            name: localization.phone,
            selector: 'phone',
        },
        {
            name: localization.webPage,
            selector: 'webPage',
        },
    ];

    return (
        <DataTable
            title={localization.menuMyClubs}
            data={data}
            columns={columns}
            customStyles={CUSTOM_TABLE_STYLES}
            subHeader
            subHeaderComponent={createNewButton}
            noDataComponent={localization.warningClubNotFound}
        />
    );
};

export default ClubTable;