import React from 'react';
import localization from "../../../utils/localization";
import ArgentineTangoRadio from "./ArgentineTangoRadio";

const ArgentineTangoRadioPage = () => (
    <div className="space-top">
        <section id="argentine-tango-radio" className="container">
            <h1 className="text-center mt-5 mb-3">{localization.argentineTangoRadio}</h1>
            <div className="row">
                <div className="col-2"/>
                <div className="col-8">
                    <ArgentineTangoRadio />
                </div>
                <div className="col-2"/>
            </div>
        </section>
    </div>
);

export default ArgentineTangoRadioPage;