import React from 'react';
import LoadingIndicator from "../../component/LoadingIndicator/LoadingIndicator";

const DialogLayout = props => (
    <div className={"p-3 mb-3 border rounded bg-white"}>
        <h5 className="font-weight-bold" >{props.title}</h5>
        <div className="separator-line mb-3" />

        {props.loading ? (
            <div style={{height: "150px"}}>
                <div className="mt-5">
                    <LoadingIndicator centerHorizontally/>
                </div>
            </div>
        ) : props.children}
    </div>
);

export default DialogLayout;