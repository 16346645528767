import React from "react";
import localization from "../../utils/localization";

const FilterResetButton = (props) => (
    <input type="reset"
           className="btn btn-primary mb-2"
           onClick={props.onClick}
           value={localization.filterButtonReset}/>
);

export default FilterResetButton;