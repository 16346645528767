import React from "react";
import './EventInfoBox.css';
import InfoBoxLayout from "../../layout/InfoBoxLayout/InfoBoxLayout";
import {FullDateFormatter, TimeFormatter} from "../DateTimeFormatter/DateTimeFormatter";
import localization from "../../utils/localization";

const eventInfoBox = (props) => {

    let date = (<FullDateFormatter date={props.startDate}/>);
    let time = (
        <div>
            <i className="far fa-clock mr-1"/>
            <span>
                <TimeFormatter date={props.startDate}/> - <TimeFormatter date={props.endDate}/>
            </span>
        </div>);

    if (props.wholeDayEvent) {
        date = (<span><FullDateFormatter date={props.startDate}/> - <FullDateFormatter date={props.endDate}/></span>);
        time = ""
    }

    return (
        <InfoBoxLayout title={localization.eventInfo}>
            <ul className="nobull p-3">
                <li>
                    <i className="fas fa-calendar-alt mr-1"/>{date}
                </li>
                <li>
                    {time}
                </li>

                <li>
                    <i className="fas fa-map-marker-alt mr-1"/>
                    <a href={"https://maps.google.com/?q=" + props.address} target="_blank" rel="noopener noreferrer">
                        <span>{props.address}</span>
                    </a>
                </li>

                {props.externalUrl ? (
                    <li>
                        <i className="fab fa-facebook mr-1"></i>
                        <a href={props.externalUrl} target="_blank" rel="noopener noreferrer">
                            <span>{props.externalUrl}</span>
                        </a>
                    </li>
                ) : null}

            </ul>
        </InfoBoxLayout>
    )
};

export default eventInfoBox;