import React, {Fragment, useEffect, useState} from 'react';
import localization from "../../utils/localization";
import './FormInputs.css';

import DatePicker, {registerLocale} from "react-datepicker";
import hu from "date-fns/locale/hu";
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/hu.js';

registerLocale('hu', hu);
registerLocale('en', en);

export const VARCHAR_MAX_LENGTH = 255;

export const CustomForm = (props) => (
    <form onSubmit={props.formControl.handleSubmit(props.onSubmit)}>
        {props.children}
    </form>
);

export const HiddenField = (props) => (
    <input type="hidden"
           name={props.name}
           defaultValue={props.defaultValue}
           value={props.value}
           ref={props.formControl.register({})} />
)



export const InputField = (props) => {
    const onChange = (event) => {
        props.onChange(event);
    };

    return (
        <div className="form-group">
            <label htmlFor={props.name} className="font-weight-bold" >{props.label}</label>
            {errorMsgRequired(props.name, props.formControl.errors)}
            <input type="text" className="form-control"
                   id={props.name}
                   name={props.name}
                   defaultValue={props.defaultValue}
                   ref={props.formControl.register({required: props.required})}
                   onChange={onChange}
                   maxLength={props.maxLength}
                   disabled={props.disabled}
                   placeholder={props.placeholder}/>
        </div>
    )
};

export const NumberField = (props) => {
    const [value, setValue] = useState("")

    useEffect(() => {
        setValue(props.defaultValue);
    }, [props.defaultValue]);

    const onChange = (event) => {
        const currentValue = event.target.value;
        if (Number.isInteger(+currentValue))
            setValue(+currentValue);

        if (currentValue.length === 0)
            setValue("")
    };

    return (
        <div className="form-group">
            <label htmlFor={props.name} className="font-weight-bold" >{props.label}</label>
            {errorMsgRequired(props.name, props.formControl.errors)}
            <input type="text" className="form-control"
                   id={props.name}
                   name={props.name}
                   ref={props.formControl.register({required: props.required})}
                   onChange={onChange}
                   value={value}
                   maxLength={props.maxLength}
                   placeholder={props.placeholder}/>
        </div>
    )
};

NumberField.defaultProps = {
    defaultValue: ""
}

export const TextAreaField = (props) => {
    return (
        <div className="form-group">
            <label htmlFor={props.name} className="font-weight-bold" >{props.label}</label>
            {errorMsgRequired(props.name, props.formControl.errors)}
            <div className="form-group">
                <textarea className="form-control"
                          id={props.name}
                          name={props.name}
                          defaultValue={props.defaultValue}
                          rows={props.rows}
                          maxLength={props.maxLength}
                          placeholder={props.placeholder}
                          ref={props.formControl.register({required: props.required})}/>
            </div>
        </div>
    )
};

export const EmailField = (props) => (
    <div className="form-group">
        <label htmlFor={props.name} className="font-weight-bold" >{localization.formEmail}</label>
        {errorMsgEmail(props.name, props.formControl.errors)}
        <input id={props.name}
               name={props.name}
               defaultValue={props.defaultValue}
               className="form-control"
               placeholder={localization.formEmailPlaceholder}
               maxLength={VARCHAR_MAX_LENGTH}
               ref={props.formControl.register({
                   required: props.required,
                   validate: value => validateEmail(value)
               })}/>
    </div>
);

export const PasswordField = (props) => {
    const label = props.label ? props.label : localization.formPassword;

    return (
        <div className="form-group">
            <label htmlFor={props.name} className="font-weight-bold" >{label}</label>
            {errorMsgRequired(props.name, props.formControl.errors)}
            <input type="password" className="form-control"
                   name={props.name}
                   id={props.name}
                   maxLength={VARCHAR_MAX_LENGTH}
                   ref={props.formControl.register({required: true})}/>
        </div>
    )
};

export const SelectItem = props => {
    const [currentValue, setCurrentValue] = useState();

    useEffect(() => {
        setCurrentValue(props.defaultValue);
    }, [props.defaultValue]);

    const optionList = props.options.map(option => (
        <option key={props.name + "_" + option.value} value={option.value}>{option.text}</option>
    ));

    const onChange = (event) => {
        setCurrentValue(event.target.value);
        props.onChange(event);
    };

    return (
        <div className="form-group">
            <label className="font-weight-bold" hidden={props.hidden}>{props.label}</label>
            <select name={props.name} ref={props.formControl.register} className="form-control" value={currentValue} onChange={onChange} hidden={props.hidden}>
                {optionList}
            </select>
        </div>
    )
};

export const DateTimePicker = props => {
    const [currentDate, setCurrentDate] = useState(new Date(props.defaultValue));

    useEffect(() => {
        props.formControl.register({ name: props.name });
        props.formControl.setValue(props.name, currentDate);
    }, [props.formControl, props.name, currentDate]);

    const onDateChange = date => {
        setCurrentDate(date);
        props.formControl.setValue(props.name, date);
    };

    return (
        <div className="form-group">
            <label className="font-weight-bold" >{props.label}</label>
            {errorMsgRequired(props.name, props.formControl.errors)}
            <div>
                <DatePicker
                    selected={currentDate}
                    onChange={date => onDateChange(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption={localization.formTime}
                    locale={localization.getLanguage()}
                    dateFormat="yyyy. MMMM d. HH:mm"/>
            </div>
        </div>
    )
};


export const DatePickerField = props => {
    const [currentDate, setCurrentDate] = useState(new Date(props.defaultValue));

    useEffect(() => {
        props.formControl.register({ name: props.name });
        props.formControl.setValue(props.name, currentDate);
    }, [props.formControl, props.name, currentDate]);

    const onDateChange = date => {
        setCurrentDate(date);
        props.formControl.setValue(props.name, date);
    };

    return (
        <div className="form-group">
            <label className="font-weight-bold" >{props.label}</label>
            {errorMsgRequired(props.name, props.formControl.errors)}
            <div>
                <DatePicker
                    selected={currentDate}
                    onChange={date => onDateChange(date)}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    showWeekNumbers
                    locale={localization.getLanguage()}
                    dateFormat="yyyy. MMMM d."/>
            </div>
        </div>
    )
};

export const CheckBox = props => {
    const [isChecked, setChecked] = useState(props.defaultValue);

    useEffect(() => {
        if (props.formControl) {
            props.formControl.register({ name: props.name });
            props.formControl.setValue(props.name, isChecked);
        }
    }, [props.formControl, props.name, isChecked]);

    const onChange = (event) => {
        setChecked(event.target.checked);
        props.onChange(event.target.checked);
    }

    return (
        <div className="form-check">
            <input type="checkbox" className="form-check-input"
                   id={props.name}
                   disabled={props.disabled}
                   onChange={onChange}
                   checked={isChecked}/>
            <label className="form-check-label" htmlFor={props.name}>{props.label}</label>
        </div>
    )
};

const RadioButton = props => {
    return (
        <div className="form-check">
            <input className="form-check-input" type="radio"
                   name={props.name}
                   id={props.value}
                   value={props.value}
                   onClick={props.onClick}
                   defaultChecked={props.checked}/>
            <label className="form-check-label" htmlFor={props.value}>{props.label}</label>
        </div>
    )
}

export const RadioButtonGroup = props => {
    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        if (props.formControl) {
            props.formControl.register({ name: props.name });
            props.formControl.setValue(props.name, value);
        }
    }, [props.formControl, props.name, value]);


    const onClick = (event) => {
        setValue(event.target.value);
    }

    const optionList = props.options.map(option => {
        const checked = option.value === value;

        return (
            <RadioButton key={option.value}
                         id={option.value}
                         name={props.name}
                         label={option.label}
                         value={option.value}
                         onClick={onClick}
                         checked={checked}/>
        )});

    return (
        <>{optionList}</>
    )
}

export const SubmitButton = (props) => (
    <div>
        <button type="submit" className="btn btn-block btn-primary">{props.caption}</button>
    </div>
);

export const PrintGeneralErrorMsg = (props) => (
    props.error ? <FormErrorMessage msg={props.text} /> : <div/>
);

export const CKEditorField = (props) => {
    const [value, setValue] = useState(props.defaultValue);

    useEffect(() => {
        props.formControl.register({ name: props.name });
        props.formControl.setValue(props.name, value);
    }, [props.defaultValue, props.formControl, props.name, value]);

    const onChange = (data) => {
        props.formControl.setValue(props.name, data);
        setValue(data);
    }

    return (
        <div className="form-group">
            <label className="font-weight-bold" >{props.label}</label>
            {errorMsgRequired(props.name, props.formControl.errors)}

            <CKEditor
                editor={ ClassicEditor }
                data={value}
                onChange={ ( event, editor ) => {
                    onChange(editor.getData());
                } }
                config={ {
                    toolbar: {
                        items: ['heading', '|', 'bold', 'italic', 'link', 'numberedList', 'bulletedList', '|', 'indent', 'outdent', '|', 'undo', 'redo' ],
                    },
                    language: localization.getLanguage(),
                } }
            />
        </div>
    )
}

// --- Helper Functions ---

const FormErrorMessage = ({msg}) => (
    <div className="error-msg text-danger text-left">
        {msg}
    </div>
);

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const errorMsgRequired = (field, error) => {
    return error[field] && error[field].type === 'required' && <FormErrorMessage msg={localization.validationRequired} />
};

const errorMsgEmail = (field, error) => (
    <Fragment>
        {errorMsgRequired(field, error)}
        {error[field] && error[field].type === 'validate' && <FormErrorMessage msg={localization.validationEmail} />}
    </Fragment>
);

EmailField.defaultProps = {
    name: 'email'
};

PasswordField.defaultProps = {
    name: 'password'
};

TextAreaField.defaultProps = {
    rows: 2,
    maxLength: 50000
};

DateTimePicker.defaultProps = {
    defaultValue: new Date()
};

DatePickerField.defaultProps = {
    defaultValue: new Date()
};

InputField.defaultProps = {
    onChange: () => {},
    maxLength: VARCHAR_MAX_LENGTH
};

SelectItem.defaultProps = {
    onChange: () => {}
};

CheckBox.defaultProps = {
    defaultValue: false,
    onChange: () => {}
};