import {ACCESS_TOKEN, API_USER, OPERATION_ABORTED} from '../constants';
import Alert from "react-s-alert";
import localizeErrorMessage from "./errorCodeResolver";
import {CONFIG} from "../config";
import {sendGAError} from "./googleAnalytics";

const API_VERSION = '/v2';

const getRequestHeaders = (options) => {
    const headers = new Headers();

    if (!options.skipContentType)
        headers.append('Content-Type', 'application/json');

    if (localStorage.getItem(ACCESS_TOKEN))
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));

    return headers;
}

const request = (options) => {
    const defaults = {headers: getRequestHeaders(options)};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response => {
            // console.log(response)
            return response.json()
                .then(json => {
                    if(!response.ok) {
                        return Promise.reject(json);
                    }

                    return json;
                })
        });
};

export function getCurrentUser() {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("User is not logged in.");
    }

    return request({
        url: CONFIG.apiUrl + API_VERSION + API_USER,
        method: 'GET'
    });
}

export function login(loginRequest) {
    return request({
        url: CONFIG.apiUrl + API_VERSION + "/auth/login",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: CONFIG.apiUrl + API_VERSION + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}

export function forgotPasswordRequest(data) {
    return request({
        url: CONFIG.apiUrl + API_VERSION + "/auth/forgot-password",
        method: 'POST',
        body: JSON.stringify(data)
    });
}

export function resetPasswordRequest(data) {
    return request({
        url: CONFIG.apiUrl + API_VERSION + "/auth/reset-password",
        method: 'POST',
        body: JSON.stringify(data)
    });
}

export function changePasswordRequest(data) {
    return request({
        url: CONFIG.apiUrl + API_VERSION + "/auth/change-password",
        method: 'PUT',
        body: JSON.stringify(data)
    });
}

export function uploadFile(formData) {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("Upload file error: No access token set.");
    }

    return request({
        url: CONFIG.apiUrl + API_VERSION +'/upload-file/',
        method: 'POST',
        body: formData,
        skipContentType: true
    });
}

function sendAuthenticated(method, url, data, controller) {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("Authentication error: No access token set.");
    }

    return send(method, url, data, controller);
}

function send(method, url, data, controller) {
    return request({
        url: CONFIG.apiUrl + API_VERSION + url,
        method: method,
        body: data ? JSON.stringify(data) : null,
        signal: controller ? controller.signal : null
    });
}

function get(url, controller) {
    return send("GET", url, null, controller);
}

export function getAuthenticated(url, controller) {
    return sendAuthenticated("GET", url, null, controller);
}

export function postAuthenticated(url, data, controller) {
    return sendAuthenticated("POST", url, data, controller);
}

export function putAuthenticated(url, data, controller) {
    return sendAuthenticated("PUT", url, data, controller);
}

function deleteAuthenticated(url, controller) {
    return sendAuthenticated("DELETE", url, controller);
}

export function handleError(error) {
    if (error.name !== OPERATION_ABORTED) {
        console.log(error);
        sendGAError("Connection error.");
        Alert.error(localizeErrorMessage(error));
    }
}

export function simpleQuery(url, onFinished, onError) {
    const controller = new AbortController();

    const responsePromise = get(url, controller);
    checkResponsePromise(responsePromise, onFinished, onError);

    return controller;
}

export function simpleAuthenticatedPost(url, data, onFinished, onError) {
    const controller = new AbortController();

    const responsePromise = postAuthenticated(url, data, controller);
    checkResponsePromise(responsePromise, onFinished, onError);

    return controller;
}

export function simpleAuthenticatedQuery(url, onFinished, onError) {
    const controller = new AbortController();

    const responsePromise = getAuthenticated(url, controller);
    checkResponsePromise(responsePromise, onFinished, onError);

    return controller;
}

export function simpleAuthenticatedPut(url, data, onFinished, onError) {
    const controller = new AbortController();

    const responsePromise = putAuthenticated(url, data, controller);
    checkResponsePromise(responsePromise, onFinished, onError);

    return controller;
}

export function simpleAuthenticatedDelete(url, onFinished, onError) {
    const controller = new AbortController();

    const responsePromise = deleteAuthenticated(url, controller);
    checkResponsePromise(responsePromise, onFinished, onError);

    return controller;
}

const checkResponsePromise = (responsePromise, onFinished, onError) => {
    responsePromise
        .then( response => {
            onFinished(response);
        })
        .catch( error => {
            if (onError)
                onError(error);
            else
                handleError(error);
        });
}