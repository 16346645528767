import React from "react";
import localization from "../../utils/localization";
import Calendar from "../../component/Calendar/Calendar";
import CustomDivider from "../../component/UI/UI";
import EventListSection from "./EventListSection";
import './EventPage.css';
import {ScrollUpLink} from "../../component/ScrollLink/ScrollLink";
import {Col, Container, Row} from "react-bootstrap";

const EventPage = (props) => (
    <>
        <ScrollUpLink to="calendar"/>

        <Container>
            <Row>
                <Col lg={2} className="sidebar-outer bg-light border">
                    <div className="sticky">
                        {props.filter}
                    </div>
                </Col>

                <Col lg={10}>
                    <section id="calendar">
                        <h1 className="text-center mt-4 mt-md-5">{localization.sectionTitleCalendar}</h1>
                        <Calendar filteredEvents={props.events}/>
                    </section>
                    <div className="mt-5">
                        <CustomDivider/>
                    </div>
                    <EventListSection events={props.events} />
                </Col>
            </Row>
        </Container>
    </>
);

export default EventPage;
