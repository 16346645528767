import React from "react";
import {forgotPasswordRequest} from "../../../utils/connection";
import localization from "../../../utils/localization";
import Alert from "react-s-alert";
import {Url} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from 'react-hook-form'
import {CustomForm, EmailField, SubmitButton} from "../../../component/FormInputs/FormInputs";
import localizeErrorMessage from "../../../utils/errorCodeResolver";
import {LocalizedLink, useNavigation} from "../../../hook/useNavigation";
import userActions from "../../../store/actions/userActions";
import {getBaseUrl} from "../../../utils/helperFunctions";
import {CONFIG} from "../../../config";

const ForgotPasswordForm = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const formControl = useForm();

    const currentLanguage = useSelector(state => state.uiReducer.currentLanguage);
    const setLoading = (value) => dispatch(userActions.loadingAction(value));

    const onSubmit = async (data) => {
        setLoading(true);

        const request = {
            email: data.email,
            callbackBaseUrl: getBaseUrl(),
            language: currentLanguage,
            subject: localization.mailResetPwSubject,
            htmlBody: localization.mailResetPwBody,
            webPageTitle: localization.getString(CONFIG.profile.title)
        };

        try {
            await forgotPasswordRequest(request);
            setLoading(false);
            Alert.success(localization.loginResetPasswordSent);
            navigation.pushHistory(Url.HOME);
        } catch (error) {
            setLoading(false);
            console.log(error);
            Alert.error(localizeErrorMessage(error));
        }
    };

    return (
        <>
            <h1 className="mb-4 text-center">{localization.loginForgotPassword}</h1>
            <p>{localization.loginForgotPasswordEmail}!</p>
            <CustomForm formControl={formControl} onSubmit={onSubmit}>
                <EmailField required formControl={formControl}/>
                <SubmitButton caption={localization.formSubmit}/>
            </CustomForm>

            <LocalizedLink className="text-center mt-3" to={Url.LOGIN}>
                {localization.buttonBack}
            </LocalizedLink>
        </>
    );
}

export default ForgotPasswordForm;