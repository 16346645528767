export const ACCESS_TOKEN = 'accessToken';
export const OPERATION_ABORTED = 'AbortError';

export const S3_IMAGES = 'https://milonga-finder-data.s3-eu-west-1.amazonaws.com/img/';

export const API_USER = '/user/';
export const API_USER_SETTING = '/user/setting';
export const API_USER_INFO = '/user/info';
export const API_EVENTS = '/events/';
export const API_CLUBS = '/clubs/';
export const API_TEACHERS = '/teachers/';
export const API_MY_TEACHERS = '/teachers/my';
export const API_MY_CLUBS = '/clubs/my';
export const API_MY_EVENTS = '/events/my';
export const API_DANCE_PARTNER = '/dance-partner/';
export const API_EVENT_PROCESSING_LOGS = '/events/processing/errors/';

export const DEFAULT_CLUB_HUNGARY = 1;
export const DEFAULT_CLUB_NAME = "[DEFAULT_CLUB]";

export const QUERY_RECURRING = "?recurring=true";

export const USERNAME_MAX_LENGTH = 35;
export const LIMIT_EVENT_DURATION_HOURS = 24 * 5;
export const PATH_NEW = "new";
export const GOOGLE_MAPS_LINK = "https://maps.google.com/?q=";

export const SHORT_DESCRIPTION = "shortDescription";
export const LONG_DESCRIPTION = "longDescription";

export const IMG_DEFAULT_EVENT = "default_event.jpg";
export const IMG_UNKNOWN = "unknown.jpg";

export const DANCE_PARTNER_CATEGORY_COURSE = "COURSE";
export const DANCE_PARTNER_CATEGORY_PRACTICE = "PRACTICE";
export const DANCE_PARTNER_CATEGORY_WORKSHOP = "WORKSHOP";
export const DANCE_PARTNER_CATEGORY_CAMP = "CAMP";
export const DANCE_PARTNER_CATEGORY_REGISTRATION = "MARATHON";

export const COUNTRY_HUNGARY = 1;
export const COUNTRY_SERBIA = 2;
export const COUNTRY_POLAND = 3;

export const DATASOURCE_FACEBOOK = "FACEBOOK";

export const Url = {
    ROOT: '/',
    HOME: '/home',
    EVENTS: '/events',
    CLASSES: '/classes',
    TEACHERS: '/teachers',
    LOGIN: '/login',
    SIGNUP: '/signup',
    PROFILE: '/profile',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    MY_CLUBS: '/my-clubs',
    TEACHER_ADMIN: '/teacher-admin',
    EVENTS_ADMIN: '/events-admin',
    RADIO: '/argentine-tango-radio',
    DANCE_PARTNER_SEEKER: '/find-a-partner',
    DANCE_PARTNER_SEEKER_ADMIN: '/find-a-partner-admin',
    INSTRUCTIONS: '/admin-instructions',
    USER_DATA_DELETION: '/user-data-deletion',
    FACEBOOK_EVENT_ERRORS: "/facebook-event-errors"
};