import React, {useEffect, useState} from 'react';
import localization from "../../../utils/localization";
import {Col, Container, Row} from "react-bootstrap";
import DancePartnerCard from "../../../component/DancePartnerCard/DancePartnerCard";
import {API_DANCE_PARTNER, Url} from "../../../constants";
import {simpleQuery} from "../../../utils/connection";
import LoadingIndicator from "../../../component/LoadingIndicator/LoadingIndicator";
import {LocalizedLink} from "../../../hook/useNavigation";
import InfoPanel from "../../../component/InfoPanel/InfoPanel";
import {useSelector} from "react-redux";

const DancePartnerSeekerPage = () => {
    const country = useSelector(state => state.uiReducer.country);
    const [cards, setCards] = useState();

    useEffect(() => {
        const controller = simpleQuery(API_DANCE_PARTNER + `all/${country}/`, setCards);
        return () => {controller.abort();}
    }, [country])

    const InfoHu = () => (
        <InfoPanel className="mt-4">
            <h5><i className="fas fa-info-circle mr-2"/>Információ</h5>
            Itt találhatóak azok a vezetők és követők, akik éppen tangó partnert keresnek. Jelenkezz be a fiókodba,
            majd a Cabeceo gomb megnyomása után küldhetsz üzenetet a kiválasztott partnerjelöltednek.

            <h5><i className="fas fa-question-circle mr-2 mt-4"/>Hogyan állíthatom be az adatlapom?</h5>

            <div>
                A <LocalizedLink to={Url.DANCE_PARTNER_SEEKER_ADMIN}>{localization.settingCardDancePartnerSeekerTitle} </LocalizedLink>
                oldalon tudsz feladni új hirdetést, illetve ugyanott tudod szerkeszteni a személyes adataidat, mint szerep, szint, magasság, stb.
            </div>
        </InfoPanel>
    )

    const InfoEn = () => (
        <InfoPanel className="mt-4">
            <h5><i className="fas fa-info-circle mr-2"/>Information</h5>
            Find here the leaders and followers who are looking for a dance partner. Sign in to your account and send a message to your next dance partner by the Cabaceo button.

            <h5><i className="fas fa-question-circle mr-2 mt-4"/>How can I customize my profile?</h5>

            <div>
                Create a new advertisement or customize your personal profile such as (role, skill level, body height, etc.) on the <LocalizedLink to={Url.DANCE_PARTNER_SEEKER_ADMIN}>{localization.settingCardDancePartnerSeekerTitle} </LocalizedLink> page.
            </div>
        </InfoPanel>
    )

    const getDancerCards = () => {
        if (cards) {
            if (cards.length > 0) {
                return cards.map((dancer, index) => {
                    return (
                        <Col lg={6} className="mb-5" style={{marginTop: "100px"}} key={"dancer-" + index}>
                            <DancePartnerCard data={dancer}/>
                        </Col>)
                });
            } else {
                return (
                    <>
                        <Col/>
                        <Col>
                            <div className="text-center my-5" style={{height: "400px"}}>
                                <i className="far fa-frown fa-6x"/>
                                <h4 className="mt-3">{localization.dancePartnerMessageNoOneLookingFor}</h4>
                            </div>
                        </Col>
                        <Col/>
                    </>
                )
            }
        } else {
            return (
                <div style={{height: "200px", marginTop: "100px"}}>
                    <LoadingIndicator centerHorizontally/>
                </div>)
        }
    }

    return (
        <Container style={{paddingTop: "80px"}}>
            <h1 className="text-center mt-5 mb-5">{localization.menuDancePartnerSeeker}</h1>

            {localization.getLanguage() === "hu" ? <InfoHu/> : <InfoEn/>}

            <div className="my-5"/>

            <Row>
                {getDancerCards()}
            </Row>
        </Container>
    );
};

export default DancePartnerSeekerPage;