import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import FilterInputTitle from "../../../component/EventFilterButtons/FilterInputTitle";
import {filterCategory, filterTitle, filterUpcomingEvents} from "../../../component/EventFilterButtons/filtersFunctions";
import {
    FilterInputCategoryClass,
    FilterInputCategoryEvent
} from "../../../component/EventFilterButtons/FilterInputCategory";
import {CheckBox} from "../../../component/FormInputs/FormInputs";
import localization from "../../../utils/localization";

const EventTableFilters = (props) => {
    const formControl = useForm();
    const [searchTitle, setSearchTitle] = useState('');
    const [searchCategory, setSearchCategory] = useState('');
    const [showPastEvents, setShowPastEvents] = useState(false);

    useEffect(() => {
        let filteredEvents = props.data;
        if (!showPastEvents)
            filteredEvents = filterUpcomingEvents(filteredEvents)

        filteredEvents = filterTitle(filteredEvents, searchTitle);
        filteredEvents = filterCategory(filteredEvents, searchCategory);

        props.applyFilter(filteredEvents);
    },[props, searchTitle, searchCategory, showPastEvents]);

    return (
        <div className="mr-5 d-flex">
            <div className="mx-3">
                <label className="font-weight-bold mb-3" >{localization.filterPastEvents}</label>
                <CheckBox formControl={formControl} name="pastEvents" label={localization.filterShow} defaultValue={showPastEvents} onChange={setShowPastEvents}/>
            </div>
            <FilterInputTitle formControl={formControl} onChange={setSearchTitle} value={searchTitle}/>
            <span className="mx-2"/>
            {props.classFilter ? (
                <FilterInputCategoryClass formControl={formControl} value={searchCategory} onChange={setSearchCategory}/>
            ) : (
                <FilterInputCategoryEvent formControl={formControl} value={searchCategory} onChange={setSearchCategory} />
            )}
        </div>
    )
}

export default React.memo(EventTableFilters);