import React, {useState, useEffect, useCallback} from 'react';
import DataTable from 'react-data-table-component';
import localization from "../../../utils/localization";
import {ActionButtonDelete, ActionButtonEditCore} from "../../../component/ActionButtons/ActionButtons";
import Button from "react-bootstrap/Button";
import {handleError, simpleAuthenticatedDelete, simpleAuthenticatedQuery} from "../../../utils/connection";
import {DeleteConfirmModal} from "../../../component/FormModal/ConfirmModal";
import {Url, API_TEACHERS, API_MY_TEACHERS, PATH_NEW} from "../../../constants";
import {CUSTOM_TABLE_STYLES} from "../../../utils/tableUtils";
import {useNavigation} from "../../../hook/useNavigation";

const TeacherTable = () => {
    const navigation = useNavigation();
    const [data, setData] = useState([]);

    const fetchData = useCallback(() => {
        return simpleAuthenticatedQuery(API_MY_TEACHERS, setData);
    }, [])

    useEffect(() => {
        const controller = fetchData();
        return () => controller.abort()
    }, [fetchData]);

    const onCreateClick = (id) => {
        navigation.pushHistory(Url.TEACHER_ADMIN + "/" + id)
    }

    const createNewButton = (
        <Button onClick={() => onCreateClick(PATH_NEW)}>
            <i className="fas fa-plus mr-2"/>{localization.actionAddTeacher}
        </Button>
    );

    const deleteRow = id => {
        simpleAuthenticatedDelete(API_TEACHERS + id, fetchData, (error) => {
            handleError(error);
            fetchData();
        });
    };

    const actionButtonBar = row => (
        <>
            <ActionButtonEditCore onClick={() => onCreateClick(row.id)} />
            <span className="mr-2" />
            <DeleteConfirmModal title={row.name} trigger={ActionButtonDelete} onAction={() => deleteRow(row.id)}/>
        </>
    );

    const columns = [
        {
            cell: (row) => actionButtonBar(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: localization.formName,
            selector: 'name',
        },
        {
            name: localization.formEmail,
            selector: 'email',
        },
        {
            name: "Facebook",
            selector: 'facebook',
        },
        {
            name: localization.phone,
            selector: 'phone',
        },
        {
            name: localization.webPage,
            selector: 'webPage',
        },
    ];

    return (
        <DataTable
            title={localization.menuTeacherAdmin}
            data={data}
            columns={columns}
            customStyles={CUSTOM_TABLE_STYLES}
            subHeader
            subHeaderComponent={createNewButton}
            noDataComponent={localization.warningTeacherNotFound}
        />
    );
};

export default TeacherTable;