import React from 'react';
import { ACCESS_TOKEN, Url } from '../../../constants';
import { useLocation } from 'react-router-dom'
import {LocalizedRedirect} from "../../../hook/useNavigation";
import qs from "query-string";
import {useDispatch} from "react-redux";
import uiActions from "../../../store/actions/uiActions";
import userActions from "../../../store/actions/userActions";

const OAuth2RedirectHandler = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const parameters = qs.parse(location.search);

    const redirect = (target) => {
        if (target && target !== "undefined") {
            return <LocalizedRedirect to={parameters.target}/>;
        } else {
            return <LocalizedRedirect to={Url.PROFILE}/>;
        }
    }

    if (parameters.token) {
        localStorage.setItem(ACCESS_TOKEN, parameters.token);
        dispatch(userActions.loadCurrentlyLoggedInUser());
        dispatch(uiActions.changeLanguage(parameters.lang));
        return redirect(parameters.target);
    } else {
        return <LocalizedRedirect to={Url.LOGIN} state={{
            from: location,
            error: parameters.error
        }}/>
    }
}

export default OAuth2RedirectHandler;