import React, {Fragment, useState} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import "./NavBar.css"
import localization from "../../utils/localization";
import {useDispatch, useSelector} from "react-redux";
import userActions from "../../store/actions/userActions";
import {isMobile} from 'react-device-detect';
import {Container, Nav, Navbar, NavDropdown, NavItem} from 'react-bootstrap';
import {Url} from "../../constants";
import {useNavigation} from "../../hook/useNavigation";
import {CONFIG} from "../../config";

const NavBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentLanguage = useSelector(state => state.uiReducer.currentLanguage);
    const authenticated = useSelector(state => state.userReducer.authenticated);
    const currentUser = useSelector(state => state.userReducer.currentUser);
    const [navExpanded, setNavExpanded] = useState(false);
    const SCROLL_THRESHOLD = 1;
    const transparentUrls = [Url.ROOT, Url.HOME, Url.EVENTS, Url.CLASSES, Url.TEACHERS];

    const isTransparentHeaderNeeded = () => {
        const currentPath = location.pathname;
        for (let url of transparentUrls) {
            if (currentPath.endsWith(url))
                return true;
        }
    }

    const transparentNavBar = isTransparentHeaderNeeded();

    const toggleMainNavClasses = (removeClass, addClass) => {
        const mainNav = document.getElementById("main-nav");
        if (mainNav) {
            mainNav.classList.remove(removeClass);
            mainNav.classList.add(addClass);
        }
    };

    const changeLogoSize = (fontSize) => {
        const logo = document.getElementById("logo");
        if (logo) {
            logo.style.fontSize = fontSize;
        }
    };

    const makeNavBarSolid = () => {
        toggleMainNavClasses("navbar-transparent", "navbar-solid");
        changeLogoSize("25px");
    };

    const makeNavBarTransparent = () => {
        toggleMainNavClasses("navbar-solid", "navbar-transparent");
        changeLogoSize("30px");
    };

    const toggleTransparency = () => {
        if (isMobile
            || !transparentNavBar
            || document.body.scrollTop > SCROLL_THRESHOLD
            || document.documentElement.scrollTop > SCROLL_THRESHOLD)
            makeNavBarSolid();
        else
            makeNavBarTransparent();
    }

    const closeNavBar = () => {
        setNavExpanded(false);
    };

    const changeLanguage = (lang) => {
        closeNavBar();

        const currentPath = location.pathname;
        const redirectUrl = currentPath.replace(new RegExp("\\/[a-z]{2}\\/", "gm"), "/" + lang + "/");
        navigate(redirectUrl, {replace: true})
    };

    const onLogout = () => {
        closeNavBar();
        dispatch(userActions.userLogout());
    };

    window.addEventListener('scroll', toggleTransparency);
    toggleTransparency();

    localization.setLanguage(currentLanguage);

    const title = localization.getString(CONFIG.profile.title);
    const imageUrl = currentUser ? currentUser.getImage() : null;
    const loginTitle = <img src={imageUrl} className="user-picture rounded-circle" alt=""/>;
    const languageTitle = <Fragment><span
        className={"mr-2 flag-icon flag-icon-" + localization.calendarLanguage}/>{localization.language}</Fragment>;

    return (
        <Navbar expand="lg" fixed="top" id="main-nav" onToggle={setNavExpanded} expanded={navExpanded}>
            <Container>
                <Navbar.Brand id="logo">
                    <MenuNavLink onClick={closeNavBar} url={Url.HOME} className="nav-link text-light" exact>
                        {title}
                    </MenuNavLink>
                </Navbar.Brand>

                <div className="hamburger-menu">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                </div>

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <MenuMain url={Url.HOME} onClick={closeNavBar}>{localization.menuHome}</MenuMain>
                        <MenuMain url={Url.EVENTS} onClick={closeNavBar}>{localization.menuEvents}</MenuMain>
                        <MenuMain url={Url.CLASSES} onClick={closeNavBar}>{localization.menuClasses}</MenuMain>
                        <MenuMain url={Url.TEACHERS} onClick={closeNavBar}>{localization.menuTeachers}</MenuMain>

                        <NavDropdown title={localization.menuExtras} id="extras-dropdown" className="menu-item-hover">
                            {CONFIG.profile.dancePartnerSeekerEnabled ? (
                                <MenuDropdown url={Url.DANCE_PARTNER_SEEKER} onClick={closeNavBar}>
                                    <i className="far fa-grin-hearts mr-2"/>{localization.menuDancePartnerSeeker}
                                </MenuDropdown>
                            ) : null}

                            <MenuDropdown url={Url.RADIO} onClick={closeNavBar}>
                                <i className="fas fa-headphones-alt mr-2"/>{localization.argentineTangoRadio}
                            </MenuDropdown>
                        </NavDropdown>
                    </Nav>

                    <Nav className="ml-auto menu-item-hover">
                        {authenticated ? (
                            <NavDropdown title={loginTitle} id="nav-dropdown">
                                <MenuDropdown url={Url.PROFILE} onClick={closeNavBar}>
                                    <i className="fas fa-user-circle mr-1"/> {localization.menuProfile}
                                </MenuDropdown>

                                {currentUser.privileges.admin ? (
                                    <>
                                        <NavDropdown.Divider/>

                                        <MenuDropdown url={Url.EVENTS_ADMIN} onClick={closeNavBar}>
                                            <i className="far fa-list-alt mr-1"/> {localization.menuMyEvents}
                                        </MenuDropdown>

                                        <MenuDropdown url={Url.MY_CLUBS} onClick={closeNavBar}>
                                            <i className="far fa-address-card"/> {localization.menuMyClubs}
                                        </MenuDropdown>

                                        <MenuDropdown url={Url.TEACHER_ADMIN} onClick={closeNavBar}>
                                            <i className="fas fa-chalkboard-teacher"/> {localization.menuTeacherAdmin}
                                        </MenuDropdown>
                                    </>
                                ) : null}

                                <NavDropdown.Divider/>

                                <NavDropdown.Item className="profile-menu" onClick={onLogout}>
                                    <i className="fas fa-user-times"/> {localization.menuLogout}
                                </NavDropdown.Item>

                            </NavDropdown>
                        ) : (
                            <MenuMain url={Url.LOGIN} onClick={closeNavBar}>
                                <i className="fas fa-user mr-1"/> {localization.menuLogin}
                            </MenuMain>
                        )}
                    </Nav>

                    <Nav className="menu-item-hover">
                        <NavDropdown title={languageTitle} id="language-dropdown">
                            <NavDropdown.Item onClick={() => changeLanguage('hu')}>
                                <span className="flag-icon flag-icon-hu"/> Magyar
                            </NavDropdown.Item>

                            <NavDropdown.Item onClick={() => changeLanguage('en')}>
                                <span className={"flag-icon flag-icon-gb"}/> English
                            </NavDropdown.Item>

                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

const MenuMain = (props) => (
    <NavItem onClick={props.onClick}>
        <MenuNavLink url={props.url}
                     className="nav-link menu-item-hover"
                     activeClassName='active-link'
                     exact>
            {props.children}
        </MenuNavLink>
    </NavItem>
)

const MenuNavLink = (props) => {
    const {localizedUrl} = useNavigation();

    return (
        <NavLink onClick={props.onclick}
                 to={localizedUrl(props.url)}
                 className={props.className}
                 activeclassname={props.activeClassName}
                 end>
            {props.children}
        </NavLink>
    )
}

const MenuDropdown = (props) => {
    const {localizedUrl} = useNavigation();

    return (
        <NavDropdown.Item as={NavLink}
                          to={localizedUrl(props.url)}
                          className="profile-menu"
                          activeclassname="profile-menu-active"
                          onClick={props.onClick}>
            {props.children}
        </NavDropdown.Item>
    )
}

export default NavBar;