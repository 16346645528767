export function filterClub(data, searchClub) {
    return data.filter( event => {
        return event.clubMeta.id === parseInt(searchClub) || searchClub === '';
    });
}

export function filterCategory(data, searchCategory) {
    return data.filter( event => {
        return event.eventType.indexOf(searchCategory) !== -1;
    });
}

export function filterTitle(data, searchTitle) {
    return data.filter( event => {
        return event.title.toLowerCase().indexOf(searchTitle.toLowerCase()) !== -1;
    });
}

export function filterName(data, searchTitle) {
    return data.filter( item => {
        return item.name.toLowerCase().indexOf(searchTitle.toLowerCase()) !== -1;
    });
}

export function filterUpcomingEvents(data) {
    return data.filter( event => {
        return !isPastEvent(event.startDateTime)
    });
}

export const isPastEvent = (startDate) => {
    return new Date(startDate) < new Date()
};