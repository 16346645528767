import React, {useEffect} from "react";
import {BrowserRouter} from 'react-router-dom';
import "./App.css";
import NavBar from "./component/NavBar/NavBar";
import AutoScrollToTop from "./component/ScrollToTop/AutoScrollToTop";
import {useDispatch, useSelector} from "react-redux";
import LoadingIndicator from "./component/LoadingIndicator/LoadingIndicator";

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import userActions from "./store/actions/userActions";
import eventActions from "./store/actions/eventActions";
import {CONFIG} from "./config";
import localization from "./utils/localization";
import {getBaseUrl} from "./utils/helperFunctions";
import AppRoutes from "./routes/AppRoutes";
import Alert from "react-s-alert";
import Footer from "./component/Footer/Footer";

function App() {
    // Only need to force this component to re-render after the language change:
    const currentLanguage = useSelector(state => state.uiReducer.currentLanguage);
    const country = useSelector(state => state.uiReducer.country);
    const loading = useSelector(state => state.userReducer.loading)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.loadCurrentlyLoggedInUser());
        dispatch(eventActions.fetchClubNames(country));

        document.title = localization.getString(CONFIG.profile.title);
        replaceFaviconByProfile();
    }, [dispatch, country]);

    const replaceFaviconByProfile = () => {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        link.href = getBaseUrl() + '/icon/' + CONFIG.profile.favicon;
    }

    return (
        <BrowserRouter>
            <AutoScrollToTop/>

            <div id="apppage">
                <NavBar/>
                {loading ?
                    (<LoadingIndicator/>)
                    : (
                        <div key={currentLanguage}>
                            <AppRoutes/>
                        </div>
                    )}
                <Alert stack={{limit: 3}} timeout={3000} position='top-right' effect='slide' offset={65}/>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;