import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {CustomForm, HiddenField, InputField, SelectItem, SubmitButton} from "../../../component/FormInputs/FormInputs";
import {Button, Col, Row} from "react-bootstrap";
import EditPictureModal from "../../../component/FormModal/EditPictureModal";
import {handleError, putAuthenticated} from "../../../utils/connection";
import {API_USER_SETTING, USERNAME_MAX_LENGTH} from "../../../constants";
import localization from "../../../utils/localization";
import {useDispatch, useSelector} from "react-redux";
import {useModal} from "../../../hook/useModal";
import Modal from "react-bootstrap/Modal";
import LoadingIndicator from "../../../component/LoadingIndicator/LoadingIndicator";
import ModalBase from "../../../component/FormModal/ModalBase";
import userActions from "../../../store/actions/userActions";
import {getLanguageOptions} from "../../../utils/enumMapper";

const ProfileSettingsModal = (props) => {
    const currentUser = useSelector(state => state.userReducer.currentUser);
    const modal = useModal();
    const formControl = useForm();
    const [data, setData] = useState(currentUser);
    const dispatch = useDispatch();

    const editPictureButton =
        <Button variant={"warning"} size={"sm"} disabled={data.socialData} style={{width: "128px"}}>
            <i className="fas fa-edit"/> {localization.formNewProfilePicture}
        </Button>

    const updatePicture = (picturePath) => {
        formControl.setValue("imageUrl", picturePath);

        const modifiedData = {...data};
        modifiedData.imageUrl = picturePath;
        setData(modifiedData);
    };

    const onSubmit = async submittedData => {
        modal.setLoading(true);

        const userSettingRequest = {
            name: submittedData.name,
            imageUrl: submittedData.imageUrl,
            language: submittedData.language
        }

        try {
            await putAuthenticated(API_USER_SETTING, userSettingRequest);
            dispatch(userActions.loadCurrentlyLoggedInUser());
        } catch (error) {
            handleError(error);
        }

        modal.setLoading(false);
    }

    return (
        <>
            {modal.renderTrigger(props.trigger)}
            <ModalBase show={modal.show} title={localization.settingCardNameAndProfileTitle} size="md">
                {modal.loading ? (
                    <Modal.Body>
                        <div style={{height: "200px"}}>
                            <LoadingIndicator/>
                        </div>
                    </Modal.Body>
                ) : (
                    <CustomForm formControl={formControl} onSubmit={onSubmit}>
                        <Modal.Body>
                            <HiddenField formControl={formControl} name="imageUrl"
                                         value={data.imageUrl ? data.imageUrl : ""}/>
                            <div className={"px-2"}>
                                <Row>
                                    <Col sm={5}>
                                        <div className="font-weight-bold mb-2">
                                            {localization.formProfilePicture}
                                        </div>

                                        <div className="mb-2">
                                            <img src={data.getImage()} alt={data.name} width={128} height={128}/>
                                        </div>

                                        <EditPictureModal s3Folder={"profile"}
                                                          width={512}
                                                          height={512}
                                                          onUpdate={updatePicture}
                                                          trigger={editPictureButton} modalSize={"lg"}/>
                                    </Col>

                                    <Col className="mt-3 mt-sm-0">
                                        <InputField required formControl={formControl}
                                                    defaultValue={data.name}
                                                    name="name"
                                                    label={localization.formName}
                                                    maxLength={USERNAME_MAX_LENGTH}
                                                    disabled={data.socialData}/>

                                        <SelectItem formControl={formControl}
                                                    defaultValue={data.language}
                                                    label={localization.formPreferredLanguage}
                                                    name="language"
                                                    options={getLanguageOptions()}/>
                                    </Col>
                                </Row>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={modal.handleClose}>{localization.formDiscard}</Button>
                            <SubmitButton caption={localization.formSubmit}/>
                        </Modal.Footer>
                    </CustomForm>
                )}
            </ModalBase>
        </>
    );
};

export default ProfileSettingsModal;