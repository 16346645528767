import React from 'react';
import {Card} from "react-bootstrap";

const InfoPanel = props => {
    return (
        <Card className={"p-2 mt-4 border-dark " + props.className} style={{backgroundColor: "rgb(231,239,252)"}}>
            {props.children}
        </Card>
    );
};

export default InfoPanel;