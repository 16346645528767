import LocalizedStrings from 'react-localization';

const localization = new LocalizedStrings({
    en: {
        calendarLanguage: "gb",
        titleTangoHungary: "Tango Hungary",
        titleMilongaFinder: "Milonga Finder",
        titleTangoKrakow: "Tango Krakow",
        language: "English",
        error404: "Page not found",

        country: "Country",
        countryHungary: "Hungary",
        countrySerbia: "Serbia",
        countryPoland: "Poland",

        menuHome: "Home",
        menuEvents: "Events",
        menuClasses: "Classes",
        menuLogin: "Login",
        menuTeachers: "Teaching",
        menuProfile: "Profile",
        menuLogout: "Logout",
        menuMyClubs: "My Clubs",
        menuMyEvents: "My Events",
        menuTeacherAdmin: "My Instructors",
        menuExtras: "Extras",
        menuDancePartnerSeeker: "Find a Partner",

        headerEvents: "Tango Events",
        headerEventsDescription1: "Milonga, Practica, Festival, Marathon and other tango events.",
        headerEventsDescription2: "Scroll down to discover the upcoming tango events or use the buttons below!",
        headerEventsButtonCalendar: "Calendar",
        headerEventsButtonUpcomingEvents: "Upcoming Events",
        headerTeachers: "Tango Clubs and Instructors",
        headerTeachersDescription1: "List of the tango instructors, dance schools and clubs.",
        headerTeachersDescription2: "Scroll down to discover the lists or use the buttons below!",
        headerClassesDescription1: "Search classes, courses, workshops by tango clubs.",

        filter: "Filter",
        filterTitle: "Filtering Events",
        filterEventType: "Category",
        filterHintEventType: "All",
        filterEventTitle: "Title",
        filterHintEventTitle: "Enter title",
        filterButtonReset: "Reset",
        filterTangoClub: "Tango Club",
        filterPastEvents: "Past events",
        filterShow: "Show",

        eventIndependent: "Independent event",

        eventTypeMilonga: "Milonga",
        eventTypePractica: "Practica",
        eventTypeFestival: "Festival",
        eventTypeMarathon: "Marathon",
        eventTypeCamp: "Tango Camp",
        eventTypeOther: "Other",
        eventTypeClass: "Class",
        eventTypeWorkshop: "Workshop",
        eventTypeTechnique: "Technique",
        eventTypeOnline: "Online",
        eventDescription: "Description",
        eventStartDate: "Start Date",
        eventEndDate: "End Date",
        eventSource: "Source",
        eventLastUpdated: "Last updated",

        sectionTitleCalendar: "Event Calendar",
        sectionTitleUpcomingEvents: "Upcoming Tango Events",
        details: "Details",
        buttonBack: "Back",
        eventInfo: "Information",
        eventLocation: "Location",
        footerCopyright: "All rights reserved",
        footerTermOfUse: "Term of use",
        footerPrivacyPolicy: "Privacy Policy",
        footerPhotos: "Photos",
        footerCopyrightZoli: "Zoltán Németh",
        noDescription: "No description.",
        notSpecified: 'Not specified',
        notPublic: 'Not public',
        noEvent: "There is no event.",
        webPage: "Web Page",
        phone: "Phone",
        address: "Address",
        gallery: "Gallery",
        tangoTeachers: "Tango Instructors",
        tangoSchools: "Dance Schools & Clubs",
        pageUnderConstruction: "Coming Soon!",
        warningEventNotFound: "No tango events found on this date!",
        warningClubNotFound: 'There is no tango club created.',
        warningTeacherNotFound: "There is no instructor created.",
        warningClassNotFound: 'There is no event found. Create one!',
        warningClassNoClub: 'There is no club assigned to this user. Without a tango club the creation or modification of an event is not possible!',

        homeHeader: "Explore the argentine tango events in many countries!",
        homeHeaderHU: "Explore the life of argentine tango in Hungary!",
        homeHeaderKrakow: "Explore the life of argentine tango in Krakow!",
        homeWelcome: "Welcome",
        homeWelcomeText1: "This web page is a collection of local tango life, where you can find plenty of information about milongas, classes, teachers and other tango events without a Facebook account.",
        homeWelcomeText2: "This web page is being updated continuously and introducing many new features time by time. Jump to the What's new section in case you are interested what kind of features does this page contain!",
        homeSiteMap: "Contents",
        homeWhatsNew: "What's new",
        homeWeeklyEvents: "Tango Events in This Week",

        argentineTangoRadio: "Argentine Tango Radio",
        argentineTangoRadioText1: "Best of danceable Argentine tango music from the Golden Age and on, by DJ Balazs Gyenis.",
        argentineTangoRadioText2: "The most listened-to tango radio in the world!",
        argentineTangoRadioText3: "Listen what's currently playing!",

        googleCalendar: 'Google Calendar',
        googleCalendarAdd: 'Add event to Google Calendar',

        copyrightTamasSzalai: 'Tamás Szalai',
        copyrightZoli: 'Photo: Zoltán Németh',
        copyrightMagdi: 'Photo: Magdi Gál',

        dancePartnerHeader: "Whether you're a leader or a follower, this is a great place to find your next tango partner.",
        dancePartnerCardRole: 'Role',
        dancePartnerCardLevel: 'Level',
        dancePartnerCardActiveYears: 'Active years',
        dancePartnerCardHeight: 'Height',
        dancePartnerCardAge: 'Age',
        dancePartnerCardPreferredClub: 'Preferred tango club',
        dancePartnerCardCategoryCourse: 'Class',
        dancePartnerCardCategoryPractice: 'Practice',
        dancePartnerCardCategoryWorkshop: 'Workshop',
        dancePartnerCardCategoryCamp: 'Tango Camp',
        dancePartnerCardCategoryRegistration: 'Event Registration',
        dancePartnerCardExpiryDate: 'This advertisement will expire on',
        dancePartnerCardExpired: 'Expired advertisement. Please set a recent date by clicking on the Modify button!',
        dancePartnerCardTitleAdvertisement: 'Advertisement',
        dancePartnerCardCabeceoReceived: "You've received a Cabeceo for this advertisement",
        dancePartnerCardNotAvailable: 'This advertisement is not available anymore.',

        dancePartnerMessageSendCabeceo: 'Send a Cabeceo',
        dancePartnerMessageCabeceoSentSuccessfully: 'Your cabeceo sent successfully!',
        dancePartnerMessageInfo: 'The following information will be sent to your chosen partner.',
        dancePartnerMessagePlaceholder: 'Write here a message, which will be sent to your partner in email!',
        dancePartnerMessageLogin: 'Please Sign In to send a message!',
        dancePartnerMessageEmailSubject: "You've received a Cabeceo!",
        dancePartnerMessageEmailBody1: "You have received this message, because someone would like to invite you to dance. Please read their message below and make contact with your next potential partner!",
        dancePartnerMessageEmailBody2: 'Here you can see your posted advertisement',
        dancePartnerMessageChosenPartnerInfo: 'Information of your next dance partner',
        dancePartnerMessageNoOneLookingFor: 'Currently nobody looking for a dance partner.',

        dancePartnerAdminInfo: "The information below will be visible on your advertisements and these will be sent to your chosen dance partner as well.",
        dancePartnerAdminMyAdvertisements: "My Advertisements",
        dancePartnerAdminCreateAdvertisement: "Create Advertisement",
        dancePartnerAdminNoAdvertisement: "Currently you doesn't have any advertisements.",
        dancePartnerAdminMinBodyHeightError: "Your height cannot be smaller than ",
        dancePartnerAdminMaxBodyHeightError: "Your height cannot be larger than ",

        dancePartnerAdModalTitle: 'Dance Partner Advertisement',
        dancePartnerAdModalCategory: 'Where would you like to find a partner?',
        dancePartnerAdModalExpirationDate: 'How long would you like to see your advertisement?',
        dancePartnerAdModalRole: 'Are you a Leader or a Follower?',
        dancePartnerAdModalIntroduction: 'Introduce yourself!',

        skillLevelAbsoluteBeginner: 'Absolute beginner',
        skillLevelBeginner: 'Beginner',
        skillLevelBeginnerIntermediate: 'Beginner-Intermediate',
        skillLevelIntermediate: 'Intermediate',
        skillLevelIntermediateAdvanced: 'Intermediate-Advanced',
        skillLevelAdvanced: 'Advanced',

        roleLeader: 'Leader',
        roleFollower: 'Follower',

        formName: 'Name',
        formEmail: 'Email Address',
        formPassword: 'Password',
        formConfirmPassword: 'Confirm Password',
        formCurrentPassword: "Current password",
        formNewPassword: "New Password",
        formOr: 'Or',
        formSignIn: 'Sign In',
        formSignUp: 'Sign Up',
        formNewUser: 'New user?',
        formHasAccount: 'Already have an account?',
        formSubmit: 'Submit',
        formEmailPlaceholder: 'name@example.com',
        formProfilePicture: 'Profile Picture',
        formNewProfilePicture: "New Profile Picture",
        formCoverPicture: 'Cover Picture',
        formSave: 'Save',
        formDiscard: 'Discard',
        formOk: 'Ok',
        formSavePicture: 'Save Picture',
        formDescription: 'Descriptions',
        formDescriptionShort: 'Short Description',
        formDescriptionLong: 'Long Description',
        formUploadPicture: 'Upload Picture',
        formTime: 'Time',
        formClub: 'Club',
        formRecurringWeekly: "Weekly recurring event (for 8 weeks)",
        formRecurringEvent: "Recurring Event",
        formRecurringEventEdit: "Edit recurring event",
        formRecurringEventDelete: "DELETE recurring event",
        formRecurringCurrent: "Only this event",
        formRecurringUpcoming: "This event and all upcoming events",
        formGeneralInformation: "General Information",
        formUploadPictureToGallery: "Upload Pictures to Gallery",
        formClickHereToUploadPicture: "Click here to upload a picture!",
        formMessage: 'Message',
        formPreferredLanguage: 'Preferred Language',
        formHashtag: 'Hashtag',

        pictureEditorBrowse: 'Browse Picture',
        pictureEditorInstruction: 'Drag \'n\' drop an image file here or click on the \'Browse Picture\' button to select one from your device!',
        pictureEditorZoom: 'Zoom',
        pictureEditorSaveError: 'No picture added!',
        pictureEditorRotate: 'Rotate',

        profileSectionUser: 'User settings',
        profileSectionAdmin: 'Administrator settings',

        settingCardNameAndProfileTitle: 'Profile Settings',
        settingCardNameAndProfileDescription: 'Modify your username, profile picture and preferred language.',
        settingCardChangePasswordTitle: 'Change password',
        settingCardChangePasswordDescription: 'Change your current password.',
        settingCardDancePartnerSeekerTitle: 'Find a Partner - Advertisements',
        settingCardDancePartnerSeekerDescription: 'Manage your advertisements to find a dance partner.',
        settingCardMyClubsDescription: 'Manage your tango clubs.',
        settingCardTangoEventsDescription: 'Manage tango events.',
        settingCardTeacherAdminDescription: 'Manage profiles of tango instructors.',

        actionModify: 'Modify',
        actionDelete: 'Delete',
        actionCopy: 'Copy',
        actionCreateClub: 'Create Club',
        actionCreateEvent: 'Create Event',
        actionAddTeacher: 'New Instructor',
        actionDonate: 'Donate',

        confirmDelete: 'Are you sure you want to delete this item?',
        confirmDeleteRecurring: 'Are you sure you want to delete this recurring event and all the upcoming?',

        loginUnexpectedError: 'Something went wrong. Please try again!',
        loginLogoutMessage: 'You\'re logged out successfully!',
        loginBadCredentials: 'Incorrect email or password!',
        loginEmailInUse: 'This email address is already in use. Choose another one!',
        loginPasswordMismatch: 'The Password and Confirmation Password does not match!',
        loginForgotPassword: 'Forgotten password',
        loginForgotPasswordEmail: 'We\'ll send you the reset password instructions to your registered email address',
        loginResetPassword: 'Reset password',
        loginResetPasswordInfo: 'Please give your new password in the fields below',
        loginResetPasswordSent: 'Reset password instructions sent to your email address!',
        loginResetPasswordUserNotFound: 'User not exists with the given email address!',
        loginResetPasswordTokenExpired: 'This url to reset password expired. Please request a new one!',
        loginResetPasswordSucceed: 'Your password has been changed.',
        errorInvalidPassword: 'Your current password is invalid.',

        mailResetPwSubject: '%s: Password reset',
        mailResetPwBody: '<h3>Hi %s!</h3><div>You\'ve recently asked to reset the password for your %s account.<br/>' +
            'To update your password, click the following link: <a href="%s">Reset my password!</a><br/>' +
            'You will be able to reset your password within 30 minutes!</div><br/>' +
            '<p>Best wishes,<br/>%s team</p>',

        validationRequired: 'This field is required',
        validationEmail: 'Incorrect email format',
        validationStartDateError: 'The Start Date has to be earlier than the End Date!',
        validationEventDurationError: "The event cannot be longer than 5 days!",
        validationDetailedDescriptionSizeError: "The detailed description is too long. Please give a shorter description!",
        validationNoProperCountryPrivilege: "Your user has no proper country privilege.",
        validationError: "Validation Error: some fields not filled properly.",

        modifySuccess: "The content was modified successfully",

        tableRowsPerPage: "Rows per page",

        infoMyClub1: "On this page you can modify the profile of the tango clubs, which are assigned to you user account or create a new club.",
        infoMyClub2: "Note: Multiple users able to manage the same tango club! In case you would like to assign somebody else to your club please contact Tamás Szalai!",
        infoMyEvents: 'This page contains the events, which are organized by the tango club assigned to your user account',
        warningDonationWebPage: "Do you like this web page? Donate here to help the further improvements!",
        infoTeachers: 'The following table contains the list of the tango instructors, which are managed by you.',

        news2022June: "For Administrators: The 'My Classes' administration page removed, from now you can add your classes also on the 'My Events' page.",
    },
    hu: {
        calendarLanguage: "hu",
        titleTangoHungary: "Tangó Magyarország",
        titleMilongaFinder: "Milonga Finder",
        titleTangoKrakow: "Tango Krakow",
        language: "Magyar",
        error404: "Ez az oldal nem található",

        country: "Ország",
        countryHungary: "Magyarország",
        countrySerbia: "Szerbia",
        countryPoland: "Lengyelország",

        menuHome: "Főoldal",
        menuEvents: "Események",
        menuClasses: "Kurzusok",
        menuLogin: "Belépés",
        menuTeachers: "Oktatás",
        menuProfile: "Adatlap",
        menuLogout: "Kijelentkezés",
        menuMyClubs: "Klubjaim",
        menuMyEvents: "Eseményeim",
        menuTeacherAdmin: "Oktatóim",
        menuExtras: "Extrák",
        menuDancePartnerSeeker: "Táncpartner Kereső",

        headerEvents: "Tangó Események",
        headerEventsDescription1: "Milongák, Praktikák, Fesztiválok, Maratonok és egyéb tangós események.",
        headerEventsDescription2: "Görgess lejjebb a közelgő események megtekintéséhez!",
        headerEventsButtonCalendar: "Naptár",
        headerEventsButtonUpcomingEvents: "Közelgő események",
        headerTeachers: "Tangó Klubok és Oktatók",
        headerTeachersDescription1: "Tangó oktatók, tánciskolák és tangó klubok listája.",
        headerTeachersDescription2: "Görgess lejjebb a listák megtekintéséhez vagy használd az alábbi gombokat!",
        headerClassesDescription1: "Órák, kurzusok, workshopok keresője klubonként.",

        filter: "Szűrő",
        filterTitle: "Esemény szűrő",
        filterEventType: "Kategória",
        filterHintEventType: "Összes",
        filterEventTitle: "Név",
        filterHintEventTitle: "Esemény neve",
        filterButtonReset: "Visszaállítás",
        filterTangoClub: "Tangó Klub",
        filterPastEvents: "Elmúlt események",
        filterShow: "Megjelenítés",

        eventIndependent: "Független esemény",

        eventTypeMilonga: "Milonga",
        eventTypePractica: "Praktika",
        eventTypeFestival: "Fesztivál",
        eventTypeMarathon: "Maraton",
        eventTypeCamp: "Tangótábor",
        eventTypeOther: "Egyéb",
        eventTypeClass: "Kurzus",
        eventTypeWorkshop: "Workshop",
        eventTypeTechnique: "Technika",
        eventTypeOnline: "Online",

        eventDescription: "Leírás",
        eventStartDate: "Kezdés ideje",
        eventEndDate: "Befejezés ideje",
        eventSource: "Forrás",
        eventLastUpdated: "Utoljára frissítve",

        sectionTitleCalendar: "Eseménynaptár",
        sectionTitleUpcomingEvents: "Közelgő események",
        details: "Részletek",
        buttonBack: "Vissza",
        eventInfo: "Információ",
        eventLocation: "Helyszín",
        footerCopyright: "Minden jog fenntartva",
        footerTermOfUse: "Felhasználási feltételek",
        footerPrivacyPolicy: "Adatvédelem",
        footerPhotos: "Fotók",
        footerCopyrightZoli: "Németh Zoltán",
        noDescription: "Nincs leírás.",
        notSpecified: 'Nincs megadva',
        notPublic: 'Nem publikus',
        noEvent: "Nincs esemény.",
        webPage: "Weboldal",
        phone: "Telefonszám",
        address: "Cím",
        gallery: "Galléria",
        tangoTeachers: "Tangó Oktatók",
        tangoSchools: "Tánciskolák & Klubok",
        pageUnderConstruction: "Hamarosan!",
        warningEventNotFound: "Ezen a dátumon nem található esemény!",
        warningClubNotFound: 'Még nem lett létrehozva tangó klub.',
        warningTeacherNotFound: 'Még nem lett létrehozva oktató.',
        warningClassNotFound: 'Egyelőre nincs esemény. Hozz létre egy újat!',
        warningClassNoClub: 'Ehhez a felhasználóhoz nincs klub hozzárendelve. Enélkül az esemény létrehozása vagy módosítása nem lehetséges!',

        homeHeader: "Fedezd fel az argentin tango eseményeit számos országban!",
        homeHeaderHU: "Fedezd fel az argentin tangós életet Magyarországon!",
        homeHeaderKrakow: "Fedezd fel az argentin tangós életet Krakkóban!",
        homeWelcome: "Üdvözlünk",
        homeWelcomeText1: "Ez a weboldal a helyi argentin tangós élet gyűjtőhelye, ahol Facebook fiók nélkül is számos információt megtalálsz többek között milongákról, tangó órákról, okatókról és más egyéb tangós eseményről.",
        homeWelcomeText2: "Ez a weboldal folyamatosan frissül, így időről időre hasznos funkciók kerülnek bevezetésre. Ugorj az Újdonságok szekcióhoz, ha érdelel miket rejt az oldal magában!",
        homeSiteMap: "Tartalmak",
        homeWhatsNew: "Újdonságok",
        homeWeeklyEvents: "Tangó Események a héten",

        argentineTangoRadio: "Argentin Tangó Rádió",
        argentineTangoRadioText1: "Az argentin tangó aranykorának legszebb tánczenéi, DJ Gyenis Balázs válogatásában.",
        argentineTangoRadioText2: "A világ leghallgatottabb tangó rádiója!",
        argentineTangoRadioText3: "Hallgasd meg mi szól éppen!",

        googleCalendar: 'Google Naptár',
        googleCalendarAdd: 'Esemény hozzáadsása a Google Naptárhoz',

        copyrightTamasSzalai: 'Szalai Tamás',
        copyrightZoli: 'Fotó: Németh Zoltán',
        copyrightMagdi: 'Fotó: Gál Magdi',

        dancePartnerHeader: "Vezetőként vagy követőként itt megtalálhatod a következő tangó partnered.",
        dancePartnerCardRole: 'Szerep',
        dancePartnerCardLevel: 'Szint',
        dancePartnerCardActiveYears: 'Aktív évek',
        dancePartnerCardHeight: 'Magasság',
        dancePartnerCardAge: 'Életkor',
        dancePartnerCardPreferredClub: 'Preferált tangó klub',
        dancePartnerCardCategoryCourse: 'Kurzus',
        dancePartnerCardCategoryPractice: 'Gyakorlás',
        dancePartnerCardCategoryWorkshop: 'Workshop',
        dancePartnerCardCategoryCamp: 'Tangó Tábor',
        dancePartnerCardCategoryRegistration: 'Esemény Regisztráció',
        dancePartnerCardExpiryDate: 'Eddig lesz látható az oldalon',
        dancePartnerCardExpired: 'Lejárt hirdetés. Adj meg egy későbbi dátumot a Szerkesztés gombra kattintva!',
        dancePartnerCardTitleAdvertisement: 'Hirdetés',
        dancePartnerCardCabeceoReceived: "Erre a hirdetésedre kaptál Cabeceot",
        dancePartnerCardNotAvailable: 'Ez a hirdetés már nem elérhető.',

        dancePartnerMessageSendCabeceo: 'Cabeceo küldése',
        dancePartnerMessageCabeceoSentSuccessfully: 'A cabeceo sikeresen elküldve!',
        dancePartnerMessageInfo: 'Az alábbi személyes adataid kerülnek továbbításra a partner jelöltednek.',
        dancePartnerMessagePlaceholder: 'Írj ide egy üzenetet a táncpartner jelöltednek, email-ben fog megkapni!',
        dancePartnerMessageLogin: 'Az üzenetküldéshez be kell jeletkezned!',
        dancePartnerMessageEmailSubject: 'Cabeceot kaptál!',
        dancePartnerMessageEmailBody1: 'Ezt a levelet azért kaptad, mert szeretnének felkérni táncpartnernek. Kérlek olvasd el az üzenetet és ha szimpatikusnak találod a leendő táncpartneredet, vedd fel a kapcsolatot!',
        dancePartnerMessageEmailBody2: 'A feladott hirdetésed itt tudod megtekinteni',
        dancePartnerMessageChosenPartnerInfo: 'A leendő partnered adatai',
        dancePartnerMessageNoOneLookingFor: 'Jelenleg senki nem keres táncpartnert.',

        dancePartnerAdminInfo: "Az itt megadott adatok lesznek láthatóak a hirdetéseidben, illetve ezek az adatok lesznek elküldve a kiválasztott táncpartnerednek.",
        dancePartnerAdminMyAdvertisements: "Hirdetéseim",
        dancePartnerAdminCreateAdvertisement: "Új hirdetés létrehozása",
        dancePartnerAdminNoAdvertisement: "Jelenleg nincs hirdetésed.",
        dancePartnerAdminMinBodyHeightError: "A testmagasság nem lehet kisebb, mint ",
        dancePartnerAdminMaxBodyHeightError: "A testmagasság nem lehet nagyobb, mint ",

        dancePartnerAdModalTitle: 'Táncpartner Hirdetés',
        dancePartnerAdModalCategory: 'Hova szeretnél partnert találni?',
        dancePartnerAdModalExpirationDate: 'Meddig legyen látható a hirdetésed?',
        dancePartnerAdModalRole: 'Vezetőként vagy követőként keresel partnert?',
        dancePartnerAdModalIntroduction: 'Mutatkozz be!',

        skillLevelAbsoluteBeginner: 'Teljesen kezdő',
        skillLevelBeginner: 'Kezdő',
        skillLevelBeginnerIntermediate: 'Kezdő-Középhaladó',
        skillLevelIntermediate: 'Középhaladó',
        skillLevelIntermediateAdvanced: 'Középhaladó-Haladó',
        skillLevelAdvanced: 'Haladó',

        roleLeader: 'Vezető',
        roleFollower: 'Követő',

        formName: 'Név',
        formEmail: 'Email cím',
        formPassword: 'Jelszó',
        formConfirmPassword: 'Jelszó újra',
        formCurrentPassword: "Jelenlegi jelszó",
        formNewPassword: "Új jelszó",
        formOr: 'Vagy',
        formSignIn: 'Bejelentkezés',
        formSignUp: 'Regisztráció',
        formNewUser: 'Új felhasználó vagy?',
        formHasAccount: 'Már van fiókja?',
        formSubmit: 'Küldés',
        formEmailPlaceholder: 'nev@pelda.hu',
        formProfilePicture: 'Profilkép',
        formNewProfilePicture: "Új Profilkép",
        formCoverPicture: 'Borítókép',
        formSave: 'Mentés',
        formDiscard: 'Elvetés',
        formOk: 'Ok',
        formSavePicture: 'Kép mentése',
        formDescription: 'Leírások',
        formDescriptionShort: 'Rövid leírás',
        formDescriptionLong: 'Részletes leírás',
        formUploadPicture: 'Új kép feltöltése',
        formTime: 'Időpont',
        formClub: 'Klub',
        formRecurringWeekly: "Hetente ismétlődő esemény (8 héten át)",
        formRecurringEvent: "Ismétlődő esemény",
        formRecurringEventEdit: "Ismétlődő esemény szerkesztése",
        formRecurringEventDelete: "Ismétlődő esemény TÖRLÉSE",
        formRecurringCurrent: "Ezt az eseményt",
        formRecurringUpcoming: "Ezt és az összes ezt követő eseményt",
        formGeneralInformation: "Általános információk",
        formUploadPictureToGallery: "Képek feltöltése a gallériába",
        formClickHereToUploadPicture: "Kattints ide a kép feltöltéséhez!",
        formMessage: 'Üzenet',
        formPreferredLanguage: 'Preferált Nyelv',
        formHashtag: 'Hashtag',

        pictureEditorBrowse: 'Kép Tallózása',
        pictureEditorInstruction: 'Dobj ide egy képet vagy kattints a \'Kép Tallózása\' gombra és válassz egyet az eszközödről!',
        pictureEditorZoom: 'Nagyítás',
        pictureEditorSaveError: 'Nem lett kép hozzáadva!',
        pictureEditorRotate: 'Forgatás',

        profileSectionUser: 'Felhasználói beállítások',
        profileSectionAdmin: 'Adminisztrátori beállítások',

        settingCardNameAndProfileTitle: 'Profil Beállítások',
        settingCardNameAndProfileDescription: 'A felhasználóneved, profilképed és preferált nyelv módosítása.',
        settingCardChangePasswordTitle: 'Jelszó Módosítása',
        settingCardChangePasswordDescription: 'Aktuális jelszavad megváltoztatása.',
        settingCardDancePartnerSeekerTitle: 'Táncpartner Hirdetések',
        settingCardDancePartnerSeekerDescription: 'Táncpartner kereséshez hírdetések feladása.',
        settingCardMyClubsDescription: 'Általad kezelt klubok adminisztrációja.',
        settingCardTangoEventsDescription: 'Tangó események adminisztrációja.',
        settingCardTeacherAdminDescription: 'Oktatók profiljainak adminisztrációja.',

        actionModify: 'Szerkesztés',
        actionDelete: 'Törlés',
        actionCopy: 'Másolás',
        actionCreateClub: 'Új Klub',
        actionCreateEvent: 'Új Esemény',
        actionAddTeacher: 'Új Oktató',
        actionDonate: 'Adomány',

        confirmDelete: 'Biztosan törölni szeretnéd ezt az elemet?',
        confirmDeleteRecurring: 'Biztosan törölni szeretnéd ezt az ismétlődő eseményt és az utánalevőt?',

        loginUnexpectedError: 'Váratlan hiba történt, próbáld újra később!',
        loginLogoutMessage: 'Sikeresen kijelentkeztél!',
        loginBadCredentials: 'Helytelen email cím vagy jelszó!',
        loginEmailInUse: 'Ezzel az email címmel már létezik felhasználói fiók. Válassz egy másikat!',
        loginPasswordMismatch: 'A megadott jelszavak nem egyeznek!',
        loginForgotPassword: 'Elfelejtett jelszó',
        loginForgotPasswordEmail: 'Elküldjük a regisztrált email címedre a jelszó visszaállításához szükséges információkat',
        loginResetPassword: 'Jelszó visszaállítása',
        loginResetPasswordInfo: 'Kérlek add meg az új jelszavad az alábbi mezőkbe',
        loginResetPasswordSent: 'A jelszóvisszaállításához szükséges utasításokat elküldtük az email címedre!',
        loginResetPasswordUserNotFound: 'Az adott email címmel nem található regisztrált felhasználó!',
        loginResetPasswordTokenExpired: 'A jelszó visszaállításához szükséges link lejárt, kérj egy újat!',
        loginResetPasswordSucceed: 'A jelszavad sikeresen megváltoztattad.',
        errorInvalidPassword: 'A jelenlegi jelszavad helytelenül lett megadva.',

        mailResetPwSubject: '%s: Jelszó visszaállítása',
        mailResetPwBody: '<h3>Üdvözlünk %s!</h3><div>Kérelmezted a %s fiókod jelszavának a visszaállítását.<br/>' +
            'A jelszavad frissítéséhez kattints a következő linkre: <a href="%s">Jelszavam visszaállítása!</a><br/>' +
            'A jelszó visszaállítására 30 perc áll rendelkezésre!</div><br/>' +
            '<p>Minden jót kívánunk,<br/>A %s csapata</p>',

        validationRequired: 'Kötelező mező',
        validationEmail: 'Helytelen email formátum',
        validationStartDateError: 'A kezdés dátumának korábbinak kell lennie a befejezéstől!',
        validationEventDurationError: "Az esemény nem lehet hosszabb 5 napnál!",
        validationDetailedDescriptionSizeError: "A részletes leírás túl hosszú. Kérlek adj meg egy rövidebb szöveget!",
        validationNoProperCountryPrivilege: "A felhasználódnak nincs megfelelő ország jogosultsága.",
        validationError: "Validációs hiba: valamelyik mező helytelenül lett kitöltve.",

        modifySuccess: "A tartalom módosítása sikeresen megtörtént",

        tableRowsPerPage: "Sorok oldalanként",

        infoMyClub1: "Ezen az oldalon tudod a felhasználói fiókodhoz rendelt tangó klubok profilját módosítani, illetve új klubot létrehozni.",
        infoMyClub2: "Megjegyzés: Egy tangó klubhot több felhasználó is kezelheti! Ha szeretnél valakit hozzárendelni a klubodhoz, akkor keresd kérlek Szalai Tamást!",
        infoMyEvents: 'Ezen az oldalon találhatóak az események, amelyek a felhasználódhoz rendelt klub által lett meghirdetve',
        warningDonationWebPage: "Tetszik ez a weboldal? A további hasznos fejlesztések segítéséhez itt adományozhatsz!",
        infoTeachers: 'Az alábbi táblázatban találhatóak az általad kezelt tangó oktatók listája.',

        news2022June: "Adminisztrátoroknak: A 'Kurzusaim' adminisztrációs oldal megszűnt, mostantól az 'Eseményeim' oldalon tudod felvenni az óráidat is.",
    }
});

export default localization;