import localization from "./localization";

export const ALL = "";
export const MILONGA = "MILONGA";
export const PRACTICA = "PRACTICA";
export const FESTIVAL = "FESTIVAL";
export const MARATHON = "MARATHON";
export const CAMP = "CAMP";
export const OTHER = "OTHER";
export const CLASS = "CLASS";
export const WORKSHOP = "WORKSHOP";
export const TECHNIQUE = "TECHNIQUE";
export const ONLINE = "ONLINE";

export const EventStyle = (event) => {
    let eventTypeTitle = "";
    let backgroundColor = "";
    let textColor = "text-white";
    const eventType = event.eventType;
    const isClass = eventType === CLASS || eventType === WORKSHOP || eventType === TECHNIQUE || eventType === ONLINE

    function isCancelled() {
        if (typeof event.title !== 'undefined')
            return event.title.toUpperCase().includes("CANCELLED") || event.title.toUpperCase().includes("SZÜNETEL")
                || event.title.toUpperCase().includes("ELMARAD");
        else
            return false;
    }

    function getBackgroundColor(color) {
        return isCancelled() ? "bg-light" : color
    }

    function getTextColor(color = "text-white") {
        return isCancelled() ? "text-danger" : color
    }

    if (eventType === MILONGA) {
        eventTypeTitle = localization.eventTypeMilonga;
        backgroundColor = getBackgroundColor('bg-success');
        textColor = getTextColor();
    }

    if (eventType === PRACTICA) {
        eventTypeTitle = localization.eventTypePractica;
        backgroundColor = getBackgroundColor('bg-warning');
        textColor = getTextColor("text-dark");
    }

    if (eventType === FESTIVAL) {
        eventTypeTitle = localization.eventTypeFestival;
        backgroundColor = getBackgroundColor('bg-danger');
        textColor = getTextColor();
    }

    if (eventType === MARATHON) {
        eventTypeTitle = localization.eventTypeMarathon;
        backgroundColor = getBackgroundColor('bg-danger');
        textColor = getTextColor();
    }

    if (eventType === CAMP) {
        eventTypeTitle = localization.eventTypeCamp;
        backgroundColor = getBackgroundColor('bg-danger');
        textColor = getTextColor();
    }

    if (eventType === OTHER) {
        eventTypeTitle = localization.eventTypeOther;
        backgroundColor = getBackgroundColor('bg-dark');
        textColor = getTextColor();
    }

    if (eventType === CLASS) {
        eventTypeTitle = localization.eventTypeClass;
        backgroundColor = getBackgroundColor('bg-primary');
        textColor = getTextColor();
    }

    if (eventType === WORKSHOP) {
        eventTypeTitle = localization.eventTypeWorkshop;
        backgroundColor = getBackgroundColor('bg-info');
        textColor = getTextColor();
    }

    if (eventType === TECHNIQUE) {
        eventTypeTitle = localization.eventTypeTechnique;
        backgroundColor = getBackgroundColor('badge-technique');
        textColor = getTextColor();
    }

    if (eventType === ONLINE) {
        eventTypeTitle = localization.eventTypeOnline;
        backgroundColor = getBackgroundColor('badge-online');
        textColor = getTextColor();
    }

    return {eventTypeTitle, backgroundColor, textColor, isClass, isCancelled: isCancelled()};
};

export const getEventCategoryOptions = () => [
    {value: MILONGA, text: localization.eventTypeMilonga},
    {value: PRACTICA, text: localization.eventTypePractica},
    {value: MARATHON, text: localization.eventTypeMarathon},
    {value: FESTIVAL, text: localization.eventTypeFestival},
    {value: CAMP, text: localization.eventTypeCamp},
    {value: OTHER, text: localization.eventTypeOther}
];

export const getClassCategoryOptions = () => [
    {value: CLASS, text: localization.eventTypeClass},
    {value: WORKSHOP, text: localization.eventTypeWorkshop},
];

export const getTangoEventOptions = () => {
    const options = getClassCategoryOptions();
    options.push(...getEventCategoryOptions());

    console.log(options)

    return options;
}