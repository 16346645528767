import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import localization from "../../utils/localization";
import {CustomForm, RadioButtonGroup, SubmitButton} from "../FormInputs/FormInputs";
import {useForm} from "react-hook-form";
import ModalBase from "./ModalBase";

const OPTION_CURRENT_ONLY = "CURRENT_ONLY";
const OPTION_CURRENT_AND_UPCOMING = "CURRENT_AND_UPCOMING";

const RecurringEventModal = (props) => {
    const formControl = useForm();

    const onSubmit = (data) => {
        const modifyCurrentAndUpcominEvents = data.recurringEditOptions === OPTION_CURRENT_AND_UPCOMING;
        props.onSubmit(modifyCurrentAndUpcominEvents);
    }

    const radioBtnOptions = [
        {label: localization.formRecurringCurrent, value: OPTION_CURRENT_ONLY},
        {label: localization.formRecurringUpcoming, value: OPTION_CURRENT_AND_UPCOMING}
    ]

    return (
        <ModalBase show={props.show} title={props.title} size={"md"}>
            <CustomForm formControl={formControl} onSubmit={onSubmit}>
                <Modal.Body>
                    <h5 className="mb-4">{props.text}</h5>
                    <RadioButtonGroup formControl={formControl} name="recurringEditOptions" options={radioBtnOptions} defaultValue={OPTION_CURRENT_ONLY}/>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>{localization.formDiscard}</Button>
                    <SubmitButton caption={localization.formOk}/>
                </Modal.Footer>
            </CustomForm>
        </ModalBase>
    )
};

export default RecurringEventModal;