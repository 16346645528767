import React from "react";
import googleLogo from "./google.png";
import facebookLogo from "./facebook.png";
import './SocialLogin.css';
import {useSelector} from "react-redux";
import {CONFIG} from "../../../config";
import {getBaseUrl} from "../../../utils/helperFunctions";

const SocialLogin = (props) => {
    const currentLanguage = useSelector(state => state.uiReducer.currentLanguage);
    const mode = "&mode=" + props.mode;
    const target = "&target=" + encodeURIComponent(props.target);

    const OAUTH2_REDIRECT_URI = getBaseUrl() + '/oauth2/redirect?lang=' + currentLanguage + mode + target;
    const GOOGLE_AUTH_URL = CONFIG.apiUrl + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
    const FACEBOOK_AUTH_URL = CONFIG.apiUrl + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

    return (
        <div className="text-center">
            <a href={GOOGLE_AUTH_URL}>
                <div className="btn btn-outline-secondary google-button">
                    <img src={googleLogo} alt="Google" /><span className="font-weight-bold ml-2">Google</span>
                </div>
            </a>

            <a href={FACEBOOK_AUTH_URL}>
                <div className="btn btn-outline-secondary facebook-button ml-3">
                    <img src={facebookLogo} alt="Facebook" /><span className="font-weight-bold ml-2">Facebook</span>
                </div>
            </a>
        </div>
    )
};

export default SocialLogin;