import {getProfileByDomain} from "./profiles";

const backendUrl = {
    prod: "https://api.tangohungary.hu",
    dev: "http://localhost:8080"
}

export const CONFIG = {
    profile: getProfileByDomain(),
    apiUrl: backendUrl.prod
}