import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import EditUserInfoModal from "./EditUserInfoModal";
import BackButton from "../../../../component/BackButton/BackButton";
import {ModalTriggerEditButton} from "../../../../component/ActionButtons/ActionButtons";
import DancePartnerCard from "../../../../component/DancePartnerCard/DancePartnerCard";
import {API_DANCE_PARTNER, Url} from "../../../../constants";
import DialogLayout from "../../../../layout/DialogLayout/DialogLayout";
import CreateButton from "../../../../component/Buttons/CreateButton";
import EditDancePartnerAdvertisementModal from "./EditDancePartnerAdvertisementModal";
import localization from "../../../../utils/localization";
import {useSelector} from "react-redux";
import {getUserAgeOptionText, getUserSkillLevelOptionText} from "../../../../utils/enumMapper";
import {simpleAuthenticatedDelete, simpleAuthenticatedQuery} from "../../../../utils/connection";
import InfoPanel from "../../../../component/InfoPanel/InfoPanel";

const EditDancePartnerAdminPage = () => {
    const currentUser = useSelector(state => state.userReducer.currentUser);
    const [cards, setCards] = useState(null);

    const userInfo = currentUser ? currentUser.tangoUserInfo : {};

    const fetchMyCards = useCallback(() => {
        return simpleAuthenticatedQuery(API_DANCE_PARTNER + "my", setCards);
    }, [])

    useEffect(() => {
        const controller = fetchMyCards();
        return () => controller.abort();
    }, [fetchMyCards])

    const onDeleteCard = (id) => {
        simpleAuthenticatedDelete(API_DANCE_PARTNER + id, fetchMyCards, fetchMyCards)
    }

    const renderDancePartnerCards = () => {
        if (cards && cards.length > 0) {
            return cards.map((data, index) => (
                <Col lg={6} className="mb-5" key={"card-" + index}>
                    <DancePartnerCard data={data} onDelete={onDeleteCard} onSubmit={fetchMyCards} edit/>
                </Col>
            ));
        } else {
            return (
                <>
                    <Col/>
                    <Col sm={10} className="mt-3">
                        <h5 className="text-center">{localization.dancePartnerAdminNoAdvertisement}</h5>
                    </Col>
                    <Col/>
                </>
            )
        }
    }

    return (
        <Container className="space-top">
            <h1 className="text-center mb-5">{localization.settingCardDancePartnerSeekerTitle}</h1>

            <BackButton backUrl={Url.PROFILE}/>
            <InfoPanel className="mb-2 mt-2">
                <h5><i className="fas fa-info-circle mr-2"/>{localization.eventInfo}</h5>
                {localization.dancePartnerAdminInfo}
            </InfoPanel>

            <DialogLayout title={localization.formGeneralInformation}>
                <Row>
                    <Col>
                        <div className="font-weight-bold">{localization.dancePartnerCardHeight}: </div>{userInfo.bodyHeight}
                    </Col>

                    <Col>
                        <div className="font-weight-bold">{localization.dancePartnerCardAge}: </div>{getUserAgeOptionText(userInfo.age)}
                    </Col>

                    <Col>
                        <div className="font-weight-bold">{localization.dancePartnerCardLevel}: </div>{getUserSkillLevelOptionText(userInfo.skillLevel)}
                    </Col>

                    <Col>
                        <div className="font-weight-bold">{localization.dancePartnerCardActiveYears}: </div>{userInfo.activeDancingYears}
                    </Col>

                    <Col>
                        <EditUserInfoModal trigger={ModalTriggerEditButton} userInfo={userInfo}/>
                    </Col>
                </Row>
            </DialogLayout>


            <div className="mt-5"/>
            <DialogLayout title={localization.dancePartnerAdminMyAdvertisements} loading={cards === null}>
                <div className="mt-4" />

                <Row>
                    {renderDancePartnerCards()}
                </Row>

                <div className="m-2 mt-5">
                    <EditDancePartnerAdvertisementModal
                        trigger={<CreateButton title={localization.dancePartnerAdminCreateAdvertisement} block/>}
                        onSubmit={fetchMyCards}/>
                </div>
            </DialogLayout>

        </Container>
    );
};

export default EditDancePartnerAdminPage;