import React, {Fragment, useEffect} from "react";
import SectionCard from "../../component/SectionCard/SectionCard";
import ScrollLink from "../../component/ScrollLink/ScrollLink";
import localization from "../../utils/localization";
import HeadLayout from "../../layout/HeadLayout/HeadLayout";
import Calendar from "../../component/Calendar/Calendar";
import {useSelector} from "react-redux";
import {Url} from "../../constants";
import {Col, Row} from "react-bootstrap";
import {CONFIG} from "../../config";
import {useLocation} from "react-router-dom";
import WhatsNewSection from "./WhatsNewSection";
import {sendGAPageView} from "../../utils/googleAnalytics";

const Home = () => {
    const location = useLocation();
    const events = useSelector(state => state.eventReducer.events);

    const SM = 6;
    const MD = 4;

    useEffect(() => {
        if (location.pathname === '/') {
            sendGAPageView("home");
        }
    }, [location]);

    return (
        <Fragment>
            <HeadLayout pic={CONFIG.profile.coverImageHome}>
                <div className="text-center text-white">
                    <h1>{localization.homeWelcome}</h1>
                    <h4 className="mt-4 mt-md-5">{localization.getString(CONFIG.profile.textHomeHeader)}</h4>

                    <div className="container mt-4 mt-md-5 text-justify">
                        <h6>{localization.homeWelcomeText1}</h6>
                        <h6 className="mt-4">{localization.homeWelcomeText2}</h6>
                    </div>

                    <ScrollLink className="text-white mt-3" to="whats-new" text={localization.homeWhatsNew}/>
                </div>
            </HeadLayout>

            <section id="pages" className="container">
                <h1 className="text-center mt-5 mb-3">{localization.homeWeeklyEvents}</h1>
                <Calendar today dayGridWeek filteredEvents={events}/>
            </section>

            <section id="pages" className="container">
                <h1 className="text-center mt-5 mb-3">{localization.homeSiteMap}</h1>

                <Row>
                    <Col sm={SM} lg={MD}>
                        <SectionCard
                            title={localization.menuEvents}
                            url={Url.EVENTS}
                            picture="events.jpg"
                            copyright={localization.copyrightZoli}
                            description={localization.headerEventsDescription1}/>
                    </Col>

                    <Col sm={SM} lg={MD}>
                        <SectionCard
                            title={localization.menuClasses}
                            url={Url.CLASSES}
                            picture="classes.jpg"
                            copyright={localization.copyrightZoli}
                            description={localization.headerClassesDescription1}/>
                    </Col>

                    <Col sm={SM} lg={MD}>
                        <SectionCard
                            title={localization.menuTeachers}
                            url={Url.TEACHERS}
                            picture="teachers.jpg"
                            copyright={localization.copyrightZoli}
                            description={localization.headerTeachersDescription1}/>
                    </Col>

                    {CONFIG.profile.dancePartnerSeekerEnabled ? (
                        <Col sm={SM} lg={MD}>
                            <SectionCard
                                title={localization.menuDancePartnerSeeker}
                                url={Url.DANCE_PARTNER_SEEKER}
                                picture="find_a_partner.jpg"
                                copyright={localization.copyrightMagdi}
                                description={localization.dancePartnerHeader}/>
                        </Col>
                    ) : null}

                    <Col sm={SM} lg={MD}>
                        <SectionCard
                            title={localization.argentineTangoRadio}
                            url={Url.RADIO}
                            picture="argentine-tango-radio.jpg"
                            description={localization.argentineTangoRadioText1}/>
                    </Col>
                </Row>
            </section>

            <WhatsNewSection/>
        </Fragment>
    );
};

export default Home;