import React from "react";
import './SectionCard.css';
import Copyright from "../Copyright/Copyright";
import localization from "../../utils/localization";
import {PictureLinkSection} from "../PictureLink/PictureLink";
import {Button} from "react-bootstrap";
import {useNavigation} from "../../hook/useNavigation";

const SectionCard = (props) => {
    const {pushHistory} = useNavigation();

    return (
        <div className="d-flex align-items-start flex-column mt-4" style={{cursor: 'pointer', height: "450px"}}>
            <div className="mb-auto">
                <h3 className="text-center text-truncate">{props.title}</h3>
                <div className="rectangle"/>
                <div className="mt-3 text-center">{props.description}</div>
            </div>

            <div className="mx-auto">
                <div style={{ width: "200px"}}>
                    <PictureLinkSection className="section-image-fluid card-shadow mt-2"
                                        picturePath={process.env.PUBLIC_URL + "/pictures/section-card/" +  props.picture}
                                        onClick={() => pushHistory(props.url)}
                                        alt={props.title}/>

                    <Copyright className="text-white" text={props.copyright}/>

                    <Button block className="my-3" onClick={() => pushHistory(props.url)}>
                        {localization.details}
                    </Button>
                </div>
            </div>
        </div>
    );
}
export default SectionCard;