import React, {useRef} from "react";
import Modal from "react-bootstrap/Modal";
import PictureEditor from "../PictureEditor/PictureEditor";
import Button from "react-bootstrap/Button";
import localization from "../../utils/localization";
import ModalBase from "./ModalBase";
import {useModal} from "../../hook/useModal";
import {Col, Row} from "react-bootstrap";

const EditPictureModal = (props) => {
    const modal = useModal();
    const pictureEditor = useRef();

    const savePicture = () => {
        pictureEditor.current.onSave();
    };

    const onUpdate = (data) => {
        props.onUpdate(data);
        modal.handleClose();
    }

    return (
        <>
            {modal.renderTrigger(props.trigger)}
            <ModalBase show={modal.show} title={localization.formUploadPicture} size={props.modalSize}>
                <Modal.Body>
                    <Row>
                        <Col/>

                        <Col>
                            <PictureEditor
                                ref={pictureEditor}
                                width={props.width}
                                height={props.height}
                                s3Folder={props.s3Folder}
                                fileName={props.fileName}
                                onClose={onUpdate}/>
                        </Col>

                        <Col/>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={modal.handleClose}>{localization.formDiscard}</Button>
                    <Button variant="danger" onClick={savePicture}>{localization.formSavePicture}</Button>
                </Modal.Footer>
            </ModalBase>
        </>
    )
}

EditPictureModal.defaultProps = {
    modalSize: "xl"
}

export default EditPictureModal;