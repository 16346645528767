import React from "react";
import "./DonateButton.css";
import localization from "../../utils/localization";
import TooltipOverlay from "../TooltipOverlay/TooltipOverlay";

const DonateButton = (props) => (
    <TooltipOverlay text={props.tooltip}>
        <a href={props.url} target="_blank" rel="noopener noreferrer">
            <div className="btn btn-warning btn-sm donate-button font-weight-bold">
                <i className="fas fa-hands-helping mr-1"/>{localization.actionDonate}
            </div>
        </a>
    </TooltipOverlay>
);

export default DonateButton;