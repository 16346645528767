import React from 'react';
import {useModal} from "../../../hook/useModal";
import ModalBase from "../../../component/FormModal/ModalBase";
import localization from "../../../utils/localization";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useForm} from "react-hook-form";
import LoadingIndicator from "../../../component/LoadingIndicator/LoadingIndicator";
import Alert from "react-s-alert";
import {CustomForm, SubmitButton, TextAreaField} from "../../../component/FormInputs/FormInputs";
import {getUserAgeOptionText, getUserSkillLevelOptionText} from "../../../utils/enumMapper";
import {useSelector} from "react-redux";
import {useNavigation} from "../../../hook/useNavigation";
import {API_DANCE_PARTNER, Url} from "../../../constants";
import {simpleAuthenticatedPost} from "../../../utils/connection";

const DancePartnerMessageModal = (props) => {
    const modal = useModal();
    const formControl = useForm();
    const {pushHistory} = useNavigation();
    const currentUser = useSelector(state => state.userReducer.currentUser);
    const advertisementLanguage = props.language ? props.language.toLowerCase() : null;

    const userInfo = {
        age: currentUser ? getUserAgeOptionText(currentUser.tangoUserInfo.age) : null,
        bodyHeight: currentUser ? currentUser.tangoUserInfo.bodyHeight : null,
        activeDancingYears: currentUser ? currentUser.tangoUserInfo.activeDancingYears : null,
        skillLevel: currentUser ? getUserSkillLevelOptionText(currentUser.tangoUserInfo.skillLevel) : null
    }

    const onSubmit = (submittedData) => {
        modal.setLoading(true);
        const dataToSend = getDataToSend(submittedData.message);

        simpleAuthenticatedPost(API_DANCE_PARTNER + 'message/' + props.id, dataToSend, () => {
            Alert.success(localization.dancePartnerMessageCabeceoSentSuccessfully);
            modal.handleClose();
        }, () => modal.handleClose())
    }

    const getDataToSend = (message) => {
        const url = window.location.href + "/" + props.id;
        const previousLanguage = localization.getLanguage();
        localization.setLanguage(advertisementLanguage)

        const dataToSend = {
            messageTemplate: composeEmailMessageTemplate(url, userInfo),
            message: message.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
            subject: localization.dancePartnerMessageEmailSubject
        }

        localization.setLanguage(previousLanguage);
        return dataToSend;
    }

    const composeEmailMessageTemplate = (url, info) => {
        let message = localization.dancePartnerMessageEmailBody1;
        message += `<br/>${localization.dancePartnerMessageEmailBody2}: ${url}. `;
        message += `<h3>${localization.formMessage}</h3>%s`;
        message += `<h3>${localization.dancePartnerMessageChosenPartnerInfo}</h3>`;
        message += `<b>${localization.formName}:</b> %s<br/>`;
        message += `<b>${localization.formEmail}:</b> %s<br/>`;
        message += `<b>${localization.dancePartnerCardAge}:</b> ${info.age}<br/>`;
        message += `<b>${localization.dancePartnerCardHeight}:</b> ${info.bodyHeight}<br/>`;
        message += `<b>${localization.dancePartnerCardActiveYears}:</b> ${info.activeDancingYears}<br/>`;
        message += `<b>${localization.dancePartnerCardLevel}:</b> ${info.skillLevel}<br/>`;
        message += `<img src="${currentUser.getImage()}" alt="Partner Profile" width="128" height="128"/>`;

        return message;
    }

    if (currentUser) {
        return (
            <>
                {modal.renderTrigger(props.trigger)}

                <ModalBase show={modal.show} title={localization.dancePartnerMessageSendCabeceo} size="lg">
                    {modal.loading || !currentUser ? (
                        <Modal.Body>
                            <div style={{height: "200px"}}>
                                <LoadingIndicator/>
                            </div>
                        </Modal.Body>
                    ): (
                        <CustomForm formControl={formControl} onSubmit={onSubmit}>
                            <Modal.Body>
                                <div className="border rounded shadow-sm p-2" style={{backgroundColor: "rgb(241,241,241)"}}>
                                    <div className="text-center">{localization.dancePartnerMessageInfo}</div>

                                    <div className="separator-line mt-2 mb-2" />

                                    <div className="mt-0">
                                        <span className="font-weight-bold">{localization.formName}:</span> {currentUser.name}
                                        <div />
                                        <span className="font-weight-bold">{localization.formEmail}:</span> {currentUser.email}
                                    </div>

                                    <span className="font-weight-bold">{localization.dancePartnerCardAge}: </span> {userInfo.age}
                                    <div/>
                                    <span className="font-weight-bold">{localization.dancePartnerCardHeight}: </span> {userInfo.bodyHeight}
                                    <div/>
                                    <span className="font-weight-bold">{localization.dancePartnerCardActiveYears}: </span> {userInfo.activeDancingYears}
                                    <div/>
                                    <span className="font-weight-bold">{localization.dancePartnerCardLevel}: </span> {userInfo.skillLevel}
                                </div>

                                <div className={"mt-4"}>
                                    <TextAreaField formControl={formControl}
                                                   required
                                                   name="message"
                                                   label={localization.formMessage}
                                                   rows={4}
                                                   maxLength={1000}
                                                   placeholder={localization.dancePartnerMessagePlaceholder}/>
                                </div>
                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="secondary" onClick={modal.handleClose}>{localization.formDiscard}</Button>
                                <SubmitButton caption={localization.formSubmit} />
                            </Modal.Footer>
                        </CustomForm>
                    )}
                </ModalBase>
            </>
        );
    } else {
        return <>
            {modal.renderTrigger(props.trigger)}

            <ModalBase show={modal.show} title={localization.dancePartnerMessageSendCabeceo} size="sm">
                <Modal.Body>
                    <p>{localization.dancePartnerMessageLogin}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={modal.handleClose}>{localization.formDiscard}</Button>
                    <Button onClick={() => pushHistory(Url.LOGIN)}>{localization.formSignIn}</Button>
                </Modal.Footer>
            </ModalBase>
            </>
    }

};

export default DancePartnerMessageModal;