import React  from 'react';
import Moment from 'react-moment';
import 'moment/locale/hu';
import 'moment/locale/en-gb';
import 'moment-timezone/builds/moment-timezone-with-data';
import localization from "../../utils/localization";

Moment.globalTimezone = 'Europe/Budapest';

const getLocaleId = () => {
    const language = localization.getLanguage();
    return language === 'en' ? "en-gb" : language;
}

export const MonthNameFormatter = (props) => {
    return (
        <Moment date={props.date} format="MMMM" locale={localization.getLanguage()}/>
    );
};

export const TimeFormatter = (props) => {
    return (
        <Moment date={props.date} format="LT" locale={localization.getLanguage()}/>
    );
};

export const FullDateFormatter = (props) => {
    return (
        <Moment date={props.date} format="LL" locale={getLocaleId()}/>
    );
};