import React from "react";
import {useNavigation} from "../../hook/useNavigation";

const SettingCard = (props) => {
    const {pushHistory} = useNavigation();

    return (
        <div className="border border-light shadow d-flex flex-column bg-white" style={{height: "280px"}}>
            <div className="mb-auto">
                <h4 className="text-center mt-3">{props.title}</h4>

                <div className="text-center mt-4 mb-3">
                    <i className={props.icon + " fa-3x"}/>
                </div>

                <div className="px-3 my-3 text-center">
                    {props.text}
                </div>
            </div>

            {props.disabled ? (
                <h5 className="text-center text-danger mb-4 ">
                    {props.disabled}
                </h5>
            ) : (
                <div className="d-flex justify-content-center mb-3" onClick={() => pushHistory(props.url)}>
                    {props.button}
                </div>
            )}
        </div>
    );
}

export default SettingCard;