import React from 'react';
import './PersonCard.css';
import {SocialIconAddress, SocialIconEmail, SocialIconFacebook, SocialIconPhone, SocialIconWeb} from "../SocialIcon/SocialIcon";
import {getLocalizedDescription, mapClubDescriptions} from "../../utils/helperFunctions";
import {S3_IMAGES, SHORT_DESCRIPTION, Url} from "../../constants";
import {Card} from "react-bootstrap";
import DetailsButton from "../DetailsButton/DetailsButton";

const PersonCard = (props) => {
    const description = mapClubDescriptions(props.data.descriptions);

    const isDetailsButtonVisible = () => {
        if (props.data.pictureGallery)
            return true;

        for (const description of props.data.descriptions) {
            if (description.longDescription)
                return true;
        }

        return false
    }

    return (
        <div id="person-card" className="h-100">
            <Card className="h-100">
                <Card.Body className="text-center">
                    <img src={S3_IMAGES + props.data.picture} alt="Portfolio" className="img-fluid rounded-circle mb-3 w-75 border move-image-up" />
                    <h2>{props.data.name}</h2>

                    {isDetailsButtonVisible() ? <DetailsButton small url={Url.TEACHERS + "/" + props.data.id} /> : null}

                    <h5 className="text-muted mt-2">{getLocalizedDescription(description, SHORT_DESCRIPTION)}</h5>
                </Card.Body>

                <Card.Footer>
                    <div className="d-flex justify-content-center">
                        <SocialIconFacebook url={props.data.facebook}/>
                        <SocialIconEmail url={props.data.email}/>
                        <SocialIconWeb url={props.data.webPage}/>
                        <SocialIconPhone url={props.data.phone}/>
                        <SocialIconAddress url={props.data.address}/>
                    </div>
                </Card.Footer>
            </Card>
        </div>
    )
};

export default PersonCard;