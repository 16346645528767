import * as actionTypes from "../actions/uiActions";
import {CONFIG} from "../../config";

const initialState = {
    currentLanguage: CONFIG.profile.defaultLanguage,
    country: CONFIG.profile.defaultCountry
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.value
            };
        case actionTypes.CHANGE_COUNTRY:
            return {
                ...state,
                country: action.value
            };
        default:
            return state;
    }
};

export default uiReducer;