import React, {useEffect, useState} from "react";
import {SelectItem} from "../FormInputs/FormInputs";
import localization from "../../utils/localization";
import * as eventStyle from "../../utils/EventStyle";
import {getClassCategoryOptions} from "../../utils/EventStyle";
import {createClubSelectOptions} from "../../utils/helperFunctions";
import {getEventCategoryOptions} from "../../utils/EventStyle";
import {useSelector} from "react-redux";

const FilterInputSelect = (props) => {
    const eventTypeSearchHandler = (event) => {
        props.onChange(event.target.value);
    };

    const initCategoryFilter = () => {
        const options = [...props.options];
        options.unshift({value: eventStyle.ALL, text: localization.filterHintEventType});

        return options;
    };

    return (
        <SelectItem formControl={props.formControl}
                    defaultValue={props.value}
                    label={props.label}
                    name="FilterCategory"
                    options={initCategoryFilter()}
                    onChange={eventTypeSearchHandler}/>
    )
};

export const FilterInputCategoryClass = (props) => (
  <FilterInputSelect
      options={getClassCategoryOptions()}
      label={localization.filterEventType}
      {...props}/>
);

export const FilterInputCategoryEvent = (props) => (
    <FilterInputSelect
        options={getEventCategoryOptions()}
        label={localization.filterEventType}
        {...props}/>
);

export const FilterInputClub = (props) => {
    const clubList = useSelector(state => state.eventReducer.clubs);
    const [clubOptions, setClubOptions] = useState([]);

    useEffect(() => {
        const options = createClubSelectOptions(clubList);
        setClubOptions(options);
    }, [clubList]);

    return (
        <FilterInputSelect
            options={clubOptions}
            label={localization.filterTangoClub}
            {...props}/>
    )
};