import React, { Component } from 'react';
import Geocode from "react-geocode";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

const apiKey = "AIzaSyDR7k9VwooqeC0i-Na_EDmp9DqnyomA-8o";
Geocode.setApiKey(apiKey);

class Map extends Component {
    constructor(props) {
        super(props);
        this.onScriptLoad = this.onScriptLoad.bind(this);
        this.id = "eventMap";
    }

    onScriptLoad(center) {
        const options = {
            center: center,
            zoom: 15,
            disableDefaultUI: true,
            fullscreenControl: true,
            zoomControl: true
        }

        const map = new window.google.maps.Map(document.getElementById(this.id), options);

        new window.google.maps.Marker({
            position: center,
            map: map,
            title: this.props.title
        });
    }

    initGoogleMap = (center) => {
        if (!window.google) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.google.com/maps/api/js?key=' + apiKey;
            script.id = 'googleMaps';
            document.body.appendChild(script);
            script.addEventListener('load', e => {
                this.onScriptLoad(center)
            })
        }
        else {
            this.onScriptLoad(center)
        }
    }

    componentDidMount() {
        Geocode.fromAddress(this.props.location).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                this.initGoogleMap({lat, lng});
            },
            error => {
                console.error(error);
            }
        );
    }

    render() {
        return (
            <div style={{ width: '100%', height: 300}} id={this.id}>
                <LoadingIndicator/>
            </div>
        );
    }
}

export default Map;