import React from 'react';
import localization from "../../utils/localization";
import {Button} from "react-bootstrap";
import {useNavigation} from "../../hook/useNavigation";

const DetailsButton = props => {
    const navigation = useNavigation();
    const small = props.small ? "btn-sm" : "";

    const onClick = () => {
        navigation.replaceHistory(props.url);
    }

    return (
        <Button variant={"primary"} className={"card-link mr-2 " + small} onClick={onClick}>
            <i className="fas fa-search"/> {localization.details}
        </Button>
    );
};

export default DetailsButton;