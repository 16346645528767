import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const TooltipOverlay = props => {
    const showTooltip = (tooltipProps) => {
        if (props.disabled)
            return <div/>
        else
            return <Tooltip {...tooltipProps} show="true">{props.text}</Tooltip>;
    }

    return (
        <OverlayTrigger placement="top" overlay={showTooltip}>
            {props.children}
        </OverlayTrigger>
    )
};

export default TooltipOverlay