import React from "react";
import {MonthNameFormatter} from "../../component/DateTimeFormatter/DateTimeFormatter";
import localization from "../../utils/localization";

const WhatsNewSection = () => (
    <section id="whats-new" className="container">
        <h1 className="text-center mt-5 mb-3">{localization.homeWhatsNew}</h1>
        <div className="card p-3 shadow-sm">
            <h3 className="text-center">2022</h3>
            <h3 className="text-capitalize"><MonthNameFormatter date={new Date(2021, 5)}/></h3>
            <ul>
                <li>{localization.news2022June}</li>
            </ul>
        </div>
    </section>
);

export default WhatsNewSection;