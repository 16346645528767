import React from 'react';

const CenterText = props => {
    return (
        <div className="container d-flex h-100">
            <div className="row justify-content-center align-self-center">
                <div className="col text-center font-weight-bold">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default CenterText;