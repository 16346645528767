import React from "react";
import Modal from "react-bootstrap/Modal";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

const ModalBase = (props) => {
    return (
        <Modal centered size={props.size} show={props.show} onHide={props.onHide} >
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            {props.loading ? (
                <div style={{height: "200px"}}>
                    <LoadingIndicator/>
                </div>
            ) : props.children}
        </Modal>
    );
};

ModalBase.defaultProps = {
    size: 'lg',
    onHide: () => {}
};

export default ModalBase;