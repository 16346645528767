import React, {Fragment, useEffect, useState} from 'react';
import './EventDetails.css';
import EventInfoBox from "../../../component/EventInfoBox/EventInfoBox";
import MapBox from "../../../component/MapBox/MapBox";
import {useParams} from 'react-router-dom';
import BackButton from "../../../component/BackButton/BackButton";
import LoadingIndicator from "../../../component/LoadingIndicator/LoadingIndicator";
import localization from "../../../utils/localization";
import GoogleCalendarAddButton from "../../../component/GoogleCalendarAddButton/GoogleCalendarAddButton";
import HeadLayout from "../../../layout/HeadLayout/HeadLayout";
import EventPicture from "../../../component/EventCard/EventPicture";
import EventCategoryBadge from "../../../component/EventCategoryBadge/EventCategoryBadge";
import {EventStyle} from "../../../utils/EventStyle";
import {Col, Container, Row} from "react-bootstrap";
import {simpleQuery} from "../../../utils/connection";
import {API_EVENTS, Url} from "../../../constants";
import {getEventUrl} from "../../../utils/helperFunctions";
import linkifyHtml from "linkify-html";
import {FullDateFormatter, TimeFormatter} from "../../../component/DateTimeFormatter/DateTimeFormatter";
import InfoBoxLayout from "../../../layout/InfoBoxLayout/InfoBoxLayout";

const EventsDetails = () => {
    const [eventDetails, setEventDetails] = useState(null);
    const params = useParams();

    useEffect(() => {
        const urlParameters = params.year + "/" + params.month + "/" + params.id;
        const controller = simpleQuery(API_EVENTS + urlParameters, setEventDetails);

        return () => controller.abort();
    }, [params.year, params.month, params.id]);

    const renderPageContent = () => {
        const eventUrl = getEventUrl(eventDetails);
        const eventStyle = EventStyle(eventDetails);
        const backUrl = eventStyle.isClass ? Url.CLASSES : Url.EVENTS;
        const headerStyle = eventStyle.isCancelled ? {color: "red"} : null;

        const ButtonBar = () => (
            <Container className="mt-4 mt-md-5">
                <BackButton backUrl={backUrl}/>
                <span className="mr-2"/>
                <GoogleCalendarAddButton
                    title={eventDetails.title}
                    startDateTime={eventDetails.startDateTime}
                    endDateTime={eventDetails.endDateTime}
                    eventUrl={eventUrl}
                    location={eventDetails.location}/>
            </Container>
        );

        let modifiedDescription = localization.noDescription;
        if (eventDetails.description && eventDetails.description.length > 0) {
            modifiedDescription = eventDetails.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
            modifiedDescription = linkifyHtml(modifiedDescription);
        }

        return (
            <Fragment>
                <div className="bg-dark text-white">
                    <Container className="py-2">
                        <h2 style={headerStyle}>{eventDetails.title}</h2>
                        <EventCategoryBadge event={eventDetails}/>
                    </Container>
                </div>

                <ButtonBar/>

                <Container className="mt-2">
                    <Row className="row">
                        <Col md={8}>
                            <EventPicture event={eventDetails}/>
                            <div className="card p-2">
                                <h1 className="my-3">{localization.details}</h1>
                                <div dangerouslySetInnerHTML={{__html: modifiedDescription}}/>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className="mt-3 mt-md-0"/>
                            <EventInfoBox
                                startDate={eventDetails.startDateTime}
                                endDate={eventDetails.endDateTime}
                                address={eventDetails.location}
                                wholeDayEvent={eventDetails.allDayEvent}
                                externalUrl={eventDetails.externalUrl}/>
                            <div className="mt-3"/>
                            <MapBox location={eventDetails.location} title={eventDetails.title}/>

                            <div className="mt-3"/>
                            {eventDetails.lastUpdated && eventDetails.dataSource !== "MILONGA_HU" ? (
                                <InfoBoxLayout title={localization.eventLastUpdated}>
                                    <div className="p-2">
                                        <FullDateFormatter date={eventDetails.lastUpdated}/> | <TimeFormatter
                                        date={eventDetails.lastUpdated}/>
                                    </div>
                                </InfoBoxLayout>
                            ) : null}
                        </Col>
                    </Row>
                </Container>

                <ButtonBar/>
            </Fragment>
        )
    };

    return (
        <Fragment>
            <HeadLayout pic="events.jpg" copyright={localization.copyrightZoli}/>
            {eventDetails === null ? (
                <div className="empty-page">
                    <LoadingIndicator/>
                </div>
            ) : renderPageContent()}
        </Fragment>
    );
}

export default EventsDetails;