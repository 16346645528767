import React, {useEffect, useState} from 'react';
import './EventFilterButtons.css';
import localization from "../../utils/localization";
import {CustomForm} from "../FormInputs/FormInputs";
import {useForm} from "react-hook-form";
import {FilterInputCategoryClass, FilterInputCategoryEvent, FilterInputClub} from "./FilterInputCategory";
import FilterInputTitle from "./FilterInputTitle";
import FilterResetButton from "./FilterResetButton";
import {filterCategory, filterClub, filterTitle} from "./filtersFunctions";
import CountrySelect from "../CountrySelect";
import {useSelector} from "react-redux";

const EventFilterButtons = (props) => {
    const formControl = useForm();
    const [searchTitle, setSearchTitle] = useState('');
    const [searchCategory, setSearchCategory] = useState('');
    const [searchClub, setSearchClub] = useState('');
    const country = useSelector(state => state.uiReducer.country);

    const resetClickHandler = () => {
        setSearchTitle('');
        setSearchCategory('');
        setSearchClub('');
    };

    useEffect(() => {
        let filteredEvents = filterClub(props.events, searchClub);
        filteredEvents = filterCategory(filteredEvents, searchCategory);
        filteredEvents = filterTitle(filteredEvents, searchTitle);

        props.onFilter(filteredEvents);
    }, [props, searchCategory, searchTitle, searchClub]);

    return (
        <div className="title-position">
            <h3>{localization.filterTitle}</h3>
            <hr/>

            <CustomForm formControl={formControl}>

                <CountrySelect formControl={formControl} defaultValue={country}/>

                {props.classCategory ?
                    <FilterInputCategoryClass formControl={formControl} value={searchCategory}
                                              onChange={setSearchCategory}/>
                    :
                    <FilterInputCategoryEvent formControl={formControl} onChange={setSearchCategory}
                                              value={searchCategory}/>
                }

                <FilterInputTitle formControl={formControl} onChange={setSearchTitle} value={searchTitle}/>
                <FilterInputClub formControl={formControl} value={searchClub} onChange={setSearchClub}/>

                <FilterResetButton onClick={resetClickHandler}/>
            </CustomForm>
        </div>
    );
};

export default EventFilterButtons;