import React from "react";
import EventTable from "./EventTable";
import localization from "../../../utils/localization";
import {Card, Col, Jumbotron, Row} from "react-bootstrap";
import {Url} from "../../../constants";
import BackButton from "../../../component/BackButton/BackButton";

const TangoEventsAdmin = () => (
    <Row className="space-top">
        <Col sm={1}/>
        <Col>
            <Jumbotron className="mb-3 p-3">
                <h5>{localization.infoMyEvents}</h5>
            </Jumbotron>

            <BackButton backUrl={Url.PROFILE} className="mb-2"/>

            <Card className="shadow-sm">
                <EventTable/>
            </Card>
        </Col>
        <Col sm={1}/>
    </Row>
);

export default TangoEventsAdmin;