import React, {useState} from 'react';
import {useModal} from "../../../hook/useModal";
import {useForm} from "react-hook-form";
import ModalBase from "../../../component/FormModal/ModalBase";
import localization from "../../../utils/localization";
import Modal from "react-bootstrap/Modal";
import LoadingIndicator from "../../../component/LoadingIndicator/LoadingIndicator";
import {CustomForm, PasswordField, PrintGeneralErrorMsg, SubmitButton} from "../../../component/FormInputs/FormInputs";
import {Button} from "react-bootstrap";
import {changePasswordRequest} from "../../../utils/connection";
import Alert from "react-s-alert";
import localizeErrorMessage from "../../../utils/errorCodeResolver";

const ChangePasswordModal = (props) => {
    const modal = useModal();
    const formControl = useForm();
    const [passwordMismatch, setPasswordMismatch] = useState(false);

    const onSubmit = async submittedData => {
        if (submittedData.newPassword === submittedData.confirmPassword) {
            modal.setLoading(true);

            try {
                delete submittedData.confirmPassword;

                await changePasswordRequest(submittedData);
                modal.setLoading(false);
                Alert.success(localization.loginResetPasswordSucceed);
                modal.handleClose();
            } catch (error) {
                modal.setLoading(false);
                console.log(error);
                Alert.error(localizeErrorMessage(error));
            }
        } else {
            setPasswordMismatch(true);
        }
    }

    const onCancel = () => {
        setPasswordMismatch(false);
        modal.handleClose();
    }

    return (
        <>
            {modal.renderTrigger(props.trigger)}

            <ModalBase show={modal.show} title={localization.settingCardChangePasswordTitle} size="sm">
                {modal.loading ? (
                    <Modal.Body>
                        <div style={{height: "200px"}}>
                            <LoadingIndicator/>
                        </div>
                    </Modal.Body>
                ): (
                    <CustomForm formControl={formControl} onSubmit={onSubmit}>
                        <Modal.Body>
                            <PasswordField formControl={formControl} name="currentPassword" label={localization.formCurrentPassword}/>
                            <PasswordField formControl={formControl} name="newPassword" label={localization.formNewPassword}/>
                            <PrintGeneralErrorMsg error={passwordMismatch} text={localization.loginPasswordMismatch}/>
                            <PasswordField formControl={formControl} name="confirmPassword" label={localization.formConfirmPassword}/>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={onCancel}>{localization.formDiscard}</Button>
                            <SubmitButton caption={localization.formSubmit} />
                        </Modal.Footer>
                    </CustomForm>
                )}
            </ModalBase>
        </>
    );
};

export default ChangePasswordModal;