import React from "react";
import TeacherTable from "./TeacherTable";
import localization from "../../../utils/localization";
import {Card, Container, Jumbotron} from "react-bootstrap";
import {Url} from "../../../constants";
import BackButton from "../../../component/BackButton/BackButton";

const TeacherAdmin = () => (
    <Container className="space-top">
        <Jumbotron className="mb-3 p-3">
            <h5>{localization.infoTeachers}</h5>
        </Jumbotron>

        <BackButton backUrl={Url.PROFILE} className="mb-2"/>

        <Card className="shadow-sm">
            <TeacherTable/>
        </Card>
    </Container>
);

export default TeacherAdmin;