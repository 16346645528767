import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {simpleQuery} from "../../../utils/connection";
import {API_DANCE_PARTNER, Url} from "../../../constants";
import DancePartnerCard from "../../../component/DancePartnerCard/DancePartnerCard";
import LoadingIndicator from "../../../component/LoadingIndicator/LoadingIndicator";
import {Col, Container, Row} from "react-bootstrap";
import BackButton from "../../../component/BackButton/BackButton";
import localization from "../../../utils/localization";

const DancePartnerCardDetailsPage = () => {
    const {id} = useParams();
    const [card, setCard] = useState();

    const NOT_FOUND = "NOT_FOUND";

    useEffect(() => {
        const controller = simpleQuery(API_DANCE_PARTNER + id, setCard, () => setCard(NOT_FOUND));
        return () => {controller.abort();}
    }, [id])

    const getDancerCards = () => {
        if (card) {
            if (card !== NOT_FOUND) {
                return <DancePartnerCard data={card} hideButtons/>
            } else {
                return (
                    <div className="text-center my-5" style={{height: "400px"}}>
                        <i className="far fa-frown fa-6x"/>
                        <h4 className="mt-3">{localization.dancePartnerCardNotAvailable}</h4>
                    </div>)
            }
        } else {
            return (
                <div style={{height: "200px", marginTop: "100px"}}>
                    <LoadingIndicator centerHorizontally/>
                </div>)
        }
    }

    return (
        <Container style={{paddingTop: "80px"}}>

            <div className="d-flex mt-5">
                <div className="mb-3 ml-2 mr-5">
                    <BackButton backUrl={Url.DANCE_PARTNER_SEEKER}/>
                </div>
            </div>


            <Row>
                <Col/>
                <Col lg={6}>
                    {card !== NOT_FOUND ? <h3 className="text-center">{localization.dancePartnerCardCabeceoReceived}</h3> : null}

                    <div style={{marginTop: "120px"}}>
                        {getDancerCards()}
                    </div>
                </Col>
                <Col/>
            </Row>
        </Container>
    );
};

export default DancePartnerCardDetailsPage;