export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";

const changeLanguage = (language) => {
    return {
        type: CHANGE_LANGUAGE,
        value: language
    }
};

const changeCountry = (country) => {
    return {
        type: CHANGE_COUNTRY,
        value: country
    }
};

export default {
    changeLanguage,
    changeCountry
}