import {ACCESS_TOKEN, S3_IMAGES} from "../../constants";
import Alert from "react-s-alert";
import localization from "../../utils/localization";
import {getCurrentUser} from "../../utils/connection";
import {ERROR_CODE_USER_LOGGED_OUT} from "../../utils/errorCodeResolver";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USER_LOGOUT = "USER_LOGOUT";

const loadingAction = (value) => {
    return {
        type: USER_LOADING,
        loading: value
    }
};

const userLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    Alert.success(localization.loginLogoutMessage);
    return {
        type: USER_LOGOUT,
        authenticated: false,
        currentUser: null,
        loading: false
    }
};


const loadCurrentlyLoggedInUser = () => {
    return async (dispatch) => {
        dispatch(loadingAction(true));

        try {
            const response = await getCurrentUser();
            const user = addMethodsToUser(response);

            dispatch(userLoaded(user))
        } catch (error) {
            if (error.errorCode === ERROR_CODE_USER_LOGGED_OUT) {
                localStorage.removeItem(ACCESS_TOKEN);
            } else {
                console.log(error);
            }
            dispatch(loadingAction(false));
        }
    };
};

const userLoaded = (user) => {
    return {
        type: USER_LOADED,
        user: user
    }
};

const addMethodsToUser = (currentUser) => {
    currentUser.getImage = function() {
        return S3_IMAGES + this.imageUrl;
    }

    return currentUser;
}

export default {
    loadingAction,
    userLogout,
    loadCurrentlyLoggedInUser
}