import React, {useState} from "react";
import ModalBase from "./ModalBase";
import Modal from "react-bootstrap/Modal";
import localization from "../../utils/localization";
import Button from "react-bootstrap/Button";
import {useModal} from "../../hook/useModal";
import RecurringEventModal from "./RecurringEventModal";
import {QUERY_RECURRING} from "../../constants";

const ConfirmModal = (props) => (
    <ModalBase size="md" show={props.modal.show} title={props.title} loading={props.modal.loading}>
        <Modal.Body>
            {props.text}
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>{localization.formDiscard}</Button>
            <Button variant="danger" onClick={props.handleAction}>{props.actionButtonText}</Button>
        </Modal.Footer>
    </ModalBase>
)

export const DeleteConfirmModal = props => {
    const modal = useModal();

    const handleAction = () => {
        modal.setLoading(true);
        props.onAction();
    };

    return (
        <>
            {modal.renderTrigger(props.trigger)}

            <ConfirmModal modal={modal}
                         title={localization.actionDelete.toUpperCase() + ": " + props.title}
                         text={localization.confirmDelete}
                         handleClose={modal.handleClose}
                         handleAction={handleAction}
                         actionButtonText={localization.actionDelete.toUpperCase()}/>
        </>
    )
};

export const DeleteEventConfirmModal = (props) => {
    const modal = useModal();
    const [updateUpcomingRecurring, setUpdateUpcomingRecurring] = useState(props.recurringId ? null : false);

    const handleAction = () => {
        modal.setLoading(true);
        const queryParam = updateUpcomingRecurring ? QUERY_RECURRING : "";
        props.onDelete(queryParam);
    };

    const handleConfirmClose = () => {
        const isRecurring = props.recurringId ? null : false;
        setUpdateUpcomingRecurring(isRecurring);
        modal.handleClose();
    }

    return (
        <>
            {modal.renderTrigger(props.trigger)}

            {updateUpcomingRecurring === null ?
                (
                    <RecurringEventModal show={modal.show}
                                         title={props.title}
                                         text={localization.formRecurringEventDelete}
                                         handleClose={handleConfirmClose}
                                         onSubmit={setUpdateUpcomingRecurring}/>
                ) : (
                    <ConfirmModal modal={modal}
                                 title={localization.actionDelete.toUpperCase() + ": " + props.title}
                                 text={updateUpcomingRecurring ? localization.confirmDeleteRecurring :  localization.confirmDelete}
                                 handleAction={handleAction}
                                 handleClose={handleConfirmClose}
                                 actionButtonText={localization.actionDelete.toUpperCase()}/>
                )}
        </>
    )
}