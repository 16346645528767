import React from 'react';
import {InputField} from "../FormInputs/FormInputs";
import localization from "../../utils/localization";

const FilterInputName = props => {
    const nameSearchHandler = (event) => {
        props.onChange(event.target.value);
    };

    return (
        <InputField formControl={props.formControl} name="name"
                    label={localization.formName} placeholder={localization.formName}
                    onChange={nameSearchHandler} value={props.value}/>
    )
};

export default FilterInputName;