import React from 'react';
import './LoadingIndicator.css';

const LoadingIndicator = (props) => {
    const center = props.centerHorizontally ? "center-horizontally" : "center";

    return (
        <div className={center}>
            <div className="lds-spinner">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    )
}

export default LoadingIndicator;