import React from "react";
import {FullDateFormatter, TimeFormatter} from "../DateTimeFormatter/DateTimeFormatter";
import GoogleCalendarAddButton from "../GoogleCalendarAddButton/GoogleCalendarAddButton";
import EventPicture from "./EventPicture";
import EventCategoryBadge from "../EventCategoryBadge/EventCategoryBadge";
import {EventStyle} from "../../utils/EventStyle";
import {GOOGLE_MAPS_LINK} from "../../constants";
import DetailsButton from "../DetailsButton/DetailsButton";
import {getEventUrl} from "../../utils/helperFunctions";
import {LocalizedLink} from "../../hook/useNavigation";
import {Card} from "react-bootstrap";

const EventCard = (props) => {
    const eventUrl = getEventUrl(props.event);

    const convertDateFormat = () => {
        let date = (<span><FullDateFormatter date={props.event.startDateTime}/> | <TimeFormatter date={props.event.startDateTime}/> - <TimeFormatter date={props.event.endDateTime}/></span>);
        if (props.event.allDayEvent)
            date = (<span><FullDateFormatter date={props.event.startDateTime}/> - <FullDateFormatter date={props.event.endDateTime}/></span>);

        return date;
    };

    let bodyBackgroundStyle = null;
    let headerBackgroundStyle = null;
    if (EventStyle(props.event).isCancelled) {
        bodyBackgroundStyle = {backgroundColor: "rgb(204, 204, 204)"};
        headerBackgroundStyle = {
            backgroundColor: bodyBackgroundStyle.backgroundColor,
            color: "red"
        };
    }

    let clubNameInfo;
    if (props.event.clubMeta && !props.event.clubMeta.defaultClub) {
        const clubName = props.event.clubMeta ? props.event.clubMeta.name : null;
        clubNameInfo = <div className="font-weight-bold"><i className="far fa-address-card mr-2"/>{clubName}</div>
    }

    return (
        <Card className="shadow-sm bg-light">
            <Card.Header className="p-2" style={headerBackgroundStyle}>
                <div className="d-flex flex-wrap flex-md-nowrap mt-2">
                    <EventCategoryBadge event={props.event}/>
                    <h5>{props.event.title}</h5>
                </div>
            </Card.Header>

            <div className={""} style={bodyBackgroundStyle}>
                <LocalizedLink to={eventUrl}>
                    <EventPicture event={props.event}/>
                </LocalizedLink>
            </div>

            <Card.Body className="p-2" style={bodyBackgroundStyle}>
                {clubNameInfo}

                <div>
                    <i className="far fa-clock mr-2 mb-2"/>{convertDateFormat()}
                </div>
                <div className="ml-1">
                    <i className="fas fa-map-marker-alt mr-2"/>
                    <a href={GOOGLE_MAPS_LINK + props.event.location} target="_blank" rel="noopener noreferrer">
                        {props.event.location}
                    </a>
                    </div>

                    <hr/>

                <DetailsButton url={eventUrl}/>

                <GoogleCalendarAddButton
                    title={props.event.title}
                    startDateTime={props.event.startDateTime}
                    endDateTime={props.event.endDateTime}
                    eventUrl={eventUrl}
                    location={props.event.location}/>

            </Card.Body>
        </Card>
    )
};

export default EventCard;