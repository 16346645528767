import React from 'react'
import './calendar.scss'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import allLocales from '@fullcalendar/core/locales-all';
import {EventStyle} from "../../utils/EventStyle";
import Tooltip from "bootstrap/js/src/tooltip";
import localization from "../../utils/localization";
import {useDispatch, useSelector} from "react-redux";
import EventCategoryBadge from "../EventCategoryBadge/EventCategoryBadge";
import {renderToStaticMarkup} from 'react-dom/server';
import {isMobileOnly} from "react-device-detect";
import {Card} from "react-bootstrap";
import {useNavigation} from "../../hook/useNavigation";
import {Url} from "../../constants";
import {constructDateUrl} from "../../utils/helperFunctions";
import eventActions from "../../store/actions/eventActions";

const VIEW_DAY_GRID_MONTH = "dayGridMonth";
const VIEW_DAY_GRID_WEEK = "dayGridWeek";
const VIEW_LIST_WEEK = "listWeek";

const Calendar = (props) => {
    const dispatch = useDispatch();
    const currentDate = useSelector(state => state.eventReducer.currentDate);
    const country = useSelector(state => state.uiReducer.country);
    const navigation = useNavigation();

    let defaultView = VIEW_DAY_GRID_MONTH;

    const collectEvents = () => {
        const events = [];
        for (const event of props.filteredEvents) {
            const e = {
                title: event.title,
                description: event.title,
                start: event.startDateTime,
                end: event.allDayEvent ? event.endDateTime : null,
                className: getEventClasses(event),
                eventId: event.id,
                eventType: event.eventType
            };

            events.push(e);
        }

        return events;
    };

    const getEventClasses = (event) => {
        const eventStyle = EventStyle(event);
        const multilineStyle = props.dayGridWeek ? "multiline-event" : "";

        let eventClasses = null;
        if (isMobileOnly && defaultView === VIEW_LIST_WEEK) {
            if (eventStyle.isCancelled) {
                eventClasses = ["text-danger"];
            }
        } else {
            eventClasses = [eventStyle.backgroundColor, eventStyle.textColor, multilineStyle, "p-1", "event-style", 'border']
        }

        return eventClasses;
    };

    const addCustomListMarker = (info) => {
        const ORIGINAL_DOT_STYLE = 'fc-event-dot';
        let dotEl = info.el.getElementsByClassName(ORIGINAL_DOT_STYLE)[0];
        if (dotEl) {
            dotEl.classList.remove(ORIGINAL_DOT_STYLE);
            dotEl.style.display = "contents";
            dotEl.style.maxWidth = "80px";
            dotEl.innerHTML = renderToStaticMarkup(<EventCategoryBadge small event={info.event.extendedProps}/>);
        }
    };

    const eventRender = (info) => {
        new Tooltip(info.el, {
            title: info.event.extendedProps.description,
            placement: 'top',
            trigger: 'hover',
            container: '#calendar'
        });

        addCustomListMarker(info)
    };

    const eventClickHandler = (event) => {
        const requestDate = constructDateUrl(currentDate);
        const eventId = event.event._def.extendedProps.eventId;
        navigation.pushHistory(Url.EVENTS + "/" + requestDate + "/" + eventId)
    };

    const dateRenderCallback = (info) => {
        const date = info.view.currentStart;

        if (currentDate === null || currentDate.getMonth() !== date.getMonth()) {
            dispatch(eventActions.fetchEvents(country, date));
            dispatch(eventActions.setCurrentDate(date));
        }
    };

    const defaultDate = currentDate && !props.today ? currentDate : new Date();

    const header = {
        left: 'title',
        center: 'today',
        right:  'prev,next'
    };

    if (props.dayGridWeek) {
        defaultView = isMobileOnly ? VIEW_LIST_WEEK : VIEW_DAY_GRID_WEEK;
        header.right = null;
        header.center = null
    }

    return (
        <Card className="shadow-sm p-1">
            <FullCalendar
                defaultView={defaultView}
                plugins={[ dayGridPlugin, listPlugin, bootstrapPlugin ]}
                defaultDate={defaultDate}
                themeSystem="bootstrap"
                contentHeight="auto"
                eventBorderColor="#fff"
                eventRender={eventRender}
                datesRender={info => dateRenderCallback(info)}
                events={collectEvents()}
                locales={allLocales}
                locale={localization.calendarLanguage}
                firstDay={1}
                eventClick={eventClickHandler}
                header={header}
                defaultTimedEventDuration={"00:00"}
                columnHeaderFormat = {{
                    weekday: "short"
                }}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit'
                }}
                views={{
                    dayGridMonth: {
                        displayEventTime: false
                    },
                    dayGridWeek: {
                        displayEventTime: true
                    },
                }}
            />
        </Card>
    )
}

export default Calendar;