import React from 'react';
import {Container, Jumbotron} from "react-bootstrap";
import localization from "../utils/localization";

const PageNotFound404 = () => {
    return (
        <Container className="space-top text-center">
            <Jumbotron className="border border-dark mb-5">
                <i className="far fa-frown fa-10x"/>
                <h1 className="mt-5">404 - {localization.error404}</h1>
                {window.location.href}
            </Jumbotron>
        </Container>
    );
};

export default PageNotFound404;