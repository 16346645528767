import React from "react";
import './PictureLink.css'

const HoverZoomImage = (props) => {
    let imgClass = "img-fluid";
    if (props.imgClass)
        imgClass = props.imgClass;

    return (
        <div className={"hover-zoom-in " + props.className}>
            <figure>
                <img className={imgClass} src={props.picturePath} alt="Event Cover" />
            </figure>
        </div>
    )
}

export const PictureExternalLink = (props) => (
    <a href={props.url} target="_blank" rel="noopener noreferrer">
        <HoverZoomImage {...props} />
    </a>
)

export const PictureLinkSection = (props) => (
    <div onClick={props.onClick}>
        <HoverZoomImage imgClass="section-image-fluid" {...props} />
    </div>
);