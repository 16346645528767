import React from 'react';
import './SocialIcon.css';
import localization from "../../utils/localization";
import Obfuscate from "react-obfuscate";
import TooltipOverlay from "../TooltipOverlay/TooltipOverlay";
import {GOOGLE_MAPS_LINK} from "../../constants";

const SocialIcon = (props) => {
    function renderAnchor() {
        const icon = <i className={props.icon + " icon"}/>;

        if (props.openInSameWindow)
            return <Obfuscate href={props.url}>{icon}</Obfuscate>;
        else
            return <Obfuscate href={props.url} target="_blank" rel="noopener noreferrer">{icon}</Obfuscate>;
    }

    if (props.url === null)
        return null;
    else
        return (
            <TooltipOverlay text={props.tooltip}>
                <div className={'social-icon ' + props.className} data-toggle="tooltip" data-placement="top">
                    {renderAnchor()}
                </div>
            </TooltipOverlay>
        )
};

SocialIcon.defaultProps = {
    openInSameWindow: false,
};

export const SocialIconFacebook = (props) => (
  <SocialIcon
      url={props.url}
      tooltip="Facebook"
      className="si-facebook"
      icon="fab fa-facebook-f"/>
);

export const SocialIconEmail = (props) => {
    const url = props.url ? "mailto:" + props.url : null;

    return (
        <SocialIcon
            url={url}
            tooltip={props.url}
            className="si-mail"
            icon="far fa-envelope"/>
    )
};

export const SocialIconWeb = (props) => {
    let url = props.url;
    if (url && !url.includes("http"))
        url = "http://" + url;

    return (
        <SocialIcon
            url={url}
            tooltip={localization.webPage}
            className="si-web"
            icon="fas fa-globe"/>
    )
};

export const SocialIconPhone = (props) => {
    const url = props.url ? "tel:" + props.url : null;

    return (
        <SocialIcon
            openInSameWindow
            url={url}
            tooltip={props.url}
            className="si-phone"
            icon="fas fa-phone"/>
    )
};

export const SocialIconAddress = (props) => {
    const url = props.url ? GOOGLE_MAPS_LINK + props.url : null;

    return (
        <SocialIcon
            url={url}
            tooltip={props.url}
            className="si-address"
            icon="fas fa-map-signs"/>
    )
};