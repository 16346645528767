import {combineReducers} from "redux";
import eventReducer from "./eventReducer";
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
    eventReducer,
    uiReducer,
    userReducer
})

export default rootReducer