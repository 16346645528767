import React from "react";

const infoBoxLayout = (props) => {
    return (
        <div id="event-info-box" className="card shadow-sm">
            <div className="card-header p-3">
                <strong>{props.title}</strong>
            </div>

            <div className="card-body p-0">
                {props.children}
            </div>
        </div>
    )
}

export default infoBoxLayout;