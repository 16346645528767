import React from 'react';
import {Col} from "react-bootstrap";
import {S3_IMAGES} from "../../constants";
import {ActionButtonDeleteCore, ActionButtonPictureUpload} from "../ActionButtons/ActionButtons";
import EditPictureModal from "../FormModal/EditPictureModal";
import CenterText from "../CenterText/CenterText";
import localization from "../../utils/localization";

const ImageAdministrator = (props) => {
    const STYLE_SIZE = {width: '400px', height: "200px"}
    const picture = props.picture[props.id];
    const picturePath = S3_IMAGES + picture;
    const s3Folder = props.s3Folder;
    const fileName = props.fileName;

    const updatePicture = (picturePath) => {
        const temp = [...props.picture];
        temp[props.id] = picturePath;
        props.onUpdate(temp);
    }

    const deletePicture = () => {
        const temp = [...props.picture];
        temp[props.id] = null;
        props.onUpdate(temp);
    }

    const uploadBox = (
        <div style={STYLE_SIZE} className={"bg-light border"}>
            <CenterText>
                {localization.formClickHereToUploadPicture}
            </CenterText>
        </div>
    )

    const PictureModal = (props) => {
        return <EditPictureModal s3Folder={s3Folder}
                                 fileName={fileName}
                                 width={800}
                                 height={400}
                                 onUpdate={updatePicture}
                                 trigger={props.trigger} />
    }

    let content = <PictureModal trigger={uploadBox}/>
    if (picture) {
        const imgPreview = <img id="picture" className="img-fluid" src={picturePath} alt="Edit Gallery Item" style={STYLE_SIZE}/>

        content = (
            <div className={"d-flex"}>
                <PictureModal trigger={imgPreview}/>

                <div className={"ml-1 d-flex flex-column"} style={{height: "50px"}}>
                    <PictureModal trigger={ActionButtonPictureUpload}/>
                    <div className={"mb-1"} />
                    <ActionButtonDeleteCore onClick={deletePicture}/>
                </div>
            </div>
        )
    }

    return (
        <Col md={6} className={"mb-3"}>
            {content}
        </Col>
    )
};

export default ImageAdministrator;