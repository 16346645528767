import {Link, Navigate, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React from "react";

export const useNavigation = () => {
    const navigate = useNavigate();
    const currentLanguage = useSelector(state => state.uiReducer.currentLanguage);

    const pushHistory = (url) => {
        if (url) {
            const path = localizedUrl(url);
            navigate(path)
        }
    }

    const replaceHistory = (url) => {
        if (url) {
            const path = localizedUrl(url);
            navigate(path, {replace: true})
        }
    }

    const localizedUrl = (url) => {
        return "/" + currentLanguage + url;
    }

    return {localizedUrl, pushHistory, replaceHistory};
}

export const LocalizedLink = (props) => {
    const {localizedUrl} = useNavigation();

    return <Link className={props.className} to={localizedUrl(props.to)}>{props.children}</Link>
}
export const LocalizedRedirect = (props) => {
    const {localizedUrl} = useNavigation();

    return <Navigate to={localizedUrl(props.to)} replace/>
}