import React from 'react';
import {SelectItem} from "./FormInputs/FormInputs";
import localization from "../utils/localization";
import {getCountryOptions} from "../utils/enumMapper";
import {useDispatch, useSelector} from "react-redux";
import uiActions from "../store/actions/uiActions";
import eventActions from "../store/actions/eventActions";
import {CONFIG} from "../config";

const CountrySelect = props => {
    const dispatch = useDispatch();
    const currentDate = useSelector(state => state.eventReducer.currentDate);
    const currentUser = useSelector(state => state.userReducer.currentUser);

    const onCountryChange = (event) => {
        if (!props.admin) {
            const newCountry = +event.target.value;
            dispatch(uiActions.changeCountry(newCountry));
            dispatch(eventActions.fetchEvents(newCountry, currentDate));
        }
    }

    const getCountries = () => {
        let options = getCountryOptions();
        if (props.admin && currentUser != null) {
            const userCountries = currentUser.privileges.countries;
            options = options.filter(e => userCountries.includes(e.value))
        }

        return options;
    }

    const countries = getCountries();
    const isSingleCountryOption = countries.length === 1;

    return (
        <SelectItem formControl={props.formControl}
                    defaultValue={props.defaultValue}
                    onChange={onCountryChange}
                    label={localization.country}
                    name="country"
                    hidden={isSingleCountryOption || !CONFIG.profile.international}
                    options={countries}/>
    );
};

export default CountrySelect;