import React from "react";
import {useTable} from "../../../hook/useTable";
import {API_EVENTS, API_MY_EVENTS, DATASOURCE_FACEBOOK, PATH_NEW, Url} from "../../../constants";
import Button from "react-bootstrap/Button";
import localization from "../../../utils/localization";
import {
    ActionButtonCopyCore,
    ActionButtonDelete,
    ActionButtonEditCore
} from "../../../component/ActionButtons/ActionButtons";
import {DeleteEventConfirmModal} from "../../../component/FormModal/ConfirmModal";
import EventTableBase from "./EventTableBase";
import EventTableFilters from "./EventTableFilters";
import {useNavigation} from "../../../hook/useNavigation";
import {useDispatch, useSelector} from "react-redux";
import eventActions from "../../../store/actions/eventActions";

export const EventTable = () => {
    const tableData = useTable(API_MY_EVENTS, API_EVENTS);
    const navigation = useNavigation();
    const currentDate = useSelector(state => state.eventReducer.currentDate);
    const country = useSelector(state => state.uiReducer.country);
    const dispatch = useDispatch();

    const onCreateClick = (id) => {
        navigation.pushHistory(Url.EVENTS_ADMIN + "/" + id)
    }

    const createNewButton = (
        <Button onClick={() => onCreateClick(PATH_NEW)}>
            <i className="fas fa-plus mr-2"/>{localization.actionCreateEvent}
        </Button>
    );

    const subHeaderComponent = (
        <>
            <EventTableFilters data={tableData.originalData} applyFilter={tableData.applyFilter}/>
            <div className="mt-3 ml-5">
                {createNewButton}
            </div>
        </>
    )

    const deleteEvent = (id, queryParam) => {
        tableData.deleteRow(id, queryParam);

        setTimeout(() => { //TODO: should not use here timeout, the backand should not give back deleted events right after the deletion!
            dispatch(eventActions.fetchEvents(country, currentDate));
        }, 2000);
    }

    const actionButtonBar = row => (
        <>
            {row.dataSource !== DATASOURCE_FACEBOOK ? (
                <>
                    <ActionButtonEditCore onClick={() => onCreateClick(row.id)}/>
                    <span className="mr-2"/>
                    <ActionButtonCopyCore onClick={() => onCreateClick(row.id + "?copy")}/>
                    <span className="mr-2"/>
                </>
            ) : null}

            <DeleteEventConfirmModal title={row.title}
                                     trigger={ActionButtonDelete}
                                     onDelete={(queryParam) => deleteEvent(row.id, queryParam)}
                                     recurringId={row.recurringId}/>
        </>
    );

    return (
        <EventTableBase data={tableData.data}
                        subHeaderComponent={subHeaderComponent}
                        actionButtonBar={actionButtonBar}
                        title={localization.menuMyEvents}/>
    )
};

export default EventTable;