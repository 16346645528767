import React from 'react';
import './Footer.css';
import localization from "../../utils/localization";
import Obfuscate from "react-obfuscate";
import DonateButton from "../DonateButton/DonateButton";

const Footer = () => {
    return (
        <div className="p-5 mt-5 footer">
            <div className="container">
                <div className="text-center">
                    <DonateButton url="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=E68MD47JGXPN2&source=url" tooltip={localization.warningDonationWebPage}/>

                    <div className="mt-2">{localization.footerCopyright} © {new Date().getFullYear()}</div>
                    <div>Vortex Panda Kft. - {localization.copyrightTamasSzalai}</div>
                    <Obfuscate href="mailto:szaltomi@gmail.com">szaltomi@gmail.com</Obfuscate>
                    <div className="mt-3">{localization.footerPhotos}</div>
                    <div className="mt-1">
                        Németh Zoltán<br/>
                        <a href="https://www.argentinetango.photo" target="_blank" rel="noopener noreferrer">
                            https://www.argentinetango.photo
                        </a></div>
                    </div>
            </div>
        </div>
    )
};

export default Footer;